// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadPurchaseType,
  EventPurchaseDataType,
  EventsDataUnionType,
  EventPayloadPurchaseItemsType,
} from "client/utils/gtm/gtm.types";
import {
  PayloadKeysEnum,
  GTMTypesEnum,
  EcommerceEnum,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
  AFFILIATION_TYPE,
} from "client/utils/gtm/gtm.types";

class PurchaseEvent extends AbstractGTMEventSegment<GTMTypesEnum.purchase> {
  protected readonly segmentShape: EventPayloadPurchaseType = {
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.transaction_id]: "0",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.shipping]: 0,
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.tax]: 0,
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadPurchaseType {
    const { checkout, orderId, itemListId } = data as EventPurchaseDataType;
    const { currency, priceVat } = super.getPriceConfig(String(checkout.totalPrice?.country));

    const segment = this.getSegment();
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.transaction_id] = String(orderId);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = String(currency);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] = Number(checkout.totalPrice?.value);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.tax] = Number(checkout.vatPrice?.value);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.shipping] = Number(
      checkout.deliveryPrice?.value,
    );

    const currentSecondaryMethod = checkout.paymentMethodsSecondary.find((item) => item.isActive);

    const coupon = currentSecondaryMethod?.meta?.coupon;
    const bonus = currentSecondaryMethod?.meta?.bonus;

    if (coupon) {
      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.coupon] = String(coupon?.number);
      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.purchase_discount] = Number(
        currentSecondaryMethod.meta?.coupon?.amount?.value,
      );
    }

    if (bonus) {
      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.used_bonus] = Number(
        bonus?.currentAmount?.value,
      );
    }

    checkout.products.forEach((product) => {
      const price = Number(priceVat ? product.priceVat?.value : product.priceNonVat?.value);
      const itemVariant = product.isVinCompatibility
        ? EcommerceProductVariantsEnum.vin_compitable
        : EcommerceProductVariantsEnum.vin_incompitable;

      const productData: EventPayloadPurchaseItemsType = {
        [EcommerceItemsEnum.item_id]: Number(product.articleId),
        [EcommerceItemsEnum.item_name]: String(product.name),
        [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
        [EcommerceItemsEnum.item_brand]: String(product.brand?.name),
        ...(product.genericId && { [EcommerceItemsEnum.item_category3]: product.genericId }),
        [EcommerceEnum.item_list_id]: itemListId,
        [EcommerceItemsEnum.item_variant]: itemVariant,
        [EcommerceItemsEnum.price]: price,
        [EcommerceItemsEnum.quantity]: Number(product.quantity?.value),
      };

      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items].push(productData);
    });

    return segment;
  }
}

export const purchaseEvent: PurchaseEvent = new PurchaseEvent();
