// Core
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Definitions
import type {
  PriceVatSwitchOptionType,
  PriceVatSwitchType,
  PriceVatSwitchInitialType,
  PriceVatSwitchValueType,
} from "./config";

// Components
import { SkeletonItems } from "components/ui/SkeletonItems";
import { Text } from "components/ui/Typography/Text";
import { FormGeneral } from "components/ui/FormFields/FormGeneral";
import { InputForm } from "components/ui/FormFields/InputForm";
import { WrappedFormRadioGroup } from "components/common/WrappedFormRadioGroup";

// Utils
import st from "./styles.module.less";
import { priceVatSwitchForm, PRICE_VAT_SWITCH_FIELD } from "./config";

export type PriceVatSwitchFormProps = {
  loading?: boolean;
  disabled?: boolean;
  label?: string;
  initialValues?: PriceVatSwitchInitialType | null;
  options?: PriceVatSwitchOptionType[];
  onSubmit?: (value: { showPriceVat: PriceVatSwitchValueType }) => void;
};

export const PriceVatSwitchForm = (props: PriceVatSwitchFormProps) => {
  const { loading, label, disabled, initialValues = null, options = [], onSubmit } = props;

  const initialValueOption = options?.find(
    (option) => option.value === initialValues?.showPriceVat,
  );

  const formProps = useForm<PriceVatSwitchType>({
    defaultValues: {
      ...priceVatSwitchForm.shape,
      ...(initialValueOption && {
        showPriceVat: initialValueOption,
      }),
    },
    resolver: zodResolver(priceVatSwitchForm.schema()),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, watch, handleSubmit } = formProps;

  const handleSubmitForm = handleSubmit((value: PriceVatSwitchType): void => {
    value.showPriceVat && onSubmit?.({ showPriceVat: value.showPriceVat.value });
  });

  useEffect(() => {
    const subscription = watch(() => {
      void handleSubmitForm();
    });
    return () => subscription.unsubscribe();
  }, [watch, onSubmit]);

  return (
    <FormGeneral
      layout="vertical"
      size="middle"
      className={st["price-vat-switch"]}
      data-test-id="price-vat-switch"
    >
      <div className={st["price-vat-switch-text"]}>
        <Text font="system" size="14" alignmentVertical="middle" testId="price-vat-switch-label">
          {label}
        </Text>
      </div>
      {loading ? (
        <SkeletonItems items={1} active className={st["price-vat-switch-skeleton"]} />
      ) : (
        <div className={st["price-vat-switch-toggle"]}>
          <InputForm
            id={PRICE_VAT_SWITCH_FIELD.showPriceVat}
            name={PRICE_VAT_SWITCH_FIELD.showPriceVat}
            loading={loading}
            type="no-gutter"
          >
            <WrappedFormRadioGroup
              name={PRICE_VAT_SWITCH_FIELD.showPriceVat}
              control={control}
              options={options}
              hasDisabled={disabled}
              optionType="button"
              type="primary"
              buttonStyle="icon"
            />
          </InputForm>
        </div>
      )}
    </FormGeneral>
  );
};
