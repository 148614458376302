// Core
import type { FC, ReactNode } from "react";
import cx from "classnames";

// Components
import { Grid } from "antd";

// Utils
import st from "./Styles.module.less";

type ContainerProps = {
  children: ReactNode;
  full?: boolean;
};

export const Container: FC<ContainerProps> = ({ full, children = null }) => {
  const { md = true, lg = true } = Grid.useBreakpoint();
  const containerStyle = cx(st.container, {
    [st.mobile]: !md,
    [st.tablet]: !lg,
    [st.full]: full,
  });

  return <div className={containerStyle}>{children}</div>;
};
