// Core
import getConfig from "next/config";

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig;

export const VERIFY_ENV = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  STAGING: "staging",
} as const;

export const isDevelopment = process.env.BUILD_LOGGER === VERIFY_ENV.DEVELOPMENT;

export const verifyEnvironment = (): {
  isDevelopment: boolean;
  isProduction: boolean;
  isStaging: boolean;
} => {
  const isDevelopmentRuntime = publicRuntimeConfig?.BUILD_LOGGER === VERIFY_ENV.DEVELOPMENT;
  const isStagingRuntime = publicRuntimeConfig?.BUILD_LOGGER === VERIFY_ENV.STAGING;
  const isProductionRuntime = publicRuntimeConfig?.BUILD_LOGGER === VERIFY_ENV.PRODUCTION;

  return {
    isDevelopment: isDevelopmentRuntime,
    isProduction: isProductionRuntime,
    isStaging: isStagingRuntime,
  };
};
