// Components
import { SearchBarForm } from "client/core/search-bar/components/search-bar-form";
import { SearchBarOption } from "client/core/search-bar/components/search-bar-option";

// Bus
import { useSearchAutocomplete } from "client/core/search-bar/hooks/use-search-autocomplete";
import { useSearchNavigate } from "client/core/search-bar/hooks/use-search-navigate";

// Definitions
import type { SearchAutocompleteOptionType, SearchBarType } from "client/core/search-bar/models";
import type { SearchBarOptionType } from "client/core/search-bar/components/search-bar-form";

// Utils
import st from "./styles.module.less";

export const SearchBar = () => {
  const {
    loadingSearchAutocomplete,
    onFetchSearchAutocomplete,
    autocompleteOptions,
    keyword: searchKey,
  } = useSearchAutocomplete();
  const { onSearchNavigate } = useSearchNavigate();

  const optionJSX = (
    option: SearchAutocompleteOptionType,
    searchStr: string,
  ): SearchBarOptionType => {
    const { label, keyword, entity } = option;

    return {
      id: 0,
      value: label,
      label: <SearchBarOption label={label} keyword={searchStr} />,
      keyword,
      entity,
      type: option.type,
    };
  };
  const options = !searchKey ? [] : autocompleteOptions.map((o) => optionJSX(o, searchKey));

  const handleSelectSearchOption = (data: { values: SearchBarType }) => {
    onSearchNavigate(data);
  };

  return (
    <div className={st["search-bar"]} data-test-id="main-search">
      <SearchBarForm
        loadingAutocomplete={loadingSearchAutocomplete}
        options={options}
        onSubmit={handleSelectSearchOption}
        onSearch={onFetchSearchAutocomplete}
      />
    </div>
  );
};
