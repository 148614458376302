// Core
import { createContext } from "react";

type CartPreviewVehiclesCacheType = {
  edit: boolean;
  select: boolean;
  setEdit: (value: boolean) => void;
  setSelect: (value: boolean) => void;
};

export const cartPreviewVehiclesCache = createContext<CartPreviewVehiclesCacheType>({
  edit: false,
  select: false,
  setEdit: () => {},
  setSelect: () => {},
});
