// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventViewItemListPayloadType,
  EventViewItemListDataType,
  EventsDataUnionType,
  EventPayloadProductItemType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  EcommerceEnum,
  ITEM_LIST_ID_TYPE,
  AFFILIATION_TYPE,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
} from "client/utils/gtm/gtm.types";

class ViewItemListEvent extends AbstractGTMEventSegment<GTMTypesEnum.view_item_list> {
  protected readonly segmentShape: EventViewItemListPayloadType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.catalog,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.item_list_id]: ITEM_LIST_ID_TYPE["catalog-items"],
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventViewItemListPayloadType {
    const { route, carId, itemListId, productsItems, categoryId, subCategoryId } =
      data as EventViewItemListDataType;
    const segment = this.getSegment();

    if (route) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    }
    if (carId) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.car_id] = carId;
    }

    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.item_list_id] = itemListId;

    productsItems.forEach((product, productIndex) => {
      const { priceVat } = super.getPriceConfig(String(product.priceNonVat?.country));
      const price = Number(priceVat ? product.vatPrice?.value : product.priceNonVat?.value);
      const itemVariant = product.isVinCompatibility
        ? EcommerceProductVariantsEnum.vin_compitable
        : EcommerceProductVariantsEnum.vin_incompitable;

      const productGtmData: EventPayloadProductItemType = {
        [EcommerceItemsEnum.item_id]: Number(product.articleId),
        [EcommerceItemsEnum.item_name]: product.name,
        [EcommerceItemsEnum.item_brand]: String(product.brand?.name),
        [EcommerceItemsEnum.price]: price,
        [EcommerceItemsEnum.quantity]: Number(product.quantity?.value),
        [EcommerceItemsEnum.item_variant]: itemVariant,
        [EcommerceItemsEnum.item_list_id]: itemListId,
        [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
        ...(categoryId && { [EcommerceItemsEnum.item_category]: categoryId }),
        ...(subCategoryId && { [EcommerceItemsEnum.item_category2]: subCategoryId }),
        ...(product.genericId && { [EcommerceItemsEnum.item_category3]: product.genericId }),
      };

      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items][productIndex] = productGtmData;
    });

    return segment;
  }
}

export const viewItemListEvent: ViewItemListEvent = new ViewItemListEvent();
