// Core
import { useState, useEffect, forwardRef } from "react";
import cx from "classnames";

// Definitions
import type { ReactNode } from "react";

// Components
import { InputNumber, InputNumberProps } from "antd";
import { Icon } from "../../Icon";

// Utils
import st from "./Styles.module.less";

export type InputCounterType = InputNumberProps & {
  placeholder?: string;
  min?: number;
  max?: number;
  step?: number | null;
  value?: { value: number; step: number; available: number };
  hasDisabled?: boolean;
  isTextCentered?: boolean;
  onChange?: (value: number) => void;
  onOpenTooltip?: (value: boolean) => void;
  children?: ReactNode | Iterable<ReactNode>;
};

export const InputCounter = forwardRef<HTMLInputElement, InputCounterType>((props, ref) => {
  const {
    className,
    hasDisabled = false,
    size = "large",
    min = 1,
    max = 999,
    step = 1,
    value = { value: 1, step: 1, available: 999 },
    placeholder,
    isTextCentered = false,
    onChange = () => {},
    onOpenTooltip,
    ...rest
  } = props;

  const inputStyle = cx(
    st["input-counter"],
    {
      [st.disabled]: hasDisabled,
      [st.centered]: isTextCentered,
    },
    className,
  );
  const [quantity, setQuantity] = useState<number>(value?.value);

  useEffect(() => {
    if (value?.value) {
      setQuantity(value.value);
    }
  }, [value]);

  const handleChange = (changeValue: number | string | null) => {
    const val = Number(changeValue);
    if (val && val % step === 0) {
      setQuantity(val);
      onChange && onChange(val);
    }
  };

  const handleMouseEnter = () => {
    onOpenTooltip?.(true);
  };

  const handleMouseLeave = () => {
    onOpenTooltip?.(false);
  };

  return (
    <InputNumber
      {...rest}
      className={inputStyle}
      size={size}
      disabled={hasDisabled}
      ref={ref}
      min={min}
      max={max || value?.available}
      step={step}
      value={quantity}
      placeholder={placeholder}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      controls={{
        upIcon: (
          <div
            className={st["count-up-icon"]}
            data-test-id="button-plus"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon name={"ArrowUpCounter"} size="mini" />
          </div>
        ),
        downIcon: (
          <div className={st["count-down-icon"]} data-test-id="button-minus">
            <Icon name={"ArrowDownCounter"} size="mini" />
          </div>
        ),
      }}
      onChange={handleChange}
    />
  );
});
InputCounter.displayName = "InputCounter";
