// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  ParametersEnum,
  EventConsentDefPayloadType,
  GTMTypesEnum,
  ConsentEnum,
  EventsDataUnionType,
  EventConsentDefDataType,
} from "client/utils/gtm/gtm.types";

class ConsentDefEvent extends AbstractGTMEventSegment<GTMTypesEnum.consent_mode_def> {
  protected readonly segmentShape = {
    [ParametersEnum.ad_storage_def]: ConsentEnum.denied,
    [ParametersEnum.analytics_storage_def]: ConsentEnum.denied,
  };

  makeSegment(data: EventsDataUnionType): EventConsentDefPayloadType {
    const consentData = data as EventConsentDefDataType;
    const segment = this.getSegment();

    return {
      ...segment,
      ...(consentData[ParametersEnum.statistics] && {
        [ParametersEnum.analytics_storage_def]: ConsentEnum.granted,
      }),
    };
  }
}

export const consentDefEvent: ConsentDefEvent = new ConsentDefEvent();
