// Core
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Title } from "components/ui/Typography/Title";
import { LightList } from "components/ui/LightList";

// Definitions
import { UserCountryCodesKeys } from "bus/user/models";
import { FOOTER_MENU_ITEM } from "client/utils/gtm/gtm.types";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";
import { footerMenuItemsPrivate } from "data/footer";
import { footerNavModelNew } from "utils/renderModels/footerNavModelNew";

// Domains
import { useProfileContext } from "hooks/useProfileContext";
import { triggerFooterMenuGtmEvent } from "utils/footer-gtm-events";

export const FooterMenuPrivate = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const countryCode = profile?.countryCode?.toLocaleLowerCase() as UserCountryCodesKeys;
  const menuItems = footerMenuItemsPrivate(countryCode, profile?.permission?.pages);

  const handleTrackFooterLinkClick = (link: keyof typeof FOOTER_MENU_ITEM) => {
    triggerFooterMenuGtmEvent({ actionType: link });
  };

  const { about: pagesAbout, support: pagesSupport, services: pagesServices } = menuItems;
  const aboutListItems = footerNavModelNew({
    items: pagesAbout.items,
    t,
    onEvent: handleTrackFooterLinkClick,
  });
  const supportListItems = footerNavModelNew({
    items: pagesSupport.items,
    t,
    onEvent: handleTrackFooterLinkClick,
  });
  const servicesListItems = footerNavModelNew({
    items: pagesServices.items,
    t,
    onEvent: handleTrackFooterLinkClick,
  });

  return (
    <Row gutter={rowGutters} align="top" justify="space-between" className={st["footer-menu"]}>
      <Col span={8}>
        <Title
          tag="h5"
          size="16"
          fontWeight="600"
          color="blue-600"
          transform="uppercase"
          className={st["footer-menu-title"]}
        >
          {t(pagesAbout.title)}
        </Title>
        <LightList mode="vertical" items={aboutListItems} />
      </Col>
      <Col span={8}>
        <Title
          tag="h5"
          size="16"
          fontWeight="600"
          color="blue-600"
          transform="uppercase"
          className={st["footer-menu-title"]}
        >
          {t(pagesSupport.title)}
        </Title>
        <LightList mode="vertical" items={supportListItems} />
      </Col>

      <Col span={8}>
        <div className={st["footer-last"]}>
          <Title
            tag="h5"
            size="16"
            fontWeight="600"
            color="blue-600"
            transform="uppercase"
            className={st["footer-menu-title"]}
          >
            {t(pagesServices.title)}
          </Title>
          <LightList mode="vertical" items={servicesListItems} />
        </div>
      </Col>
    </Row>
  );
};
