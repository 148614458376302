// Core
import { PhoneNumberFormatter } from "utils/phoneNumberFormat/phone-number-format";

// Definitions
import type {
  FormatPhoneOptions,
  PhoneNumberFormatType,
} from "utils/phoneNumberFormat/phone-number-format";

const PHONE_COUNTRY_CODE = {
  EN: "EN",
  FR: "FR",
  DE: "DE",
  AT: "AT",
  NL: "NL",
} as const;

type UserCountryCodes = keyof typeof PHONE_COUNTRY_CODE;

const formatter = new PhoneNumberFormatter();
const phoneNumberFormats = formatter.getPhoneNumberFormats();

export const getFormatPhoneNumber = (
  phoneNumber: string,
  country: UserCountryCodes = PHONE_COUNTRY_CODE.EN,
): string | null => {
  const countriesFormat: Record<UserCountryCodes, PhoneNumberFormatType> = {
    EN: phoneNumberFormats.SPECIAL,
    FR: phoneNumberFormats.SPECIAL,
    DE: phoneNumberFormats.INTERNATIONAL,
    AT: phoneNumberFormats.INTERNATIONAL,
    NL: phoneNumberFormats.INTERNATIONAL,
  } as const;

  const optionsFormatter: FormatPhoneOptions = {
    regionCode: country,
    formatPhone: countriesFormat[country],
  };

  return formatter.formatPhoneNumber(phoneNumber, optionsFormatter);
};
