// Core
import { clone } from "ramda";

// Definitions
import {
  EventPayloadType,
  EventsDataUnionType,
  GTMTypesEnum,
  GTMSegmentBuilderInterface,
} from "client/utils/gtm/gtm.types";

// Utils
import { currencyConfig } from "utils/currencyFormat/country-config";
import { checkIsVatCountry } from "utils/countries";

export abstract class AbstractGTMEventSegment<T extends GTMTypesEnum>
  implements GTMSegmentBuilderInterface<T>
{
  protected readonly APP_CONFIG = {
    currency: currencyConfig,
    checkIsVatCountry,
  };

  protected getSegment() {
    return clone(this.segmentShape);
  }

  protected getPriceConfig(country: string) {
    return {
      currency: this.APP_CONFIG.currency[country].currencyIso,
      priceVat: this.APP_CONFIG.checkIsVatCountry(country),
    };
  }

  protected abstract readonly segmentShape: EventPayloadType[T];

  abstract makeSegment(data: EventsDataUnionType): EventPayloadType[T];
}
