// Core
import { useEffect } from "react";

// Utils
import { gtmService } from "client/utils/gtm";
import { GTMTypesEnum, VisitorTypeEnum } from "client/utils/gtm/gtm.types";
import { getUniqueID } from "utils/helpers";

type ArgsType = {
  profileId?: number | null;
  profileCountryCode?: string;
};

export const useGTMPageLoad = (args: ArgsType) => {
  const { profileId = null, profileCountryCode } = args;

  useEffect(() => {
    gtmService?.event(GTMTypesEnum.pageLoad, {
      crm_session_id: getUniqueID(),
      ...(profileId && {
        user_id: profileId,
      }),
      ...(profileCountryCode && {
        geo_country_code: profileCountryCode,
      }),
      visitor_type: profileId ? VisitorTypeEnum.logged_user : VisitorTypeEnum.unknown_user,
    });
  }, [profileId, profileCountryCode]);
};
