// Definitions
import { GTMTypesEnum, FOOTER_MENU_ITEM } from "client/utils/gtm/gtm.types";

// Utils
import { gtmService } from "client/utils/gtm";

type EventFooterMenuDataType = {
  actionType: keyof typeof FOOTER_MENU_ITEM;
};

export const triggerFooterMenuGtmEvent = (data: EventFooterMenuDataType) => {
  const { actionType } = data;
  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.footer_menu, { actionType });
};
