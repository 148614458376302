// Core
import { useCallback } from "react";

// Definitions
import { HttpStatusCode } from "models/Http";
import type { CartTabProductType } from "client/core/cart/models";
import { ITEM_LIST_ID_TYPE, ROUTE_NAME_KEYS } from "client/utils/gtm/gtm.types";

// Domains
import { cartKeys } from "client/core/cart/store";

// Utils
import {
  getHttpErrorMessageNotFoundProductWithTranslations,
  getHttpErrorMessageWithTranslations,
} from "client/core/cart/utils/notification-messages";
import { queryClient } from "init/queryClient";
import { notificationService } from "utils/notifications";
import { useCartPreviewProductRemoveMutation } from "client/core/cart/hooks/mutations/use-cart-preview-product-remove-mutation";
import { triggerRemoveFromCartGtmEvent } from "client/core/cart/utils/cart-gtm-events";

type UseCartPreviewProductRemove = {
  loadingCartPreviewProductRemove?: boolean;
  onRemoveCartPreviewProduct: (data: CartTabProductType) => void;
};

type UseCallbackRemoveCartPreviewProduct = (data: CartTabProductType) => void;

export const useCartPreviewProductRemove = (): UseCartPreviewProductRemove => {
  const { loadingCartPreviewProductRemove, onRemoveRepairCartPreviewProduct } =
    useCartPreviewProductRemoveMutation();

  const onRemoveCartPreviewProduct = useCallback<UseCallbackRemoveCartPreviewProduct>((data) => {
    void (async () => {
      try {
        const [removeRepairCartPreviewProductError] = await onRemoveRepairCartPreviewProduct({
          id: data.id,
        });

        triggerRemoveFromCartGtmEvent({
          product: data,
          itemListId: ITEM_LIST_ID_TYPE["cart-items"],
          route: ROUTE_NAME_KEYS.cart,
        });

        if (removeRepairCartPreviewProductError) {
          const isError400 =
            removeRepairCartPreviewProductError.status === HttpStatusCode.badRequest;
          const isError404 = removeRepairCartPreviewProductError.status === HttpStatusCode.notFound;
          const isError500 = removeRepairCartPreviewProductError.status >= HttpStatusCode.server;

          if (isError404) {
            return notificationService.showError(
              getHttpErrorMessageNotFoundProductWithTranslations(),
            );
          }

          if (isError400 || isError500) {
            return notificationService.showError(getHttpErrorMessageWithTranslations());
          }

          throw new Error("Something went wrong in cart preview product remove flow");
        }

        await Promise.all([
          queryClient.refetchQueries([cartKeys.preview], { exact: true }, { throwOnError: true }),
        ]);
      } catch (error) {
        console.warn(error);
        notificationService.showError(getHttpErrorMessageWithTranslations());
      }
    })();
  }, []);

  return {
    loadingCartPreviewProductRemove,
    onRemoveCartPreviewProduct,
  };
};
