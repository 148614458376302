// Definitions
import type { ReactNode } from "react";
import type { CartTabProductType } from "client/core/cart/models";

// Components
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { WrapperDateDefault } from "components/common/WrapperDateDefault";
import { WrapperPrice } from "components/common/WrapperPrice";
import { CartCellProductImage } from "client/core/cart/components/cart-cell-product-image";
import { CartCellName } from "client/core/cart/components/cart-cell-name";
import { CartCellText } from "client/core/cart/components/cart-cell-text";
import { CartCellQuantity } from "client/core/cart/components/cart-cell-quantity";

// Hooks
import { useProfileContext } from "hooks/useProfileContext";
import { useCartPreviewProductsRow } from "client/core/cart/hooks/use-cart-preview-products-row";

const DATE_FORMAT_TABLE = "DD/MM/YY";

export const CELL_CUSTOM_FIELD_TYPE = {
  counter: "counter",
  ["image-thumb"]: "image-thumb",
  cartArticleNo: "cartArticleNo",
  brandAndName: "brandAndName",
  deliveryAt: "deliveryAt",
  priceRecommended: "priceRecommended",
  discount: "discount",
  pledge: "pledge",
  priceTotalVat: "priceTotalVat",
  priceTotalNonVat: "priceTotalNonVat",
  action: "action",
} as const;
type CellCustomFieldType = keyof typeof CELL_CUSTOM_FIELD_TYPE;

type CartCellCustomProps = {
  inputType: CellCustomFieldType;
  children: ReactNode;
  className: string;
  record?: CartTabProductType;
};

export const CartCellCustom = (props: CartCellCustomProps) => {
  const { inputType, children, record, ...restProps } = props;
  const { name, isDisabled, onChange, tooltip } = useCartPreviewProductsRow();
  const { profile } = useProfileContext();

  const isCellDisabled = record && "isAvailable" in record && !record?.isAvailable;

  const cmpProps = {
    ...restProps,
  };

  switch (inputType) {
    case CELL_CUSTOM_FIELD_TYPE.counter:
      return (
        <td {...cmpProps} data-test-id="cell-quantity">
          <CartCellQuantity
            name={name}
            isDisabled={isDisabled || isCellDisabled}
            onUpdate={onChange}
            tooltip={tooltip}
          />
        </td>
      );

    case CELL_CUSTOM_FIELD_TYPE["image-thumb"]:
      return (
        <td {...cmpProps} data-test-id="cell-image-thumb">
          {CartCellProductImage({
            image: record?.images?.thumb,
            withEnlarge: true,
          })}
        </td>
      );
    case CELL_CUSTOM_FIELD_TYPE.cartArticleNo:
      return (
        <td {...cmpProps} data-test-id="cell-article-number">
          {WrapperNodeDefault(record?.articleNo, isCellDisabled)}
        </td>
      );
    case CELL_CUSTOM_FIELD_TYPE.brandAndName:
      return (
        <td {...cmpProps}>
          {CartCellName({
            brand: { name: String(record?.brand?.name) },
            name: record?.name,
            disabled: isCellDisabled,
            testId: "cell-brand",
          })}
        </td>
      );
    case CELL_CUSTOM_FIELD_TYPE.deliveryAt:
      return (
        <td {...cmpProps} data-test-id="cell-delivery-at">
          {WrapperDateDefault(
            record?.deliveryAt,
            DATE_FORMAT_TABLE,
            profile?.countryCode.toLocaleLowerCase(),
            isCellDisabled,
          )}
        </td>
      );
    case CELL_CUSTOM_FIELD_TYPE.priceRecommended:
      return (
        <td {...cmpProps}>
          {WrapperPrice({
            country: record?.priceRecommended?.country,
            value: record?.priceRecommended?.value,
            isEmpty: true,
            disabled: isCellDisabled,
            testId: "cell-price-recommended",
          })}
        </td>
      );
    case CELL_CUSTOM_FIELD_TYPE.discount:
      return (
        <td {...cmpProps}>
          {CartCellText({
            value: record?.margin,
            postfix: "%",
            disabled: isCellDisabled,
            testId: "cell-discount",
            textProps: {
              size: "14",
            },
          })}
        </td>
      );
    case CELL_CUSTOM_FIELD_TYPE.pledge:
      return (
        <td {...cmpProps}>
          {WrapperPrice({
            country: record?.pledge?.country,
            value: record?.pledge?.value,
            isEmpty: true,
            disabled: isCellDisabled,
            testId: "cell-pledge",
          })}
        </td>
      );
    case CELL_CUSTOM_FIELD_TYPE.priceTotalVat:
      return (
        <td {...cmpProps}>
          {WrapperPrice({
            country: record?.priceTotalVat?.country,
            value: record?.priceTotalVat?.value,
            isEmpty: true,
            disabled: isCellDisabled,
            testId: "cell-price-total-vat",
          })}
        </td>
      );

    case CELL_CUSTOM_FIELD_TYPE.priceTotalNonVat:
      return (
        <td {...cmpProps}>
          {WrapperPrice({
            country: record?.priceTotalNonVat?.country,
            value: record?.priceTotalNonVat?.value,
            isEmpty: true,
            disabled: isCellDisabled,
            testId: "cell-price-total-non-vat",
          })}
        </td>
      );
    default:
      return <td {...cmpProps}>{children}</td>;
  }
};
