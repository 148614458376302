// Core
import { useCallback } from "react";

// Definitions
import type { CartParamsType, CartPreviewType } from "client/core/cart/models";

// Domains
import { cartKeys, initialCartState } from "client/core/cart/store";

// Utils
import { queryClient } from "init/queryClient";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";
import { notificationService } from "utils/notifications";
import { getHttpErrorMessageNotFoundProductWithTranslations } from "client/core/cart/utils/notification-messages";
import { cartService } from "client/core/cart/service";

type UseCallbackUpdateCartPreview = (data: CartPreviewType) => void;

type UseCartPreviewUpdate = {
  loadingCartPreviewUpdate: boolean;
  onUpdateCartPreview: UseCallbackUpdateCartPreview;
};
type CartPreviewUpdateMutationMutate = Promise<CartParamsType>;

export const useCartPreviewUpdate = (): UseCartPreviewUpdate => {
  const updateCartPreviewProps = useMutationAsync<
    CartPreviewType,
    CartPreviewType,
    CartPreviewUpdateMutationMutate,
    [string] | string[]
  >({
    key: [cartKeys.preview],
    name: "updateCartPreviewMutation",
    fetcher: cartService.updateCartPreview,
    handlerError404: async () => {
      await Promise.resolve(
        notificationService.showError(getHttpErrorMessageNotFoundProductWithTranslations()),
      );
    },
    options: {
      onSuccess: (data) => {
        if (!data) return;

        const queryCartKey = [cartKeys.preview];
        queryClient.setQueryData<CartPreviewType>(queryCartKey, (prevState) => {
          if (!prevState?.tabs.length) {
            return initialCartState.preview;
          }

          return {
            ...prevState,
            totalPrice: data.totalPrice,
            totalPriceVat: data.totalPriceVat,
            vatPercentage: data.vatPercentage,
            vatPrice: data.vatPrice,
            deliveryDate: data.deliveryDate,
            selected: data.selected,
            checkoutAvailable: data.checkoutAvailable,
            tabs: data.tabs,
          };
        });
      },
    },
  });

  const onUpdateCartPreview = useCallback<UseCallbackUpdateCartPreview>(
    (data) => {
      void (async () => {
        await updateCartPreviewProps.mutateAsync(data);
      })();
    },
    [updateCartPreviewProps],
  );

  return {
    loadingCartPreviewUpdate: updateCartPreviewProps.isLoading,
    onUpdateCartPreview,
  };
};
