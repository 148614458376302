// Core
import dayjs from "dayjs";

// Definitions
import { Locale } from "antd/lib/locale-provider";
import {
  UserCountryCodesEnum,
  UserLanguagesEnum,
  type UserCountryCodesKeys,
} from "bus/user/models";

// Config Antd
import en from "antd/lib/locale/en_US";
import fr from "antd/lib/locale/fr_FR";
import de from "antd/lib/locale/de_DE";
import nl from "antd/lib/locale/nl_NL";

// Config DayJS
import DayJSLocalizedFormat from "dayjs/plugin/localizedFormat";
/* eslint-disable import/no-unassigned-import */
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/de";
/* eslint-enable import/no-unassigned-import */
// Set
dayjs.extend(DayJSLocalizedFormat);

const LOCALES_BY_COUNTRY = {
  [UserCountryCodesEnum.en]: "en-FR",
  [UserCountryCodesEnum.fr]: "fr-FR",
  [UserCountryCodesEnum.de]: "de-DE",
  [UserCountryCodesEnum.at]: "de-AT",
  [UserCountryCodesEnum.nl]: "nl-NL",
} as const;

const LANGUAGE_BY_LOCALES = {
  "en-FR": UserLanguagesEnum.en,
  "fr-FR": UserLanguagesEnum.fr,
  "de-DE": UserLanguagesEnum.de,
  "de-AT": UserLanguagesEnum.de,
  "nl-NL": UserLanguagesEnum.nl,
} as const;

export const antdLocales = (locale: string) => {
  dayjs.locale(locale);
  const ANTD_LOCALES_MAP: { [key: string]: Locale } = {
    en,
    fr,
    de,
    nl,
  };
  return ANTD_LOCALES_MAP[locale];
};

export const getLocaleByCountry = (country?: UserCountryCodesKeys) => {
  const locale = country ? LOCALES_BY_COUNTRY[country] : void 0;
  const language = locale ? LANGUAGE_BY_LOCALES[locale] : LANGUAGE_BY_LOCALES["en-FR"];

  return {
    locale,
    language,
  };
};
