// Core
import { useState, useCallback, createContext, PropsWithChildren } from "react";

// Definitions
import type { AuthSessionType } from "bus/auth/models";

type AuthSessionValueType = AuthSessionType & {
  isAuthenticated: boolean;
};
type AuthSessionStoreType = {
  data: AuthSessionValueType | null;
  onClear: () => void;
};
export const authSessionStore = createContext<AuthSessionStoreType>({
  data: null,
  onClear: () => void 0,
});

export const AuthSessionProvider = ({
  children,
  session,
}: PropsWithChildren<{ session?: AuthSessionType | null }>) => {
  const initialData = {
    token: session?.token || "",
    isAuthenticated: !!session?.token || false,
  };

  const [data, setData] = useState<AuthSessionValueType | null>(initialData || null);

  const onClear = useCallback(() => {
    setData(() => null);
  }, []);

  return (
    <authSessionStore.Provider
      value={{
        data,
        onClear,
      }}
    >
      {children}
    </authSessionStore.Provider>
  );
};
