// Core
import { useContext } from "react";

// Utils
import { cartPreviewProductsRowCache } from "client/core/cart/contexts/cart-preview-products-row";

export const useCartPreviewProductsRow = () => {
  const context = useContext(cartPreviewProductsRowCache);
  if (!context) {
    throw new Error(
      "useCartPreviewProductsRow should only be called inside CartPreviewProductsRowProvider",
    );
  }

  return context;
};
