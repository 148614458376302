// Definitions
import type { GenesysConfigType, GenesysUserDataType } from "public/genesys/genesys";

// Utils
import { verifyEnvironment } from "utils/verify-environment";

export class GenesysChat {
  private static _instance: GenesysChat;

  constructor(userDataParams: GenesysUserDataType, config?: GenesysConfigType) {
    if (!GenesysChat._instance) {
      const chatConfig: GenesysConfigType = {
        main: {
          ...GenesysChatWidget.config.main,
          ...config?.main,
        },
        webchat: {
          ...GenesysChatWidget.config.webchat,
          ...config?.webchat,
        },
      };
      CXBus.configure({
        debug: verifyEnvironment().isDevelopment,
        pluginsPath: "/genesys/plugins/",
      });
      CXBus.loadPlugin("widgets-core");
      GenesysChatWidget.initGenesys(userDataParams, chatConfig);
    }
    return GenesysChat._instance;
  }

  public async openChat(): Promise<void> {
    return new Promise((resolve) => {
      CXBus.subscribe("App.ready", () => {
        window._genesys?.widgets?.bus?.command("CS-Chat-Extension.StartCustomChat");
        resolve();
      });
    });
  }

  public async closeChat(): Promise<void> {
    return new Promise((resolve) => {
      CXBus.subscribe("App.ready", () => {
        window._genesys?.widgets?.bus?.command("CS-Chat-Extension.CloseCustomChat");
        resolve();
      });
    });
  }
}
