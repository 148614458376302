// Core
import currency from "currency.js";
import { replace } from "ramda";

import type { CurrencyConfigType } from "./country-config";
import { currencyConfig } from "./country-config";

export class CurrencyFormat {
  private config;

  constructor(config: CurrencyConfigType) {
    this.config = config;
  }

  public fromFormat(value: string | number | null = 0): number {
    const strValue = String(value);
    const { separator = "", decimal = "" } = this.config;
    const regexpSeparators = new RegExp(`\\${separator}`, "g");
    const regexpDecimal = new RegExp(`\\${decimal}`);
    const replaceSeparators = replace(regexpSeparators, "", strValue);
    const replaceDecimal = replace(regexpDecimal, ".", replaceSeparators);
    const currencyValue = currency(replaceDecimal, {
      separator: "",
      pattern: "#",
      negativePattern: "-#",
      errorOnInvalid: true,
    });
    return Number(currencyValue.format());
  }

  public toFormat(value: number | null = 0): string {
    const currencyValue = currency(Number(value), {
      ...this.config,
      errorOnInvalid: true,
    });
    return currencyValue.format();
  }

  public getCurrency = () => {
    return this.config.symbol;
  };
}

export const currencyFormatService = (
  country: string = "EN",
  isNumber: boolean = false,
): CurrencyFormat => {
  return new CurrencyFormat({
    ...currencyConfig[country.toLocaleUpperCase()],
    ...(isNumber && { pattern: "#", negativePattern: "-#" }),
  });
};
