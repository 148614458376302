// Definitions
import { UserCountryCodesEnum } from "bus/user/models";
import type { UserCountryCodesKeys } from "bus/user/models";

// Utils
import { book } from "init/book";
import { getFilteredMenuItems } from "utils/helpers";

const FOOTER_MENU_ITEM = {
  cookie: "cookie",
  legal_notice: "legal_notice",
  about: "about",
  go_core: "go_core",
  benefits: "benefits",
  "term-conditions": "term-conditions",
  "benefits-terms-conditions": "benefits-terms-conditions",
  "privacy-policy": "privacy-policy",
  code_of_conduct: "code_of_conduct",
  payment: "payment",
  delivery: "delivery",
  "core-exchange": "core-exchange",
  returns_refunds: "returns_refunds",
} as const;

export type MenuNavItemData = {
  key: string;
  href: string;
  text?: string;
  icon?: string;
  asBlankLink?: boolean;
  analyticKey: keyof typeof FOOTER_MENU_ITEM;
  handleTrackClick?: (params: { actionType: keyof typeof FOOTER_MENU_ITEM }) => void;
};

export type MenuData = {
  title: string;
  items: MenuNavItemData[];
};

export const getGoCorePDF = (country?: UserCountryCodesKeys) => {
  const countryCode = country || UserCountryCodesEnum.en;

  const fileList: Record<UserCountryCodesKeys, string> = {
    [UserCountryCodesEnum.en]:
      "https://scdn.autodoc.pro/gocore_brochure/en/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.fr]:
      "https://scdn.autodoc.pro/gocore_brochure/fr/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.de]:
      "https://scdn.autodoc.pro/gocore_brochure/de/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.at]:
      "https://scdn.autodoc.pro/gocore_brochure/de/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.nl]:
      "https://scdn.autodoc.pro/gocore_brochure/nl/Brochure%20for%20AUTODOC%20PRO.pdf",
  };

  return fileList[countryCode] ? fileList[countryCode] : fileList[UserCountryCodesEnum.en];
};

const menuAbout = (): MenuData => {
  return {
    title: "common:navigation.footer.aboutTitle",
    items: [
      {
        key: "1",
        href: book.companyInfo,
        text: "common:navigation.footer.companyInfo",
        analyticKey: FOOTER_MENU_ITEM.legal_notice,
      },
      {
        key: "2",
        href: book.about,
        text: "common:navigation.footer.aboutUs",
        analyticKey: FOOTER_MENU_ITEM.about,
      },
      {
        key: "3",
        href: book.benefits,
        text: "common:navigation.footer.benefits",
        analyticKey: FOOTER_MENU_ITEM.benefits,
      },
      // {
      //   key: "4",
      //   href: getGoCorePDF(country),
      //   text: "common:navigation.footer.goCore",
      //   asBlankLink: true,
      //   analyticKey: FOOTER_MENU_ITEM["go_core"],
      // },
    ],
  };
};

const menuAboutPublic = (): MenuData => {
  return {
    title: "common:navigation.footer.aboutTitle",
    items: [
      {
        key: "1",
        href: book.about,
        text: "common:navigation.footer.aboutUs",
        analyticKey: FOOTER_MENU_ITEM.about,
      },
      {
        key: "2",
        href: book.companyInfo,
        text: "common:navigation.footer.companyInfo",
        analyticKey: FOOTER_MENU_ITEM.legal_notice,
      },
      {
        key: "3",
        href: book.benefits,
        text: "common:navigation.footer.benefits",
        analyticKey: FOOTER_MENU_ITEM.benefits,
      },
      // {
      //   key: "4",
      //   href: getGoCorePDF(country),
      //   text: "common:navigation.footer.goCore",
      //   asBlankLink: true,
      //   analyticKey: FOOTER_MENU_ITEM["go_core"],
      // },
    ],
  };
};

export const getCodeOfConductPDF = (country?: UserCountryCodesKeys) => {
  const countryCode = country || UserCountryCodesEnum.en;
  const fileList: Record<UserCountryCodesKeys, string> = {
    [UserCountryCodesEnum.en]:
      "https://scdn.autodoc.pro/code_of_conduct/en/Code%20of%20Conduct.pdf",
    [UserCountryCodesEnum.fr]:
      "https://scdn.autodoc.pro/code_of_conduct/fr/Code%20de%20conduite.pdf",
    [UserCountryCodesEnum.de]:
      "https://scdn.autodoc.pro/code_of_conduct/de/Code%20of%20Conduct.pdf",
    [UserCountryCodesEnum.at]:
      "https://scdn.autodoc.pro/code_of_conduct/de/Code%20of%20Conduct.pdf",
    [UserCountryCodesEnum.nl]:
      "https://scdn.autodoc.pro/code_of_conduct/nl/Code%20of%20Conduct.pdf",
  };
  return fileList[countryCode] ? fileList[countryCode] : fileList[UserCountryCodesEnum.en];
};

const menuSupport = (country?: UserCountryCodesKeys): MenuData => ({
  title: "common:navigation.footer.supportTitle",
  items: [
    // {
    //   key: "1",
    //   href: book.helpCentre,
    //   text: "common:navigation.footer.helpCentre",
    // },
    {
      key: "1",
      href: book.termConditions,
      text: "common:navigation.footer.termConditions",
      analyticKey: FOOTER_MENU_ITEM["term-conditions"],
    },
    // {
    //   key: "3",
    //   href: book.returnsDraft,
    //   text: "common:navigation.footer.withdrawal",
    // },
    {
      key: "2",
      href: book.benefitsTermsConditions,
      text: "common:navigation.footer.benefitsTermConditions",
      analyticKey: FOOTER_MENU_ITEM["benefits-terms-conditions"],
    },
    {
      key: "3",
      href: book.privacyPolicy,
      text: "common:navigation.footer.privacyPolicy",
      analyticKey: FOOTER_MENU_ITEM["privacy-policy"],
    },
    {
      key: "4",
      href: getCodeOfConductPDF(country),
      text: "common:navigation.footer.codeOfConduct",
      asBlankLink: true,
      analyticKey: FOOTER_MENU_ITEM.code_of_conduct,
    },
  ],
});

const menuSupportPublic = (country?: UserCountryCodesKeys): MenuData => ({
  title: "common:navigation.footer.supportTitle",
  items: [
    {
      key: "1",
      href: book.termConditions,
      text: "common:navigation.footer.termConditions",
      analyticKey: FOOTER_MENU_ITEM["term-conditions"],
    },
    {
      key: "2",
      href: book.benefitsTermsConditions,
      text: "common:navigation.footer.benefitsTermConditions",
      analyticKey: FOOTER_MENU_ITEM["benefits-terms-conditions"],
    },
    {
      key: "3",
      href: book.privacyPolicy,
      text: "common:navigation.footer.privacyPolicy",
      analyticKey: FOOTER_MENU_ITEM["privacy-policy"],
    },
    {
      key: "4",
      href: getCodeOfConductPDF(country),
      text: "common:navigation.footer.codeOfConduct",
      asBlankLink: true,
      analyticKey: FOOTER_MENU_ITEM.code_of_conduct,
    },
  ],
});

const menuServicesPrivate = (country?: UserCountryCodesKeys): MenuData => {
  const menuServicesCommon = {
    title: "common:navigation.footer.servicesTitle",
    items: [
      {
        key: "1",
        href: book.payment,
        text: "common:navigation.footer.payment",
        analyticKey: FOOTER_MENU_ITEM.payment,
      },
      {
        key: "2",
        href: book.delivery,
        text: "common:navigation.footer.delivery",
        analyticKey: FOOTER_MENU_ITEM.delivery,
      },
      {
        key: "3",
        href: book.coreExchange,
        text: "common:navigation.footer.coreExchange",
        analyticKey: FOOTER_MENU_ITEM["core-exchange"],
      },
      {
        key: "4",
        href: book.returnsAndRefunds,
        text: "common:navigation.footer.returnsRefunds",
        analyticKey: FOOTER_MENU_ITEM.returns_refunds,
      },
    ],
  };

  const menuServicesMap: { [key in UserCountryCodesEnum]: MenuData } = {
    [UserCountryCodesEnum.de]: menuServicesCommon,
    [UserCountryCodesEnum.at]: menuServicesCommon,
    [UserCountryCodesEnum.fr]: menuServicesCommon,
    [UserCountryCodesEnum.en]: menuServicesCommon,
    [UserCountryCodesEnum.nl]: menuServicesCommon,
  };

  return country ? menuServicesMap[country] : menuServicesMap.en;
};

const menuServicesPublic: MenuData = {
  title: "common:navigation.footer.servicesTitle",
  items: [
    {
      key: "1",
      href: book.payment,
      text: "common:navigation.footer.payment",
      analyticKey: FOOTER_MENU_ITEM.payment,
    },
    {
      key: "2",
      href: book.delivery,
      text: "common:navigation.footer.delivery",
      analyticKey: FOOTER_MENU_ITEM.delivery,
    },
    {
      key: "3",
      href: book.coreExchange,
      text: "common:navigation.footer.coreExchange",
      analyticKey: FOOTER_MENU_ITEM["core-exchange"],
    },
    {
      key: "4",
      href: book.returnsAndRefunds,
      text: "common:navigation.footer.returnsRefunds",
      analyticKey: FOOTER_MENU_ITEM.returns_refunds,
    },
  ],
};

export const footerMenuItemsPrivate = (country?: UserCountryCodesKeys, pages?: string[]) => {
  return {
    about: getFilteredMenuItems(menuAbout(), pages),
    support: getFilteredMenuItems(menuSupport(country), pages),
    services: getFilteredMenuItems(menuServicesPrivate(country), pages),
  };
};

export const footerMenuItemsPublic = (country?: UserCountryCodesKeys, pages?: string[]) => {
  return {
    about: getFilteredMenuItems(menuAboutPublic(), pages),
    support: getFilteredMenuItems(menuSupportPublic(country), pages),
    services: getFilteredMenuItems(menuServicesPublic, pages),
  };
};
