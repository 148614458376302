// Core
import { mergeRight } from "ramda";
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventsDataUnionType,
  EventCatalogCloseTabPayloadType,
  EventCatalogCloseTabDataType,
} from "client/utils/gtm/gtm.types";

class CatalogCloseTabEvent extends AbstractGTMEventSegment<GTMTypesEnum.close_tab> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: ParametersEnum.free_search,
    },
  };

  makeSegment(data: EventsDataUnionType): EventCatalogCloseTabPayloadType {
    const catalogTabData = data as EventCatalogCloseTabDataType;
    const params = mergeRight(this.segmentShape[PayloadKeysEnum.parameters], catalogTabData);
    return {
      [PayloadKeysEnum.parameters]: params,
    };
  }
}

export const catalogCloseTabEvent: CatalogCloseTabEvent = new CatalogCloseTabEvent();
