// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadRemoveFromCartType,
  EventRemoveFromCartDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  EcommerceEnum,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
  AFFILIATION_TYPE,
} from "client/utils/gtm/gtm.types";

class RemoveFromCartEvent extends AbstractGTMEventSegment<GTMTypesEnum.remove_from_cart> {
  protected readonly segmentShape: EventPayloadRemoveFromCartType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.cart,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadRemoveFromCartType {
    const { route, product, itemListId } = data as EventRemoveFromCartDataType;
    const { currency, priceVat } = super.getPriceConfig(String(product.priceNonVat?.country));
    const price = Number(priceVat ? product.priceVat?.value : product.priceNonVat?.value);
    const itemVariant = product.isVinCompatibility
      ? EcommerceProductVariantsEnum.vin_compitable
      : EcommerceProductVariantsEnum.vin_incompitable;
    const segment = this.getSegment();

    if (route) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    }

    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(product.deliveryAt);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = currency;
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] =
      price * Number(product.quantity?.value);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items][0] = {
      [EcommerceItemsEnum.item_id]: Number(product.articleId),
      [EcommerceItemsEnum.item_name]: String(product.name),
      [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
      [EcommerceItemsEnum.item_brand]: String(product.brand?.name),
      ...(product.genericId && { [EcommerceItemsEnum.item_category3]: product.genericId }),
      [EcommerceEnum.item_list_id]: itemListId,
      [EcommerceItemsEnum.item_variant]: itemVariant,
      [EcommerceItemsEnum.price]: price,
      [EcommerceItemsEnum.quantity]: Number(product.quantity?.value),
    };

    return segment;
  }
}

export const removeFromCartEvent: RemoveFromCartEvent = new RemoveFromCartEvent();
