// Core
import { useTranslation } from "next-i18next";

// Components
import { Link } from "components/common/Link";
import { Col } from "components/ui/Col";
import { Skeleton } from "components/ui/Skeleton";
import { Row } from "components/ui/Row";
import { Button } from "components/ui/Button";
import { Image } from "components/ui/Image";
import { Icon } from "components/ui/Icon";
import { Title } from "components/ui/Typography/Title";

// Definitions
import type { ProfileType } from "client/core/profile/models";
import type { PersonalManagerType } from "client/core/personal-manager/models";

// Assets
import { default as avatarDefault } from "assets/images/avatar-default.jpg";

// Utils
import st from "./styles.module.less";
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { getFormatPhoneNumber } from "client/core/personal-manager/utils/phone-format";

type PersonalManagerAdviserCardPropsType = {
  loadingGenesysUserDataFetch?: boolean;
  loadingPersonalManagerFetch?: boolean;
  personalManager?: PersonalManagerType | null;
  profileCountryCode?: ProfileType["countryCode"];
  onRequestCallback?: () => void;
  onRequestChat?: () => void;
};

export const PersonalManagerAdviserCard = (props: PersonalManagerAdviserCardPropsType) => {
  const {
    loadingGenesysUserDataFetch,
    loadingPersonalManagerFetch,
    personalManager = null,
    profileCountryCode,
    onRequestCallback,
    onRequestChat,
  } = props;
  const { t } = useTranslation();

  const fullName =
    personalManager?.firstName || personalManager?.lastName
      ? `${String(personalManager?.firstName)} ${String(personalManager?.lastName)}`
      : t("personal-manager:personalManager.noName");
  const phone =
    personalManager?.phone && profileCountryCode
      ? getFormatPhoneNumber(String(personalManager?.phone), profileCountryCode)
      : t("personal-manager:personalManager.noPhone");
  const email = personalManager?.email ?? t("personal-manager:personalManager.noEmail");
  const photo = personalManager?.photo ?? avatarDefault.src;

  return (
    <div className={st.card}>
      <div className={st["card-title"]}>
        <Title tag="h4">{t("personal-manager:cardInfoTitle")}</Title>
      </div>
      <div className={st["card-content"]}>
        {loadingPersonalManagerFetch ? (
          <Skeleton avatar={{ size: 80 }} paragraph={{ rows: 2 }} active />
        ) : (
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
            <Col span={4}>
              <div className={st.image}>
                <Image src={photo} alt={fullName} objectFit="contain" width={80} height={80} />
              </div>
            </Col>
            <Col span={20}>
              <div className={st.content}>
                <div className={st.title}>
                  <Title tag="h5" size="16">
                    {fullName}
                  </Title>
                </div>

                <ul className={st.info}>
                  <li className={st["info-item"]}>
                    <Icon name="Phone" className={st.icon} size="mini" />
                    <span>{phone}</span>
                  </li>
                  <li className={st["info-item"]}>
                    <Icon name="Envelop" className={st.icon} />
                    {personalManager?.email ? (
                      <Link href={`mailto:${personalManager?.email}`} blank asNextLink={false}>
                        {email}
                      </Link>
                    ) : (
                      <span>{email}</span>
                    )}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Row gutter={ROW_GUTTER_TYPE.md.horizontal} className={st["card-action-btns"]}>
        <Col offset={4} flex="auto" span={10} className={st["card-action-btns-btn"]}>
          {loadingPersonalManagerFetch ? (
            <Skeleton.Button size="large" block active />
          ) : (
            <Button type="primary" ghost size="large" block onClick={onRequestCallback}>
              {t("personal-manager:callBack.buttonLabel")}
            </Button>
          )}
        </Col>
        <Col span={10} flex="auto" className={st["card-action-btns-btn"]}>
          {loadingPersonalManagerFetch && loadingGenesysUserDataFetch ? (
            <Skeleton.Button size="large" block active />
          ) : (
            <Button type="primary" size="large" block onClick={onRequestChat}>
              {t("personal-manager:chat.buttonStart")}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
