// Core
import { useCallback } from "react";

// Definitions
import type { BaseQueryKey, BaseEmptyType } from "models/Base";
import type { FormSubmitParams } from "models/Forms";
import type { PersonalManagerRatingType } from "client/core/personal-manager/models";

// Domains
import { personalManagerKeys } from "client/core/personal-manager/store";

// Utils
import {
  getHttpSuccessMessageWithTranslations,
  getHttpErrorMessageNoPersonalManagerWithTranslations,
} from "client/core/personal-manager/utils/notification-messages";
import { notificationService } from "utils/notifications";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";
import { personalManagerService } from "client/core/personal-manager/service";

type ProfileManagerRatingCreateMutationMutate = Promise<{ data: PersonalManagerRatingType }>;
type OnCreateProfileManagerRatingCbType = (
  data: FormSubmitParams<PersonalManagerRatingType>,
) => void;
type UsePersonalManagerRatingCreate = {
  loadingProfileManagerRatingCreate: boolean;
  onCreateProfileManagerRating: OnCreateProfileManagerRatingCbType;
};

export const usePersonalManagerRatingCreate = (): UsePersonalManagerRatingCreate => {
  const queryKey: BaseQueryKey = [personalManagerKeys.personalManagerRating];
  const createProfileManagerRatingProps = useMutationAsync<
    BaseEmptyType,
    PersonalManagerRatingType,
    ProfileManagerRatingCreateMutationMutate,
    BaseQueryKey
  >({
    key: queryKey,
    name: "createPersonalManagerRatingMutation",
    fetcher: personalManagerService.createProfilePersonalManagerRating,
    handlerError422: async () =>
      Promise.resolve(
        notificationService.showError(getHttpErrorMessageNoPersonalManagerWithTranslations()),
      ),
    options: {
      onSuccess: () => notificationService.showSuccess(getHttpSuccessMessageWithTranslations()),
    },
  });

  const onCreateProfileManagerRating = useCallback<OnCreateProfileManagerRatingCbType>(
    (data) => {
      void (async () => {
        await createProfileManagerRatingProps.mutateAsync(data.values);
      })();
    },
    [createProfileManagerRatingProps],
  );

  return {
    loadingProfileManagerRatingCreate: createProfileManagerRatingProps.isLoading,
    onCreateProfileManagerRating,
  };
};
