// Core
import React, { ReactElement, forwardRef } from "react";

// Components
import { Input } from "antd";

// Definitions
import { TextAreaProps } from "antd/lib/input";

export const InputTextArea = forwardRef<HTMLInputElement, TextAreaProps>(
  (props, ref): ReactElement => {
    return <Input.TextArea {...props} ref={ref} />;
  },
);
InputTextArea.displayName = "InputTextArea";
