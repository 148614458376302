// Definitions
import type { SearchAutocompleteType } from "client/core/search-bar/models";

export const searchKeys = {
  autocomplete: "search/autocomplete",
};

export type SearchStateType = {
  autocomplete: SearchAutocompleteType;
};

export const initialSearchState: SearchStateType = {
  autocomplete: {
    items: [],
  },
};
