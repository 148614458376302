// Core
import React, { PropsWithChildren, ReactElement } from "react";

// Definitions
import type { UserGeoType } from "bus/user/models";

type ContentType = {
  geo: UserGeoType;
  setGeo: (geo: UserGeoType) => void;
};

type GeoProviderPropsType = {
  geo?: UserGeoType;
  children: ReactElement;
};

const Context = React.createContext<ContentType>({
  geo: null,
  setGeo: () => {},
});

export const GeoContextProvider = ({ children, geo }: PropsWithChildren<GeoProviderPropsType>) => {
  const valueGeo = geo || null;

  const [data, setData] = React.useState<UserGeoType>(valueGeo);

  const newValue = valueGeo?.country ? valueGeo : data;

  return (
    <Context.Provider
      value={{
        geo: newValue,
        setGeo: setData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useGeoContext = () => React.useContext(Context);
