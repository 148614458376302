// Definitions
import { BaseMutationPayloadType } from "models/Base";
import { FetchDataType, HttpStatusCode } from "models/Http";
import { AxiosError } from "axios";

// Utils
import { HttpError } from "utils/http-error";
import { verifyBrowser } from "utils/verify-browser";
import { verifyEnvironment } from "utils/verify-environment";
import { logger } from "utils/logger";
import { getFormatErrorValidation, isApiError } from "utils/helpers";

const CLIENT_API_INITIAL_ERR = {
  status: 500,
  types: {
    general: {
      error: {
        type: "ClientErrorGeneral",
        message: "Unexpected error",
      },
    },
    network: {
      error: {
        type: "ClientErrorNetwork",
        message: "Can't get response because has bad network connection or invalid request",
      },
    },
    validation: {
      error: {
        type: "ClientErrorValidation",
        message: "Response error format invalid",
      },
    },
  },
} as const;

const statusCodes = [HttpStatusCode.success, HttpStatusCode.created, HttpStatusCode.noContent];

type QueryFetcherPropsType<TQueryFnData, TFetcherPayload> = {
  name: string;
  fetcher: FetchDataType<TQueryFnData>;
  fetcherPayload?: TFetcherPayload;
  fetcherType?: "query" | "mutation";
  producer?: "Client" | "Server";
};

export const queryFetcher = async <
  TQueryFnData = unknown,
  TFetcherPayload = BaseMutationPayloadType,
>(
  props: QueryFetcherPropsType<TQueryFnData, TFetcherPayload>,
): Promise<TQueryFnData> => {
  const { name, fetcher, fetcherType = "query", fetcherPayload, producer = "Client" } = props;
  try {
    const { isDevelopment } = verifyEnvironment();

    try {
      if (isDevelopment) {
        if (verifyBrowser()) {
          logger.browserReactQueryInformationLogger.info({
            isStarted: true,
            operation: fetcherType,
            document: name,
            producer,
            ...(fetcherPayload && fetcherPayload),
          });
        } else {
          logger.serverDevelopmentLogger.info(
            `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was started`,
          );
        }
      }

      const response = await fetcher(fetcherPayload);

      const { data, status } = response;

      if (!statusCodes.includes(status)) {
        throw new HttpError(
          CLIENT_API_INITIAL_ERR.status,
          CLIENT_API_INITIAL_ERR.types.general.error,
        );
      }

      const result = data?.data;

      if (isDevelopment) {
        if (verifyBrowser()) {
          logger.browserReactQueryInformationLogger.info({
            isFinished: true,
            operation: fetcherType,
            document: name,
            producer: "Client",
            status,
            data,
          });
        } else {
          logger.serverDevelopmentLogger.info(
            `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was finished with status ${status}`,
          );
        }
      }

      return await Promise.resolve(result || ({} as TQueryFnData));
    } catch (error) {
      // HttpError
      if (error instanceof HttpError) {
        const statusCode = error?.status || Number(CLIENT_API_INITIAL_ERR.status);
        const data = error ? { name: error.name, message: error.message } : null;

        if (isDevelopment) {
          if (verifyBrowser()) {
            logger.browserReactQueryInformationLogger.error({
              isFinished: true,
              operation: fetcherType,
              document: name,
              producer: "Client",
              status: statusCode,
              data,
            });
          } else {
            logger.serverDevelopmentLogger.error(
              `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was finished with status ${statusCode}`,
            );
          }
        }

        const errShape = {
          type: "ClientErrorHttp",
          message: error.message,
          status: error.status,
          ...(error.errorCode && { errorCode: error.errorCode }),
        };
        throw new HttpError(Number(error.status), errShape);
      }

      // AxiosErrors
      if (error instanceof AxiosError) {
        const statusCode =
          error instanceof AxiosError
            ? error?.response?.status || Number(CLIENT_API_INITIAL_ERR.status)
            : Number(CLIENT_API_INITIAL_ERR.status);
        const data = error instanceof AxiosError ? error?.response?.data : null;

        if (isDevelopment) {
          if (verifyBrowser()) {
            logger.browserReactQueryInformationLogger.error({
              isFinished: true,
              operation: fetcherType,
              document: name,
              producer: "Client",
              status: statusCode,
              data,
            });
          } else {
            logger.serverDevelopmentLogger.error(
              `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was finished with status ${statusCode}`,
            );
          }
        }

        // AxiosNetworkError
        if (error.code === "ERR_NETWORK") {
          throw new HttpError(statusCode, CLIENT_API_INITIAL_ERR.types.network.error);
        }

        // AxiosBaseError with custome validation error in data
        if (!isApiError(data)) {
          throw new HttpError(statusCode, CLIENT_API_INITIAL_ERR.types.validation.error);
        }
        // AxiosBaseError
        const errLocalized = getFormatErrorValidation(data?.error);
        throw new HttpError(statusCode, errLocalized);
      }

      const statusCode = Number(CLIENT_API_INITIAL_ERR.status);
      const data = null;

      if (isDevelopment) {
        if (verifyBrowser()) {
          logger.browserReactQueryInformationLogger.error({
            isFinished: true,
            operation: fetcherType,
            document: name,
            producer: "Client",
            status: statusCode,
            data,
          });
        } else {
          logger.serverDevelopmentLogger.error(
            `ReactQuery {"document": "${name}", operation: "${fetcherType}", producer: "SSR Server" } was finished with status ${statusCode}`,
          );
        }
      }

      // AnyOtherError
      throw new HttpError(CLIENT_API_INITIAL_ERR.status, {
        ...CLIENT_API_INITIAL_ERR.types.general.error,
        type: "ClientErrorGeneral",
      });
    }
  } catch (err) {
    if (err instanceof HttpError) {
      const errShape = {
        type: err.name,
        message: err.message,
        status: err.status,
        errors: err.errors,
        ...(err.errorCode && { errorCode: err.errorCode }),
      };
      return Promise.reject(errShape);
    }
    return Promise.reject(err);
  }
};
