// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import { HttpStatusCode } from "models/Http";

// Domains
import { cartKeys } from "client/core/cart/store";
import { useSavedBasketCreateMutation } from "client/core/saved-basket-listing/hooks/use-saved-basket-create-mutation";

// Utils
import { book } from "init/book";
import { GET_ENUMS, GET_PARAMS } from "utils/constants";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { notificationService } from "utils/notifications";
import { queryClient } from "init/queryClient";

type OnAddSavedBasketCbType = (v: { vehicleId: number | null }) => void;
type UseCartPreviewSavedBasketCreateType = {
  loadingCartPreviewAddSavedBasketFetch: boolean;
  onAddSavedBasketCartPreview: OnAddSavedBasketCbType;
};

export const useCartPreviewSavedBasketCreate = (): UseCartPreviewSavedBasketCreateType => {
  const router = useRouter();

  const { loadingSavedBasketCreateMutation, onCreateSavedBasketMutation } =
    useSavedBasketCreateMutation();

  const onAddSavedBasketCartPreview = useCallback<OnAddSavedBasketCbType>(({ vehicleId }) => {
    void (async () => {
      try {
        const [createSavedBasketError, createSavedBasketResult] = await onCreateSavedBasketMutation(
          {
            id: Number(vehicleId),
          },
        );

        if (createSavedBasketError) {
          const isError400 = createSavedBasketError.status === HttpStatusCode.badRequest;
          const isError422 = createSavedBasketError.status === HttpStatusCode.unprocessableEntity;
          const isError500 = createSavedBasketError.status >= HttpStatusCode.server;

          if (isError400 || isError500 || isError422) {
            return notificationService.showError(getHttpErrorMessageWithTranslations());
          }
          throw new Error("Something went wrong in saved-basket create flow");
        }

        const savedBasketCreatedId = createSavedBasketResult?.id || 0;

        await router.push({
          pathname: book.cartPreview,
          query: {
            ...router.query,
            [GET_PARAMS.popup]: GET_ENUMS.popup.savedBasketCreate,
            savedBasket: savedBasketCreatedId,
          },
        });
        await Promise.all([
          queryClient.refetchQueries([cartKeys.preview], { exact: true }, { throwOnError: true }),
        ]);
      } catch (error) {
        console.warn(error);
        notificationService.showError(getHttpErrorMessageWithTranslations());
      }
    })();
  }, []);

  return {
    loadingCartPreviewAddSavedBasketFetch: loadingSavedBasketCreateMutation,
    onAddSavedBasketCartPreview,
  };
};
