// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";
import type {
  CartProductParamsType,
  CartProductUpdateType,
  CartPreviewType,
} from "client/core/cart/models";

// Domains
import { cartKeys, initialCartState } from "client/core/cart/store";

// Utils
import { cartService } from "client/core/cart/service";
import { queryClient } from "init/queryClient";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type UseCallbackCartPreviewProductUpdate = (
  data: CartProductUpdateType,
) => Promise<[null, CartPreviewType] | [HttpErrorType]>;

type UseCartPreviewProductUpdateMutation = {
  loadingCartPreviewProductUpdateMutation: boolean;
  onUpdateCartPreviewProductUpdateMutation: UseCallbackCartPreviewProductUpdate;
};

export const useCartPreviewProductUpdateMutation = (): UseCartPreviewProductUpdateMutation => {
  const queryKey = [cartKeys.preview];

  const updateCartPreviewProductProps = useMutationAsync<
    CartPreviewType,
    CartProductUpdateType,
    CartPreviewType,
    string[],
    CartProductParamsType
  >({
    key: queryKey,
    name: "updateCartPreviewProductMutation",
    fetcher: cartService.updateCartPreviewProduct,
    options: {
      onSuccess: (data, payload) => {
        if (!data) return;

        const queryCartKey = [cartKeys.preview];
        queryClient.setQueryData<CartPreviewType>(queryCartKey, (prevState) => {
          if (!prevState?.tabs.length) {
            return initialCartState.preview;
          }

          const tabs = prevState.tabs.map((tab, indexTab) => {
            const products = tab.products.map((product, productIndex) => {
              if (product.id === payload.id) {
                return data.tabs[indexTab].products[productIndex];
              }
              return product;
            });

            return {
              ...tab,
              deliveryDate: data.tabs[indexTab].deliveryDate,
              selected: data.tabs[indexTab].selected,
              products,
            };
          });

          return {
            ...prevState,
            totalPrice: data.totalPrice,
            totalPriceVat: data.totalPriceVat,
            vatPercentage: data.vatPercentage,
            vatPrice: data.vatPrice,
            deliveryDate: data.deliveryDate,
            selected: data.selected,
            checkoutAvailable: data.checkoutAvailable,
            tabs,
          };
        });
      },
      onError: () => void 0,
    },
  });

  const onUpdate = useCallback<UseCallbackCartPreviewProductUpdate>(async (data) => {
    try {
      const updatedData = await updateCartPreviewProductProps.mutateAsync(data);

      return [null, updatedData];
    } catch (err) {
      return [err as HttpErrorType];
    }
  }, []);

  return {
    loadingCartPreviewProductUpdateMutation: updateCartPreviewProductProps.isLoading,
    onUpdateCartPreviewProductUpdateMutation: onUpdate,
  };
};
