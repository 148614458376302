// Core
import QS from "qs";
import { AxiosRequestConfig } from "axios";

// Definitions
import type { HttpRequestParams, HttpResponse } from "models/Http";
import type {
  ProfileVehicleSelectorKbaParamsType,
  ProfileVehicleSelectorAttrsParamsType,
  ProfileVehicleSelectorRegNumberParamsType,
  ProfileVehicleType,
  ProfileVehiclesType,
} from "./models";

// Utils
import { http } from "api/network-provider";

export const profileVehicleService = Object.freeze({
  createRegNum: (values: ProfileVehicleSelectorRegNumberParamsType) => {
    const { data } = values;
    return http.post<HttpResponse<ProfileVehicleType>>("/profile-vehicles/reg-number", data);
  },
  createKba: (values: ProfileVehicleSelectorKbaParamsType) => {
    const { data } = values;
    return http.post<HttpResponse<ProfileVehicleType>>("/profile-vehicles/kba", data);
  },
  createAttrs: (values: ProfileVehicleSelectorAttrsParamsType) => {
    const { data } = values;
    return http.post<HttpResponse<ProfileVehicleType>>("/profile-vehicles/attrs", data);
  },
  fetchVehicle: (
    payload: HttpRequestParams<
      void,
      {
        vehicleId: string;
      }
    >,
  ) => {
    const params = payload?.params;
    const id = params?.vehicleId;
    const config = {
      paramsSerializer: (p: AxiosRequestConfig): string => {
        return QS.stringify(p, { encode: false });
      },
    };
    return http.get<HttpResponse<ProfileVehicleType>>(`/profile-vehicles/${id}`, config);
  },
  fetchVehicles: () => {
    return http.get<HttpResponse<ProfileVehiclesType>>("/profile-vehicles");
  },
});
