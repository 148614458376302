// Components
import { Container } from "components/common/Container";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";

type HeaderBannerSpecialProps = {
  message: string;
};

export const HeaderBannerSpecial = (props: HeaderBannerSpecialProps) => {
  const { message } = props;

  return (
    <div className={st.banner}>
      <Container>
        <Row gutter={rowGutters}>
          <Col span={24}>
            <div className={st.center}>
              <Text font="system" fontWeight="600" transform="up" color="white-100">
                {message}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
