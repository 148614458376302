// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadCartProductItemType,
  EventPayloadViewCartType,
  EventViewCartDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  EcommerceEnum,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
  AFFILIATION_TYPE,
} from "client/utils/gtm/gtm.types";
import type { CartTabProductType } from "client/core/cart/models";

class ViewCartEvent extends AbstractGTMEventSegment<GTMTypesEnum.view_cart> {
  protected readonly segmentShape: EventPayloadViewCartType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.catalog,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadViewCartType {
    const { route, cartPreview, itemListId } = data as EventViewCartDataType;
    const { currency, priceVat } = super.getPriceConfig(String(cartPreview.totalPrice?.country));

    const segment = this.getSegment();

    if (route) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    }

    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(
      cartPreview?.deliveryDate,
    );
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = currency;

    let products: CartTabProductType[] = [];

    (cartPreview.tabs || []).forEach((tab) => {
      products = [...products, ...tab.products];
    });

    let totalPrice = 0;

    products.forEach((product) => {
      const price = Number(priceVat ? product.priceVat?.value : product.priceNonVat?.value);
      const itemVariant = product.isVinCompatibility
        ? EcommerceProductVariantsEnum.vin_compitable
        : EcommerceProductVariantsEnum.vin_incompitable;

      const productData: EventPayloadCartProductItemType = {
        [EcommerceItemsEnum.item_id]: Number(product.articleId),
        [EcommerceItemsEnum.item_name]: String(product.name),
        [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
        [EcommerceItemsEnum.item_brand]: String(product.brand?.name),
        ...(product.genericId && { [EcommerceItemsEnum.item_category3]: product.genericId }),
        [EcommerceEnum.item_list_id]: itemListId,
        [EcommerceItemsEnum.item_variant]: itemVariant,
        [EcommerceItemsEnum.price]: price,
        [EcommerceItemsEnum.quantity]: Number(product.quantity?.value),
      };

      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items].push([productData]);

      totalPrice += price * Number(product.quantity?.value) * 100;
    });

    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] = totalPrice / 100;

    return segment;
  }
}

export const viewCartEvent: ViewCartEvent = new ViewCartEvent();
