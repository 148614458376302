// Core
import { useTranslation } from "next-i18next";
import Router from "next/router";

// Components
import { ErrorReporter } from "./ErrorReporter";
import { Title } from "components/ui/Typography/Title";

export const ClientError = () => {
  const { t } = useTranslation();
  const { reload } = Router;

  const errorMsg = t("common:errorBoundary.title");
  const title = <Title tag="h1">{errorMsg}</Title>;

  return (
    <ErrorReporter
      image={{ src: "/assets/images/error-500.svg", alt: errorMsg }}
      reset={reload}
      title={title}
      btnLabel={t("buttons:tryAgain")}
    />
  );
};
