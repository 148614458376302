// Core
import { AnyAbility, defineAbility, PureAbility } from "@casl/ability";

// Definitions
import type { PermissionFeatType, PermissionFeatAliasType } from "client/core/profile/models";

export class Permission {
  createAbility(permissions?: PermissionFeatType): PureAbility | AnyAbility {
    if (!permissions) {
      return new PureAbility();
    }

    const permissionsKeys = Object.keys(permissions) as Array<PermissionFeatAliasType>;

    return defineAbility((can, cannot) => {
      permissionsKeys.forEach((alias) => {
        const rules = permissions?.[alias]?.rules;
        const list = rules ? Object.entries(rules) : [];
        list.forEach(([rule, status]) => {
          (status ? can : cannot)(rule, alias);
        });
      });
    });
  }
}
