// Core
import React, { FC, ReactElement } from "react";
import cx from "classnames";

// Components
import { Divider } from "antd";

// Utils
import st from "./Styles.module.less";

type DecorBorderProps = {
  children?: never;
  gutter?: "empty" | "small" | "middle" | "large";
  color?: "gray-9";
  className?: string;
  type?: "horizontal" | "vertical";
};

export const DecorDivider: FC<DecorBorderProps> = ({
  className = "",
  gutter = "middle",
  type,
  color = "default",
}): ReactElement => {
  const dividerStyles = cx(
    st["decor-divider"],
    { [st[`gutter-${gutter}`]]: !!gutter, [st[`main-${color}`]]: !!color },
    className,
  );

  return <Divider className={dividerStyles} type={type} />;
};
