// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Components
import { PersonalManagerAdviserCard } from "client/core/personal-manager/components/personal-manager-adviser-card";

// Hooks
import { useProfileContext } from "hooks/useProfileContext";
import { usePersonalManagerAdviserFetch } from "client/core/personal-manager/hooks/use-personal-manager-adviser-fetch";
import { usePersonalManagerAdviserMetadataFetch } from "client/core/personal-manager/hooks/use-personal-manager-metadata-fetch";
import { usePersonaManagerAdviserChatCreate } from "client/core/personal-manager/hooks/use-personal-manager-adviser-chat-create";

// Utils
import { GET_ENUMS, GET_PARAMS } from "utils/constants";

export const PersonalManagerAdviser = () => {
  const router = useRouter();
  const { profile } = useProfileContext();
  const { loadingPersonalManagerAdviserFetch, personalManagerAdviser } =
    usePersonalManagerAdviserFetch();
  const { loadingPersonalManagerAdviserMetadataFetch, personalManagerAdviserMetadata } =
    usePersonalManagerAdviserMetadataFetch();
  const { onStartAdviserChat } = usePersonaManagerAdviserChatCreate({
    loadingMetadata: loadingPersonalManagerAdviserMetadataFetch,
    metadata: personalManagerAdviserMetadata,
    profileLanguage: profile?.language?.toLowerCase(),
  });

  const handleRequestCallback = useCallback(() => {
    void (async () => {
      await router.replace({
        pathname: router.pathname,
        query: {
          [GET_PARAMS.popup]: GET_ENUMS.popup.callBackPersonalManager,
        },
      });
    })();
  }, []);

  return (
    <PersonalManagerAdviserCard
      loadingPersonalManagerFetch={loadingPersonalManagerAdviserFetch}
      loadingGenesysUserDataFetch={loadingPersonalManagerAdviserMetadataFetch}
      personalManager={personalManagerAdviser}
      profileCountryCode={profile?.countryCode}
      onRequestCallback={handleRequestCallback}
      onRequestChat={onStartAdviserChat}
    />
  );
};
