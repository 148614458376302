// Definitions
import { AxiosRequestConfig } from "axios";
import type { HttpResponse } from "models/Http";
import type {
  StaticDataFetchParams,
  StaticDataInnerShape,
  CitySearchQueryParamsType,
  CitySearchType,
  CountriesType,
} from "./models";

// Utils
import { http } from "api/network-provider";
import QS from "qs";
import { HEADER_COUNTRY } from "utils/constants";

export const staticDataService = Object.freeze({
  fetchPublic: (payload: StaticDataFetchParams) => {
    const { params, meta } = payload;
    const config = {
      params,
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
      paramsSerializer: (p: AxiosRequestConfig): string => QS.stringify(p, { encode: false }),
    };

    return http.get<HttpResponse<StaticDataInnerShape>>("/dropdown-options/public", config);
  },
  fetchPrivate: (payload: StaticDataFetchParams) => {
    const { params } = payload;
    const config = {
      params,
      paramsSerializer: (p: AxiosRequestConfig): string => QS.stringify(p, { encode: false }),
    };

    return http.get<HttpResponse<StaticDataInnerShape>>("/dropdown-options/private", config);
  },
  fetchCitySearch: (payload: CitySearchQueryParamsType) => {
    const { params, meta } = payload;

    const config = {
      params,
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
      paramsSerializer: (p: AxiosRequestConfig): string => {
        return QS.stringify(p, { encode: true, arrayFormat: "indices" });
      },
    };
    return http.get<HttpResponse<CitySearchType>>("/dropdown-options/cities", config);
  },
  fetchCountries: () => {
    return http.get<HttpResponse<CountriesType>>("/dropdown-options/countries");
  },
});
