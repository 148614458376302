// Core
import cx from "classnames";

// Components
import { Button as AntButton } from "antd";
import { Tooltip } from "../Tooltip";

// Definitions
import type { ReactNode } from "react";
import type { TooltipPlacement } from "antd/es/tooltip";
import type {
  BaseButtonProps as AntBaseButtonProps,
  ButtonProps as AntButtonProps,
  ButtonHTMLType as AntButtonHTMLType,
} from "antd/es/button/button";
import type { SizeType } from "antd/es/config-provider/SizeContext";

// Utils
import st from "./Styles.module.less";

export type ButtonGeneralType = AntButtonProps["type"];
export type ButtonCustomType =
  | "reset-filter"
  | "article-link"
  | "search-bar"
  | "icon"
  | "icon-active"
  | "customer-card"
  | "customer-vehicle"
  | "customer-vehicle-action"
  | "customer-vehicle-action-del"
  | "download-pdf"
  | "delete-photo"
  | "intro-navs"
  | "read-more-link"
  | "vehicle-history"
  | "vehicle-history-active"
  | "navigation-on-dark";
export type ButtonSizeType = SizeType;
export type ButtonHTMLType = AntButtonHTMLType;
type ButtonMode = "button" | "custom";

export type ButtonProps = AntButtonProps &
  AntBaseButtonProps & {
    type?: ButtonGeneralType;
    mode?: ButtonMode;
    become?: boolean;
    showMore?: boolean;
    addToCart?: boolean;
    extraLarge?: boolean;
    noStyle?: boolean;
    downloadLink?: boolean;
    downlaodInvoice?: boolean;
    htmlType?: ButtonHTMLType;
    size?: ButtonSizeType;
    sizeBtn?: boolean;
    paddingless?: boolean;
    btnType?: ButtonCustomType;
    hasTooltip?: boolean;
    showTooltip?: boolean;
    tooltipPlacement?: TooltipPlacement;
    tooltipContent?: ReactNode;
    testId?: string;
  };

export const Button = (props: ButtonProps) => {
  const {
    children,
    className = "",
    become = false,
    showMore = false,
    addToCart = false,
    extraLarge = false,
    noStyle,
    downloadLink = false,
    downlaodInvoice = false,
    sizeBtn = false,
    paddingless = false,
    btnType,
    hasTooltip = false,
    showTooltip = false,
    tooltipPlacement,
    tooltipContent = null,
    testId,
    type,
    mode = "button",
    onClick,
    ...rest
  } = props;

  const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    if (mode === "custom") {
      evt.preventDefault();
      return onClick?.(evt);
    }
    return onClick?.(evt);
  };

  const btnStyle = cx(
    st.btn,
    {
      [st.become]: become,
      [st.paddingless]: paddingless,
      [st["show-more"]]: showMore,
      [st["add-to-cart"]]: addToCart,
      [st["extra-large"]]: extraLarge,
      [st["no-style"]]: noStyle,
      [st["download-link"]]: downloadLink,
      [st["downlaod-invoice"]]: downlaodInvoice,
      [st["size-btn"]]: sizeBtn,
    },
    btnType && { [st[btnType]]: true },
    className,
  );

  const baseJSX = (
    <AntButton
      className={btnStyle}
      data-test-id={testId}
      type={type}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </AntButton>
  );

  return hasTooltip ? (
    <Tooltip
      overlayClassName={st.tooltip}
      placement={tooltipPlacement}
      title={tooltipContent}
      destroyTooltipOnHide
      {...(showTooltip && { open: true })}
    >
      {baseJSX}
    </Tooltip>
  ) : (
    baseJSX
  );
};
