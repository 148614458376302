// Core
import { mergeRight } from "ramda";
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventsDataUnionType,
  EventCatalogTabPayloadType,
  EventCatalogTabDataType,
} from "client/utils/gtm/gtm.types";

class CatalogTabClickEvent extends AbstractGTMEventSegment<GTMTypesEnum.tab_click> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: ParametersEnum.free_search,
    },
  };

  makeSegment(data: EventsDataUnionType): EventCatalogTabPayloadType {
    const catalogTabData = data as EventCatalogTabDataType;
    const params = mergeRight(this.segmentShape[PayloadKeysEnum.parameters], catalogTabData);
    return {
      [PayloadKeysEnum.parameters]: params,
    };
  }
}

export const catalogTabClickEvent: CatalogTabClickEvent = new CatalogTabClickEvent();
