// Core
import { TFunction } from "next-i18next";

// Definitions
import type { MenuPropsType } from "components/ui/Menu/";
import type { LinkProps } from "components/common/Link";

// Component
import { MenuItem } from "components/ui/Menu";
import { Link } from "components/common/Link";
import { book } from "init/book";

type NavigationItemType = {
  id: number;
  link?: string;
  text?: string;
  icon?: string;
  children?: NavigationItemType[];
};
type MainNavModelProps = {
  items: NavigationItemType[];
  t: TFunction;
  keyPrefix?: string;
};

export const mainNavItemsCatalog = (data: {
  pages?: string[];
  profileVehicleId?: string;
}): NavigationItemType[] => {
  const { pages, profileVehicleId } = data;
  const selectedProfileVehicleId = profileVehicleId || "new";

  const menuList: NavigationItemType[] = [
    {
      id: 1,
      text: "",
      link: book.dashboard,
      icon: "HomeFilled",
    },
    {
      id: 2,
      text: "common:navigation.main.catalog",
      link: `${book.newCatalog}/${selectedProfileVehicleId}`,
    },
    {
      id: 3,
      text: "common:navigation.main.orders",
      link: "orders",
      children: [
        {
          id: 1,
          text: "common:navigation.main.savedBaskets",
          link: `${book.savedBaskets}?sort[id]=desc`,
        },
        {
          id: 3,
          text: "common:navigation.main.orders",
          link: `${book.orders}?sort[id]=desc`,
        },
      ],
    },
    {
      id: 4,
      text: "common:navigation.main.documents",
      link: book.documents,
    },
    {
      id: 5,
      text: "common:navigation.main.returns",
      link: "returns",
      children: [
        {
          id: 5,
          text: "common:navigation.main.returnsNew",
          link: book.returnsDraft,
        },
        {
          id: 4,
          text: "common:navigation.main.returnsHistory",
          link: book.returns,
        },
      ],
    },
    {
      id: 6,
      text: "common:navigation.main.repairEstimates",
      link: book.repairEstimates,
    },
  ];

  return menuList
    .filter((item) => !pages?.includes(String(item.link)))
    .map((item) => ({
      ...item,
      children: item.children?.filter((child) => !pages?.includes(String(child.link))),
    }));
};

export const mainNavModel = (props: MainNavModelProps): MenuPropsType["items"] => {
  const { t, items, keyPrefix } = props;
  const prefix = keyPrefix ? `${keyPrefix}-` : "";

  return items.map((item) => {
    const textValue = item.text ? t(String(item.text)) : "";
    const CellLink = (linkProps: LinkProps) => Link({ ...linkProps, noStyles: true });

    return {
      key: `${prefix}${String(item.id)}`,
      label: MenuItem({
        link: {
          href: item.link,
          component: CellLink,
        },
        hasText: !!item.text,
        hasIcon: !!item.icon,
        text: textValue,
        icon: item.icon,
        isSubMenu: !!item?.children,
      }),
      children: item.children
        ? mainNavModel({ t, items: item.children, keyPrefix: String(item.id) })
        : void 0,
    };
  });
};
