// Components
import { Control, Controller } from "react-hook-form";
import { InputRate } from "components/ui/FormFields/InputRate";

// Definitions
import type { RateProps } from "antd/lib/rate";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormRateType = RateProps & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormRate = (props: WrappedFormRateType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <InputRate {...rest} {...field} ref={field.ref} />;
      }}
    />
  );
};
