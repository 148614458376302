// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import type { SearchBarType } from "client/core/search-bar/models";
import { SEARCH_TYPES_KEYS } from "client/core/search-bar/models";
import type { FormSubmitParams } from "models/Forms";
import { GTMTypesEnum, ParametersEnum } from "client/utils/gtm/gtm.types";

// Utils
import { book } from "init/book";
import { gtmService } from "client/utils/gtm";

type OnSearchNavigateType = (data: FormSubmitParams<SearchBarType>) => void;

type UseSearchNavigateType = {
  onSearchNavigate: OnSearchNavigateType;
};

export const useSearchNavigate = (): UseSearchNavigateType => {
  const router = useRouter();
  const { vehicleId } = router.query;

  const onSearchNavigate = useCallback<OnSearchNavigateType>(
    (data) => {
      const { keyword, entity, search, type } = data.values;
      const selectedProfileVehicleId = vehicleId ? `/${String(vehicleId)}` : "/new";

      void (async () => {
        if (type === SEARCH_TYPES_KEYS.oem) {
          await router.push({
            pathname: `${book.newCatalog}${selectedProfileVehicleId}${book.productsOem}`,
            query: {
              search,
              oemNumber: entity,
            },
          });
          return;
        }

        if (type === SEARCH_TYPES_KEYS.product) {
          await router.push({
            pathname: `${book.newCatalog}${selectedProfileVehicleId}${book.productsOem}/${entity}`,
          });
          return;
        }

        await router.push({
          pathname: `${book.newCatalog}${selectedProfileVehicleId}${book.productsSearch}`,
          query: {
            ...(keyword ? { keyword } : { keyword: search }),
            search,
          },
        });
      })();

      search && gtmService?.event(GTMTypesEnum.search, { [ParametersEnum.query]: search });
    },
    [router],
  );

  return {
    onSearchNavigate,
  };
};
