// Core
import { forwardRef, Ref, useEffect, useState } from "react";
import cx from "classnames";
import dayjs, { Dayjs } from "dayjs";

// Definitions
import type { TimePickerProps } from "../../TimePicker/TimePicker.types";

// Components
import { TimePicker } from "../../TimePicker";

// Utils
import st from "./Styles.module.less";

const DATE_DEFAULT = "1970-01-01";
const DATE_HOURS_FORMAT = "YYYY-MM-DD HH:mm";

export type InputTimePickerType = TimePickerProps & {
  hasDisabled?: boolean;
  value?: string;
  onChange?: (date: Dayjs, value: string) => void;
  defaultValue?: Dayjs | null;
  minDate?: Dayjs | null;
};

export const InputTimePicker = forwardRef(
  (props: InputTimePickerType, ref: Ref<HTMLInputElement>) => {
    const {
      className,
      value,
      onChange,
      hasDisabled = false,
      minDate = null,
      defaultValue = null,
      ...rest
    } = props;

    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(defaultValue);

    useEffect(() => {
      if (value) {
        const formatValue = dayjs(`${DATE_DEFAULT} ${value}`, DATE_HOURS_FORMAT);
        setSelectedDate(formatValue);
      }
    }, [value]);

    const handleChange = (date: Dayjs | null, timeString: string) => {
      const isInvalidDate = minDate ? date?.isBefore(minDate) : true;
      const updateDate = isInvalidDate && minDate ? minDate : date;
      updateDate && setSelectedDate(updateDate);
      onChange?.(updateDate, timeString);
    };

    const inputStyle = cx(
      st.input,
      {
        [st.disabled]: hasDisabled,
      },
      className,
    );

    return (
      <TimePicker
        {...rest}
        value={selectedDate}
        onChange={handleChange}
        className={inputStyle}
        disabled={hasDisabled}
        ref={ref}
      />
    );
  },
);
InputTimePicker.displayName = "InputTimePicker";
