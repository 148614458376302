// Core
import React from "react";
import cx from "classnames";

// Utils
import st from "./Styles.module.less";

type FormBlockType = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  alignmentHorizontal?: "left" | "center" | "right";
  testId?: string;
};

export const FormBlock = (props: FormBlockType) => {
  const { children, testId, className = "", alignmentHorizontal = "left" } = props;
  const blockStyles = cx(st.block, {
    [st[`block-alignment-horizontal-${alignmentHorizontal}`]]: Boolean(alignmentHorizontal),
    [`${className}`]: className,
  });

  return (
    <div className={blockStyles} data-test-id={testId}>
      {children}
    </div>
  );
};
