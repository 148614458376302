// Core
import { ReactElement, useEffect, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Definitions
import type { FormSubmitFn } from "models/Forms";
import type { CartTabType } from "client/core/cart/models";

// Components
import { WrappedFormCheckbox } from "components/common/WrappedFormCheckbox";
import { FormGeneral } from "components/ui/FormFields/FormGeneral";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { InputForm } from "components/ui/FormFields/InputForm";

// Utils
import { getFieldsMetaValidation } from "utils/forms";
import { cartPreviewVehicleForm, CART_PREVIEW_VEHICLE_FIELD_TYPE } from "./config";

type CartPreviewVehicleFormProps = PropsWithChildren<{
  initialValues?: Partial<CartTabType> | null;
  onSubmit?: FormSubmitFn<CartTabType>;
  loadingFetch?: boolean;
  loadingUpdate?: boolean;
  children: ReactElement;
}>;

export const CartPreviewVehicleForm = (props: CartPreviewVehicleFormProps) => {
  const { onSubmit, initialValues = null, children, loadingFetch, loadingUpdate = false } = props;
  const formProps = useForm<CartTabType>({
    defaultValues: { ...cartPreviewVehicleForm.shape, ...initialValues },
    resolver: zodResolver(cartPreviewVehicleForm.schema()),
    mode: "all",
    reValidateMode: "onChange",
  });

  const { control, handleSubmit, formState, setValue, setError, reset, watch } = formProps;
  const { isSubmitting } = formState;

  const handleSubmitForm = handleSubmit((values) => {
    onSubmit?.({ values, acts: { setError, reset } });
  });

  const onSubmitForm = () => {
    void (async () => {
      await handleSubmitForm();
    })();
  };

  const fieldsMeta = getFieldsMetaValidation(CART_PREVIEW_VEHICLE_FIELD_TYPE, formState);

  useEffect(() => {
    initialValues?.selected && setValue("selected", initialValues.selected);
  }, [initialValues]);

  useEffect(() => {
    const subscription = watch(() => void handleSubmitForm());

    return () => subscription.unsubscribe();
  }, [onSubmitForm, watch]);

  return (
    <FormGeneral name="form-cart-vehicles" onFinish={onSubmitForm}>
      <FormBlock>
        <InputForm
          id={CART_PREVIEW_VEHICLE_FIELD_TYPE.selected}
          name={CART_PREVIEW_VEHICLE_FIELD_TYPE.selected}
          loading={loadingFetch || loadingUpdate}
          validateStatus={fieldsMeta.selected.status}
          type="no-gutter"
          skeletonSize="default"
        >
          <WrappedFormCheckbox
            name={CART_PREVIEW_VEHICLE_FIELD_TYPE.selected}
            control={control}
            mode="indeterminate"
            hasDisabled={isSubmitting}
            align="middle"
          >
            {children}
          </WrappedFormCheckbox>
        </InputForm>
      </FormBlock>
    </FormGeneral>
  );
};
