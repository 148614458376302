// Core
import Router from "next/router";

// Definitions
import { HttpErrorType, HttpStatusCode } from "models/Http";

// Utils
import { notificationService } from "utils/notifications";
import {
  getHttpErrorForbiddenMessageWithTranslations,
  getHttpErrorMessageWithTranslations,
} from "utils/notifications-message";
import { queryClient } from "init/queryClient";
import { book } from "init/book";

type QueryErrorType = {
  err: HttpErrorType;
  handlerError404?: () => Promise<void>;
  handlerError422?: () => Promise<void>;
};
export const onError = async ({ err, handlerError404, handlerError422 }: QueryErrorType) => {
  const status = err.status;
  const isError400 = status === HttpStatusCode.badRequest;
  const isError401 = status === HttpStatusCode.auth;
  const isError403 = status === HttpStatusCode.forbidden;
  const isError404 = status === HttpStatusCode.notFound;
  const isError422 = status === HttpStatusCode.unprocessableEntity;

  if (isError403) {
    notificationService.showError(getHttpErrorForbiddenMessageWithTranslations());
    return;
  }
  if (isError404 && handlerError404) {
    await handlerError404();
    return;
  }
  if (isError422 && handlerError422) {
    await handlerError422();
    return;
  }
  if (isError401) {
    await Router.replace(book.signin);
    queryClient.clear();
    return;
  }
  if (isError400) {
    notificationService.showError(getHttpErrorMessageWithTranslations());
    return;
  }
};
