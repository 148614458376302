// Core
import { useTranslation } from "next-i18next";

// Components
import { Container } from "components/common/Container";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Text } from "components/ui/Typography/Text";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import st from "./styles.module.less";

// Hooks
import { useCreateContentBannerContent } from "client/core/header-banner/hooks/use-header-banner-create-content";

const rowGutters = ROW_GUTTER_TYPE.md.horizontal;

export const HeaderBannerOlympicGames = () => {
  const { t } = useTranslation();

  const { content } = useCreateContentBannerContent(
    [t("common:olympicGames"), t("common:serviceAgentHoliday")],
    10000,
  );

  return (
    <div className={st.banner}>
      <Container>
        <Row gutter={rowGutters}>
          <Col span={24}>
            <div className={st.center}>
              <Text font="system" fontWeight="600" color="white-100">
                {content}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
