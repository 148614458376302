// Components
import { Divider as AntDivider } from "antd";

// Definitions
import type { DividerProps as AntDividerProps } from "antd";

type DividerProps = AntDividerProps;

export const Divider = (props: DividerProps) => {
  return <AntDivider {...props} />;
};
