// Core
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

// Components
import { ErrorReporter } from "./ErrorReporter";
import { Text } from "components/ui/Typography/Text";
import { Title } from "components/ui/Typography/Title";

// Hooks
import { useAuth } from "bus/auth/hooks/useAuth";

// Utils
import { book } from "init/book";

export const NotFound = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isAuthenticated } = useAuth();

  const handleClick = () => {
    const redirectPath = isAuthenticated ? book.dashboard : book.signin;
    void (async () => {
      await router.replace(redirectPath);
    })();
  };

  const errorMsg = t("common:errorPageNotFound.title");
  const notFoundTitle = (
    <Title tag="h1" size="38" fontWeight="600">
      {t("common:errorPageNotFound.oops")}
      <br />
      {t("common:errorPageNotFound.title")}
    </Title>
  );
  const notFoundDescription = (
    <Text tag="p" size="14">
      {t("common:errorPageNotFound.description")}
    </Text>
  );

  return (
    <ErrorReporter
      image={{ src: "/assets/images/error-404.svg", alt: errorMsg }}
      reset={handleClick}
      title={notFoundTitle}
      description={notFoundDescription}
      btnLabel={t("buttons:tryAgain")}
    />
  );
};
