// Core
import { FC } from "react";

// Definitions
import type { CartProductAlternative } from "client/core/cart/models";
import type { RowChildrenType } from "components/ui/TableAnt/TableAnt.types";

// Components
import { CartPreviewProductAlternativeRowProvider } from "client/core/cart/components/cart-preview-products-alternative-row-provider";

// Hooks
import { useCartPreviewProductAlternativeUpdate } from "client/core/cart/hooks/use-cart-preview-product-alternative-update";
import { useCartPreviewProductAlternativesStore } from "client/core/cart/hooks/use-cart-preview-product-alternative-store";

type CartPreviewProductAlternativeRowProps = {
  children: RowChildrenType<unknown> | RowChildrenType<CartProductAlternative>[];
};

export const CartPreviewProductAlternativeRow: FC<CartPreviewProductAlternativeRowProps> = ({
  children,
  ...props
}) => {
  const currentProduct = Array.isArray(children) ? children[0].props?.record : null;

  const { productAlternativesParams: paramsStore } = useCartPreviewProductAlternativesStore();
  const { loadingCartPreviewProductAlternativeUpdate, onUpdateCartPreviewProductAlternative } =
    useCartPreviewProductAlternativeUpdate({
      id: Number(paramsStore?.productId || null),
      keyId: Number(currentProduct?.id || null),
    });

  const onSubmitForm = () => {
    onUpdateCartPreviewProductAlternative?.({
      articleId: Number(currentProduct?.articleId || null),
    });
  };

  return (
    <CartPreviewProductAlternativeRowProvider
      onSubmit={onSubmitForm}
      loading={loadingCartPreviewProductAlternativeUpdate}
    >
      <tr {...props}>
        <>{children}</>
      </tr>
    </CartPreviewProductAlternativeRowProvider>
  );
};
