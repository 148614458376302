// Definitions
import type { SettingsPriceType } from "client/core/settings-price-vat/components/settings-price-vat/config";

// Utils
import { useProfileContext } from "hooks/useProfileContext";
import { PRICE_VAT_VIEW_KEYS } from "../models";

type UseSettingsPriceFetchType = {
  settingsPrice: SettingsPriceType;
};

export const useSettingsPriceFetch = (): UseSettingsPriceFetchType => {
  const { profile } = useProfileContext();

  const showPriceVat = profile?.settings?.showPriceVat
    ? PRICE_VAT_VIEW_KEYS.withVat
    : PRICE_VAT_VIEW_KEYS.notVat;

  return {
    settingsPrice: {
      showPriceVat,
    },
  };
};
