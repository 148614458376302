// Core
import { forwardRef } from "react";

// Components
import { Rate } from "antd";

// Definitions
import { RateProps } from "antd/lib/rate";

export const InputRate = forwardRef<HTMLInputElement, RateProps>((props, ref) => {
  return <Rate {...props} ref={ref} />;
});
InputRate.displayName = "InputRate";
