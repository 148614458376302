export const countryIcons = [
  {
    value: "en",
    icon: "IconGB",
  },
  {
    value: "at",
    icon: "CountryAt",
  },
  {
    value: "be",
    icon: "IconBE",
  },
  {
    value: "fi",
    icon: "IconFI",
  },
  {
    value: "en",
    icon: "IconXI",
  },
  {
    value: "de",
    icon: "CountryDe",
  },
  {
    value: "fr",
    icon: "CountryFr",
  },
  {
    value: "pl",
    icon: "IconPL",
  },
  {
    value: "cs",
    icon: "IconCZ",
  },
  {
    value: "da",
    icon: "IconDK",
  },
  {
    value: "el",
    icon: "IconGR",
  },
  {
    value: "es",
    icon: "IconES",
  },
  {
    value: "et",
    icon: "IconEE",
  },
  {
    value: "hu",
    icon: "IconHU",
  },
  {
    value: "it",
    icon: "IconIT",
  },
  {
    value: "lt",
    icon: "IconLT",
  },
  {
    value: "lu",
    icon: "IconLU",
  },
  {
    value: "lv",
    icon: "IconLV",
  },
  {
    value: "nl",
    icon: "CountryNl",
  },
  {
    value: "pt",
    icon: "IconPT",
  },
  {
    value: "ro",
    icon: "IconRO",
  },
  {
    value: "sk",
    icon: "IconSK",
  },
  {
    value: "sl",
    icon: "IconSI",
  },
  {
    value: "se",
    icon: "IconSE",
  },
  {
    value: "bg",
    icon: "IconBG",
  },
];
