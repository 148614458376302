/* Core */
import winston from "winston";

// Utils
import { verifyEnvironment } from "utils/verify-environment";

export const serverDevelopmentLogger = winston.createLogger({
  levels: winston.config.syslog.levels,
  transports: [new winston.transports.Console()],
  format: winston.format.combine(winston.format.colorize(), winston.format.simple()),
  silent: !verifyEnvironment().isDevelopment,
});
