// Core
import { useContext } from "react";

// Utils
import { cartCache } from "client/core/cart/contexts/cart";

export const useCartStore = () => {
  const context = useContext(cartCache);
  if (!context) {
    throw new Error("useCartStore should only be called inside CartStoreProvider");
  }
  return context;
};
