// Core
import Router from "next/router";
import { useTranslation } from "next-i18next";

// Components
import { Container } from "components/common/Container";
import { LayoutApp } from "components/common/LayoutApp";
import { StandardErrorReporter } from "components/ui/Errors";
import { LayoutHeaderPublic } from "components/common/LayoutHeaderPublic";
import { LayoutFooterPublic } from "components/common/LayoutFooterPublic";

export const AppError = () => {
  const { reload } = Router;
  const { t } = useTranslation();

  const title = t("common:errorBoundary.title");
  const btnLabel = t("buttons:tryAgain");

  return (
    <LayoutApp>
      <LayoutHeaderPublic hasActions={false} />
      <Container>
        <StandardErrorReporter
          reset={reload}
          title={title}
          btnLabel={btnLabel}
          image={{ src: "/assets/images/error-500.svg", alt: title }}
        />
      </Container>
      <LayoutFooterPublic />
    </LayoutApp>
  );
};
