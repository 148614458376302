// Definitions
import type { StaticDataInnerShape } from "client/core/personal-manager/models";

// Domain
import { personalManagerKeys } from "client/core/personal-manager/store";
import { personalManagerService } from "client/core/personal-manager/service";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";

type UsePersonalMmanagerAdviserStaticDataFetchType = {
  loadingStaticData: boolean;
  staticData: StaticDataInnerShape | null;
};

export const usePersonalMmanagerAdviserStaticDataFetch =
  (): UsePersonalMmanagerAdviserStaticDataFetchType => {
    const params = { types: ["phoneCodes"] };

    const fetchProps = useQueryAsync<
      StaticDataInnerShape,
      StaticDataInnerShape,
      [string] | string[]
    >({
      key: [personalManagerKeys.staticData],
      name: "fetchPersonalMmanagerAdviserStaticDataQuery",
      fetcher: personalManagerService.fetchPrivateStaticData,
      fetcherPayload: { params },
      options: {
        refetchOnMount: "always",
      },
    });

    return {
      loadingStaticData: fetchProps.isLoading || false,
      staticData: fetchProps?.data || null,
    };
  };
