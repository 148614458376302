// Core
import { createContext } from "react";

// Definitions
import type { FilterParamsType } from "models/Filter";

type ParamsType = FilterParamsType & {
  articleId?: number | null;
  vehicleId?: number | null;
  productId?: number | null;
};

type CartPreviewProductAlternativesCacheType = {
  onClose: () => void;
  onOpen: () => void;
  onChange: (values: ParamsType) => void;
  visibleProductAlternatives: boolean;
  productAlternativesParams: ParamsType;
};

export const cartPreviewProductAlternativesCache =
  createContext<CartPreviewProductAlternativesCacheType>({
    onClose: () => {},
    onOpen: () => {},
    onChange: () => {},
    visibleProductAlternatives: false,
    productAlternativesParams: {},
  });
