// Core
import { i18n } from "next-i18next";

// Components
import { Trans } from "next-i18next";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./styles.module.less";
import { CurrencyFormat } from "utils/currencyFormat/currencyFormat";
import { currencyConfig } from "utils/currencyFormat/country-config";

type BrandImageType = { id?: number | null; src: string; alt: string; blurDataURL?: string };
type BrandType = {
  id: number | null;
  name: string;
  image: BrandImageType | null;
};
type CartCellNameProps = {
  name?: string;
  brand?:
    | BrandType
    | {
        id: number;
        name: string;
      }
    | { name: string }
    | null;
  pledge?: {
    value: number | null;
    country: string;
  } | null;
  text?: string;
  disabled?: boolean;
  isCatalog?: boolean;
  testId?: string;
};

export const CartCellName = (props: CartCellNameProps) => {
  const { name, brand, pledge, text, disabled, isCatalog, testId } = props;

  const currencyFormat = new CurrencyFormat(
    currencyConfig[String(i18n?.language).toLocaleUpperCase()],
  );

  return (
    <div className={st["cell-name"]} data-test-id={testId}>
      {WrapperNodeDefault(name)}
      {brand &&
        (disabled ? (
          <Text size="14" color="gray-400" box="full">
            {brand?.name}
          </Text>
        ) : (
          <Text size="14" color="gray-600" box="full">
            {brand?.name}
          </Text>
        ))}
      {text && (
        <>
          {disabled ? (
            <Text size="14" color="gray-400" box="full">
              {text}
            </Text>
          ) : (
            <Text size="14" color="gray-600" box="full">
              {text}
            </Text>
          )}
        </>
      )}
      {pledge?.value && !isCatalog && (
        <>
          <Trans
            i18nKey={"products:table.pledge"}
            values={{ price: currencyFormat.toFormat(pledge.value) }}
            components={{
              span: <Text size="14" color="gray-600" />,
            }}
          />
        </>
      )}
    </div>
  );
};
