// Core
import { useContext } from "react";

// Utils
import { authSessionStore } from "bus/auth/context";

type UseAuthType = {
  isAuthenticated: boolean;
  authToken: string;
  onClear: () => void;
};

export const useAuth = (): UseAuthType => {
  const context = useContext(authSessionStore);
  if (!context) {
    throw new Error("useAuth should only be called inside AuthSessionProvider");
  }
  return {
    isAuthenticated: Boolean(context.data?.isAuthenticated),
    authToken: String(context.data?.token),
    onClear: context.onClear,
  };
};
