// Core
import Router from "next/router";

// Definitions
import type { ParsedUrlQueryInput } from "node:querystring";

type UrlObject = {
  auth?: string | null | undefined;
  hash?: string | null | undefined;
  host?: string | null | undefined;
  hostname?: string | null | undefined;
  href?: string | null | undefined;
  pathname?: string | null | undefined;
  protocol?: string | null | undefined;
  search?: string | null | undefined;
  slashes?: boolean | null | undefined;
  port?: string | number | null | undefined;
  query?: string | null | ParsedUrlQueryInput | undefined;
};

export type TransitionOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
};
const getRouterConfig = (props?: TransitionOptions): TransitionOptions => {
  return props ? props : { scroll: false, shallow: true };
};
export const routerPush = async (
  path: string | UrlObject,
  options?: TransitionOptions,
): Promise<boolean> => {
  return Router.push(path, void 0, getRouterConfig(options));
};
export const routerReplace = async (
  path: string | UrlObject,
  options?: TransitionOptions,
): Promise<boolean> => {
  return Router.replace(path, void 0, getRouterConfig(options));
};
