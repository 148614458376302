// Core
import QS from "qs";
import { AxiosRequestConfig } from "axios";

// Definitions
import { HttpResponse } from "models/Http";
import type {
  SearchAutocompleteQueryParamsType,
  SearchAutocompleteType,
} from "client/core/search-bar/models";

// Utils
import { http } from "api/network-provider";

export const searchService = Object.freeze({
  fetchSearchAutocomplete: (payload: SearchAutocompleteQueryParamsType) => {
    const { params } = payload;
    const config = {
      params,
      paramsSerializer: (p: AxiosRequestConfig): string => {
        return QS.stringify(p, { encode: false, arrayFormat: "indices" });
      },
    };
    return http.get<HttpResponse<SearchAutocompleteType>>("/search/autocomplete", config);
  },
});
