// Core
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SyntheticEvent } from "react";

// Components
import { Form } from "antd";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Button } from "components/ui/Button";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { InputForm } from "components/ui/FormFields/InputForm";
import { Text } from "components/ui/Typography/Text";
import { WrappedFormTextArea } from "components/common/WrappedFormTextArea";
import { WrappedFormRate } from "components/common/WrappedFormRate";

// Definitions
import type { FormSubmitFn } from "models/Forms";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { getFieldsMetaValidation } from "utils/forms";

import {
  PersonalMangerOperatorAssesmentFormEnum,
  PersonalManagerOperatorAssesmentFormType,
  inputConfig,
  personalManagerOperatorAssesmentForm,
} from "./config";
import st from "./style.module.less";

type PersonalManagerOperatorAssesmentFormProps = {
  onSubmit: FormSubmitFn<PersonalManagerOperatorAssesmentFormType>;
  loading: boolean;
  initialValues?: Partial<PersonalManagerOperatorAssesmentFormType>;
};

export const PersonalManagerOperatorAssesmentForm = (
  props: PersonalManagerOperatorAssesmentFormProps,
) => {
  const { onSubmit, loading = false, initialValues } = props;

  const { t } = useTranslation();

  const formProps = useForm<PersonalManagerOperatorAssesmentFormType>({
    defaultValues: { ...personalManagerOperatorAssesmentForm.shape, ...initialValues },
    resolver: zodResolver(personalManagerOperatorAssesmentForm.schema(t)),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, reset } = formProps;

  const fieldsMeta = getFieldsMetaValidation(PersonalMangerOperatorAssesmentFormEnum, formState);

  const handleSubmitForm = handleSubmit((values: PersonalManagerOperatorAssesmentFormType) => {
    onSubmit({ values });
    reset();
  });

  const onSubmitForm = (event: SyntheticEvent) => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  return (
    <Form onFinish={onSubmitForm}>
      <FormBlock>
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal} justify="space-between">
          <Col xs={24} md={14}>
            <Text size="12">{t("personal-manager:operatorAssesment.description")}</Text>
          </Col>
          <Col xs={24} md={6}>
            <InputForm
              id={PersonalMangerOperatorAssesmentFormEnum.rating}
              name={PersonalMangerOperatorAssesmentFormEnum.rating}
              validateStatus={fieldsMeta.rating.status}
              required
              {...fieldsMeta.rating.helpText}
            >
              <WrappedFormRate
                name={PersonalMangerOperatorAssesmentFormEnum.rating}
                control={control}
              />
            </InputForm>
          </Col>
        </Row>
      </FormBlock>
      <FormBlock>
        <Row>
          <Col xs={24}>
            <InputForm
              id={PersonalMangerOperatorAssesmentFormEnum.comment}
              name={PersonalMangerOperatorAssesmentFormEnum.comment}
              validateStatus={fieldsMeta.comment.status}
              {...fieldsMeta.comment.helpText}
            >
              <WrappedFormTextArea
                className={st["operator-assesment-comment"]}
                name={PersonalMangerOperatorAssesmentFormEnum.comment}
                control={control}
                placeholder={t(
                  inputConfig[PersonalMangerOperatorAssesmentFormEnum.comment].placeholder,
                )}
                maxLength={inputConfig[PersonalMangerOperatorAssesmentFormEnum.comment].max}
              />
            </InputForm>
          </Col>
        </Row>
        <Row>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            disabled={formState.isSubmitting || !formState.touchedFields}
            loading={loading}
          >
            {t("buttons:send")}
          </Button>
        </Row>
      </FormBlock>
    </Form>
  );
};
