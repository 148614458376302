// Core
import { createContext } from "react";

// Definitions
import type { ReturnDraftProductType } from "client/core/return-new/models";

type ReturnNewCacheType = {
  data: ReturnDraftProductType[];
  setData: (value: ReturnDraftProductType[]) => void;
};

export const returnNewCache = createContext<ReturnNewCacheType>({
  data: [],
  setData: () => {},
});
