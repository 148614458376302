// Core
import TagManager from "react-gtm-module";

// Definitions
import {
  EventsDataType,
  GTMConfigType,
  GTMSegmentBuilderInterface,
  GTMServiceInterface,
  GTMTypesEnum,
} from "./gtm.types";

// Service
import { gtmConfig } from "./config";

// Utils
import { verifyEnvironment } from "utils/verify-environment";

const { isDevelopment } = verifyEnvironment();

export class GTMClientService<T extends GTMTypesEnum = GTMTypesEnum>
  implements GTMServiceInterface
{
  private readonly tagManager?: typeof TagManager;

  private readonly eventsHandlers: Map<GTMTypesEnum, GTMSegmentBuilderInterface>;

  private readonly verifyEventConsentGranted: GTMConfigType["checkEventsConsentGranted"];

  private isActive = true;

  private readonly isSafeToInit: boolean = typeof window !== "undefined";

  constructor(config: GTMConfigType) {
    this.verifyEventConsentGranted = config.checkEventsConsentGranted;
    this.eventsHandlers = config.eventsMap;
    config.checkEventsConsentGranted &&
      (this.verifyEventConsentGranted = config.checkEventsConsentGranted);
    config.active && (this.isActive = config.active);

    if (this.isSafeToInit) {
      const tagManagerArgs = {
        gtmId: config.gtmId,
        ...config.gtmArgs,
      };
      this.tagManager = TagManager;
      this.tagManager.initialize(tagManagerArgs);
    }
  }

  public setIsActive(active: boolean) {
    this.isActive = active;
  }

  private verifyEvent(name: T) {
    if (!this.isActive || !this.tagManager) {
      throw new Error("GTM service is not active");
    }
    if (!this.eventsHandlers.has(name)) {
      throw new Error(`GTM Event "${name}" isn't observed`);
    }
  }

  public verifyEventAccess(name: T) {
    if (this.verifyEventConsentGranted && !this.verifyEventConsentGranted(name)) {
      return false;
    }
    return true;
  }

  private eventSegmentBuilder(name: T) {
    return this.eventsHandlers.get(name);
  }

  public event(name: T, data: EventsDataType[T]) {
    try {
      this.verifyEvent(name);
      const isEventCanBeSent = this.verifyEventAccess(name);
      if (!isEventCanBeSent) {
        if (isDevelopment) {
          console.log(`GTM Event "${name}" consent isn't granted`);
        }
      }

      const eventSegment = this.eventSegmentBuilder(name);
      const segment = eventSegment?.makeSegment(data);

      this.tagManager?.dataLayer?.({
        dataLayer: { event: name, ...segment },
      });
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(`[GTM service | event method]: error: ${err.message}`);
      }
      throw new Error("[GTM service | event method]: error: unknown");
    }
  }
}

export const gtmService = new GTMClientService(gtmConfig);
