// Definitions
import type { FilterParamsType } from "models/Filter";
import type { TableParamsAnt } from "components/ui/TableAnt/TableAnt.types";
import type { CartProductAlternativeListType } from "client/core/cart/models";

// Domains
import { initialCartState, cartKeys } from "client/core/cart/store";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { cartService } from "client/core/cart/service";
import { convertSortParametersToSortingRules } from "client/core/cart/utils/common";

type UseCartProductProductsAlternativesFetchType = {
  loadingProductsAlternativesFetch: boolean;
  productsAlternatives: CartProductAlternativeListType;
  params: {
    sortBy: TableParamsAnt["sortBy"];
  };
};

type CartPreviewProductAlternativesParams = FilterParamsType & {
  vehicleId?: number;
  articleId: number;
};
type CartPreviewProductAlternativesQueryKeyType = [string, CartPreviewProductAlternativesParams];

type ArgsType = {
  params: FilterParamsType & {
    articleId?: number | null;
    vehicleId?: number | null;
  };
};

export const useCartPreviewProductAlternativesFetch = (
  args: ArgsType,
): UseCartProductProductsAlternativesFetchType => {
  const { sort, ...otherParams } = args.params;

  const params = {
    sort,
    articleId: Number(otherParams?.articleId),
    ...(otherParams?.vehicleId && { vehicleId: otherParams.vehicleId }),
    ...(otherParams?.page && { page: otherParams.page }),
    ...(otherParams?.perPage && { perPage: otherParams.perPage }),
  };

  const fetchProductsAlternativesProps = useQueryAsync<
    CartProductAlternativeListType,
    CartProductAlternativeListType,
    CartPreviewProductAlternativesQueryKeyType,
    CartPreviewProductAlternativesParams
  >({
    key: [cartKeys.productsAlternatives, params],
    name: "fetchCartProductAlternatives",
    fetcher: cartService.fetchProductsAlternatives,
    fetcherPayload: { params },
    options: {
      enabled: !!params?.articleId,
    },
  });

  const sortBy = convertSortParametersToSortingRules(sort);

  return {
    loadingProductsAlternativesFetch:
      fetchProductsAlternativesProps.isLoading || fetchProductsAlternativesProps.isRefetching,
    productsAlternatives:
      fetchProductsAlternativesProps.data || initialCartState.productsAlternatives,
    params: { sortBy },
  };
};
