// Core
import { z } from "zod";

// TODO: Rework filters types
enum SortEnum {
  asc = "asc",
  desc = "desc",
}

const paginationSchema = z.object({
  page: z.number().nullable().optional(),
  total: z.number().nullable().optional(),
  perPage: z.number().nullable().optional(),
  from: z.number().nullable().optional(),
  to: z.number().nullable().optional(),
  lastPage: z.number().nullable().optional(),
  path: z.string().optional(),
});

type PaginationType = z.infer<typeof paginationSchema>;

const radioObjSchema = z.object({
  id: z.number(),
  label: z.string(),
  value: z.string(),
  count: z.number().optional(),
  isSelected: z.boolean().optional(),
});

const criteriaItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
  isSelected: z.boolean().optional(),
});

const filterSchema = z.object({
  priceRange: z.array(z.number()).optional(),
  brand: z.array(z.string()).optional(),
  generic: z.array(z.string()).optional(),
  delivery: z.string().optional(),
  priceFrom: z.number().nullable().optional(),
  priceTo: z.number().nullable().optional(),
  vehicleId: z.number().optional(),
  nodeId: z.number().optional(),
  installation: radioObjSchema
    .nullable()
    .or(z.array(radioObjSchema.nullable()))
    .nullable()
    .optional(),
  criteria: z
    .array(
      z
        .array(
          criteriaItemSchema
            .or(z.array(criteriaItemSchema.nullable().optional()))
            .nullable()
            .optional(),
        )
        .or(
          z.record(
            z.string(),
            criteriaItemSchema
              .or(z.array(criteriaItemSchema.nullable().optional()))
              .nullable()
              .optional(),
          ),
        )
        .nullable()
        .optional(),
    )
    .default([])
    .nullable()
    .optional(),
  fittingScore: z.string().optional(),
});

type FilterType = z.infer<typeof filterSchema>;

type FilterRequestInstallationType = { installation?: string };

type FilterRequestFittingScoreType = { fittingScore?: string };

type FilterRequestValuesType =
  | FilterType
  | FilterRequestInstallationType
  | FilterRequestFittingScoreType;

type SortType = {
  [key: string]: SortEnum.asc | SortEnum.desc;
};

export type FilterParamsType = {
  filter?: FilterRequestValuesType | null;
  page?: number | string;
  perPage?: number | string;
  sort?: SortType;
};

export const getFinalTableQuery = (
  params: FilterParamsType,
  pagination: PaginationType | null = null,
): FilterParamsType => {
  const TABLE_DEFAULT_PAGE = 1;

  const perPage = params.perPage || pagination?.perPage;
  const page = params.page || pagination?.page;
  const sort = params?.sort && Object.keys(params?.sort).length ? params.sort : null;
  const isPerPageChanged = params.perPage && params.perPage !== pagination?.perPage;
  const lastPage = pagination?.total && perPage && Math.ceil(pagination.total / Number(perPage));
  const availablePage = page && lastPage && isPerPageChanged ? TABLE_DEFAULT_PAGE : page;

  return {
    // do not remove keys with void, it's save order of query params
    page: void 0,
    perPage: void 0,
    sort: void 0,
    ...(sort && { sort }),
    ...(perPage && { perPage: Number(perPage) }),
    ...(page && { page: Number(availablePage) }),
  };
};
