// Core
import { z } from "zod";

// Utils
import { getFieldLabel } from "utils/forms";

export const SEARCH_BAR_FORM_KEYS = {
  type: "type",
  entity: "entity",
  search: "search",
  keyword: "keyword",
} as const;
export type SearchBarFormKeysType = typeof SEARCH_BAR_FORM_KEYS;

const regExps = {
  [SEARCH_BAR_FORM_KEYS.search]: new RegExp("^(?=.*\\p{L})|(?=.*[0-9])", "u"),
};

export const inputConfig = {
  [SEARCH_BAR_FORM_KEYS.search]: {
    placeholder: getFieldLabel("searchBar", true),
    pattern: regExps[SEARCH_BAR_FORM_KEYS.search],
  },
};

const initialChangeEmailFormShape = {
  type: null,
  entity: "",
  search: "",
  keyword: "",
};

export const searchBarForm = {
  shape: initialChangeEmailFormShape,
  schema: () =>
    z.object({
      [SEARCH_BAR_FORM_KEYS.type]: z.number().nullable(),
      [SEARCH_BAR_FORM_KEYS.entity]: z.string(),
      [SEARCH_BAR_FORM_KEYS.search]: z
        .string()
        .trim()
        .regex(inputConfig[SEARCH_BAR_FORM_KEYS.search].pattern),
      [SEARCH_BAR_FORM_KEYS.keyword]: z.string(),
    }),
};
