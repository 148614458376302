// Components
import { Control, Controller } from "react-hook-form";
import { InputTimePicker } from "components/ui/FormFields/InputTimePicker";

// Definitions
import type { InputTimePickerType } from "components/ui/FormFields/InputTimePicker";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormTimeRangePickerType = InputTimePickerType & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormTimePicker = (props: WrappedFormTimeRangePickerType) => {
  const { name, control, onChange, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputTimePicker {...rest} {...field} ref={field.ref} onChange={onChange} />
      )}
    />
  );
};
