// Components
import { Control, Controller } from "react-hook-form";
import { InputCheckbox } from "components/ui/FormFields/InputCheckbox";

// Definitions
import type { InputCheckboxType } from "components/ui/FormFields/InputCheckbox";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormCheckboxType = InputCheckboxType & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormCheckbox = (props: WrappedFormCheckboxType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <InputCheckbox {...rest} {...field} ref={field.ref} />;
      }}
    />
  );
};
