// Components
import { Control, Controller } from "react-hook-form";
import { InputSelect } from "components/ui/FormFields/InputSelect";

// Definitions
import type { InputSelectProps } from "components/ui/FormFields/InputSelect";
import type { ValidateStatus } from "antd/lib/form/FormItem";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormSelectType = InputSelectProps & {
  name: string;
  placeholder?: string;
  control?: Control<any>;
  validateStatus?: ValidateStatus;
};

export const WrappedFormSelect = (props: WrappedFormSelectType) => {
  const { name, control, validateStatus, ...rest } = props;

  const isValid = validateStatus !== "error";

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputSelect hasValid={isValid} {...rest} {...field} />}
    />
  );
};
