import { z } from "zod";

export const PRICE_VAT_VIEW_KEYS = {
  empty: "",
  notVat: "notVat",
  withVat: "withVat",
} as const;

export const priceVatViewSchema = z.enum([
  PRICE_VAT_VIEW_KEYS.empty,
  PRICE_VAT_VIEW_KEYS.notVat,
  PRICE_VAT_VIEW_KEYS.withVat,
]);

export type PriceVatViewType = z.infer<typeof priceVatViewSchema>;
