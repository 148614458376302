// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  ParametersEnum,
  EventConsentOptionPayloadType,
  GTMTypesEnum,
  ConsentEnum,
  EventsDataUnionType,
  EventConsentOptionDataType,
} from "client/utils/gtm/gtm.types";

class ConsentOptionEvent extends AbstractGTMEventSegment<GTMTypesEnum.consent_mode_option> {
  protected readonly segmentShape = {
    [ParametersEnum.ad_storage]: ConsentEnum.denied,
    [ParametersEnum.analytics_storage]: ConsentEnum.denied,
  };

  makeSegment(data: EventsDataUnionType): EventConsentOptionPayloadType {
    const consentData = data as EventConsentOptionDataType;
    const segment = this.getSegment();

    return {
      ...segment,
      ...(consentData[ParametersEnum.statistics] && {
        [ParametersEnum.analytics_storage]: ConsentEnum.granted,
      }),
    };
  }
}

export const consentOptionEvent: ConsentOptionEvent = new ConsentOptionEvent();
