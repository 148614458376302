// Components
import { Control, Controller } from "react-hook-form";
import { InputRadioGroup } from "components/ui/FormFields/InputRadioGroup";

// Definitions
import type { InputRadioGroupType } from "components/ui/FormFields/InputRadioGroup";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormRadioGroupType = InputRadioGroupType & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormRadioGroup = (props: WrappedFormRadioGroupType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputRadioGroup {...rest} {...field} ref={field.ref} />}
    />
  );
};
