// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadDashboardBannerType,
  EventDashboardBannerDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ANALYTICS_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class DashboardBannerEvent extends AbstractGTMEventSegment<GTMTypesEnum.dashboard_banner> {
  protected readonly segmentShape: EventPayloadDashboardBannerType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: "",
      [ParametersEnum.banner_position]: 0,
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadDashboardBannerType {
    const { bannerPosition, bannerName } = data as EventDashboardBannerDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] =
      `${bannerName}_${ANALYTICS_ACTION_TYPE.view} and ${bannerName}_${ANALYTICS_ACTION_TYPE.click}`;
    segment[PayloadKeysEnum.parameters][ParametersEnum.banner_position] = bannerPosition + 1;

    return segment;
  }
}

export const dashboardBannerEvent: DashboardBannerEvent = new DashboardBannerEvent();
