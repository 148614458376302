// Core
import { useCallback } from "react";

// Definitions
import { HttpStatusCode } from "models/Http";
import type { CartProductUpdateType } from "client/core/cart/models";

// Domains
import { useCartPreviewProductUpdateMutation } from "client/core/cart/hooks/mutations/use-cart-preview-product-update-mutation";

// Utils
import { notificationService } from "utils/notifications";
import {
  getHttpErrorMessageCartProductUpdateWithTranslations,
  getHttpErrorMessageNotFoundProductWithTranslations,
  getHttpErrorMessageWithTranslations,
} from "client/core/cart/utils/notification-messages";

type UseCartPreviewProductUpdate = {
  loadingCartPreviewProductUpdate: boolean;
  onUpdateCartPreviewProduct: (values: CartProductUpdateType) => Promise<void>;
};

type UseCallbackUpdateCartPreviewProduct = (data: CartProductUpdateType) => Promise<void>;

export const useCartPreviewProductUpdate = (): UseCartPreviewProductUpdate => {
  const { loadingCartPreviewProductUpdateMutation, onUpdateCartPreviewProductUpdateMutation } =
    useCartPreviewProductUpdateMutation();

  const onUpdateCartPreviewProduct = useCallback<UseCallbackUpdateCartPreviewProduct>(
    async (resultData) => {
      try {
        const [error] = await onUpdateCartPreviewProductUpdateMutation(resultData);

        const hasErrorInUpdateProduct = error && "hasError" in error;
        if (hasErrorInUpdateProduct) {
          const isError404 = error.status === HttpStatusCode.notFound;
          const isError422 = error.status === HttpStatusCode.unprocessableEntity;
          const isError500 = error.status === HttpStatusCode.server;

          if (isError422 || isError500) {
            return notificationService.showError(
              getHttpErrorMessageCartProductUpdateWithTranslations(),
            );
          }

          if (isError404) {
            return notificationService.showError(
              getHttpErrorMessageNotFoundProductWithTranslations(),
            );
          }

          throw new Error("Something went wrong in cart preview product update flow");
        }
      } catch (error) {
        console.warn(error);
        notificationService.showError(getHttpErrorMessageWithTranslations());
      }
    },
    [],
  );

  return {
    loadingCartPreviewProductUpdate: loadingCartPreviewProductUpdateMutation,
    onUpdateCartPreviewProduct,
  };
};
