// Core
import cx from "classnames";

// Utils
import st from "./Styles.module.less";

export const WrapperNodeDefault = (
  value: number | string | null = "",
  disabled?: boolean,
  testId = "wrapper-node-default",
) => {
  const wrapperStyles = cx(st["wrapper-node"], {
    [st.disabled]: disabled,
  });
  const renderValue = value || "-";
  return (
    <span className={wrapperStyles} data-test-id={testId}>
      {renderValue}
    </span>
  );
};
