// Core
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { CartTotalPrice } from "client/core/cart/components/cart-total-price";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import st from "./styles.module.less";

type CartPreviewTotalPrice = {
  value: number | null;
  country: string;
} | null;
type CartPreviewTotalProps = {
  loadingFetch?: boolean;
  loadingUpdate?: boolean;
  hasMandateUnconfirmed?: boolean;
  totalPrice?: CartPreviewTotalPrice;
  totalPriceVat?: CartPreviewTotalPrice;
  vatPrice?: CartPreviewTotalPrice;
  vatPercentage?: number | null;
  onCreate?: () => void;
};

export const CartPreviewTotal = (props: CartPreviewTotalProps) => {
  const {
    loadingFetch,
    loadingUpdate,
    totalPrice,
    totalPriceVat,
    vatPrice,
    vatPercentage,
    hasMandateUnconfirmed,
    onCreate,
  } = props;
  const { t } = useTranslation();

  return (
    <article className={st["cart-preview-total"]} data-test-id="cart-preview-total">
      <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
        <Col span={24}>
          <div className={st["cart-preview-total-price"]}>
            <CartTotalPrice
              totalPrice={totalPrice}
              totalPriceVat={totalPriceVat}
              vatPrice={vatPrice}
              vatPercentage={vatPercentage}
              loadingFetch={loadingFetch}
              loadingUpdate={loadingUpdate}
              buttonText={t("cart:total.previewBtnText")}
              onCreate={onCreate}
              hasMandateUnconfirmed={hasMandateUnconfirmed}
            />
          </div>
        </Col>
      </Row>
    </article>
  );
};
