// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadNotificationPageType,
  EventNotificationPageDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import { ParametersEnum, PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class NotificationPageEvent extends AbstractGTMEventSegment<GTMTypesEnum.notification_page> {
  protected readonly segmentShape: EventPayloadNotificationPageType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadNotificationPageType {
    const { totalNotifications, totalUnreadNotifications } = data as EventNotificationPageDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] =
      `${totalNotifications}%${totalUnreadNotifications}`;

    return segment;
  }
}

export const notificationPageEvent: NotificationPageEvent = new NotificationPageEvent();
