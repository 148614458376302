// Core
import { useCallback, ReactElement, PropsWithChildren } from "react";

// Definitions
import type { FilterParamsType } from "models/Filter";

// Hooks
import { useSessionStorage } from "hooks/useSessionStorage";

// Utils
import { cartPreviewProductAlternativesCache } from "client/core/cart/contexts/cart-preview-product-alternatives";

const PRODUCT_ALTERNATIVES_DRAWER = "atd-pro-cart-product-alternatives-drawer";
type CallbackParamsType = FilterParamsType & {
  articleId?: number | null;
  vehicleId?: number | null;
  productId?: number | null;
};
type StoreType = FilterParamsType & {
  articleId?: number | null;
  vehicleId?: number | null;
  productId?: number | null;
  open: boolean;
};

type CartPreviewProductAlternativesProviderType = PropsWithChildren<{ children: ReactElement }>;

export const CartPreviewProductAlternativesProvider = (
  props: CartPreviewProductAlternativesProviderType,
) => {
  const { storedValue, setValue, getValue, clearValue } = useSessionStorage<StoreType>(
    PRODUCT_ALTERNATIVES_DRAWER,
    {
      open: false,
    },
  );

  const handleClose = () => {
    setValue({ open: false });
    clearValue();
  };

  const handleOpen = useCallback(() => {
    setValue({ open: true });
  }, []);

  const handleChange = useCallback<(v: CallbackParamsType) => void>((values) => {
    const current = getValue(PRODUCT_ALTERNATIVES_DRAWER);
    if (!current) {
      return;
    }
    setValue({ ...current, ...values });
  }, []);

  const { open, ...otherParams } = storedValue;

  return (
    <cartPreviewProductAlternativesCache.Provider
      value={{
        visibleProductAlternatives: open,
        productAlternativesParams: otherParams,
        onClose: handleClose,
        onOpen: handleOpen,
        onChange: handleChange,
      }}
    >
      {props.children}
    </cartPreviewProductAlternativesCache.Provider>
  );
};
