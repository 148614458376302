// Definitions
import type { PersonalManagerAdviserMetadataType } from "client/core/personal-manager/models";

// Domains
import { personalManagerKeys } from "client/core/personal-manager/store";

// Domains

import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { personalManagerService } from "client/core/personal-manager/service";

export type UsePersonalManagerAdviserMetadataFetchType = {
  loadingPersonalManagerAdviserMetadataFetch: boolean;
  personalManagerAdviserMetadata: PersonalManagerAdviserMetadataType | null;
};

export const usePersonalManagerAdviserMetadataFetch =
  (): UsePersonalManagerAdviserMetadataFetchType => {
    const fetchProps = useQueryAsync<
      PersonalManagerAdviserMetadataType,
      PersonalManagerAdviserMetadataType,
      [string] | string[]
    >({
      key: [personalManagerKeys.personalManagerMetadata],
      name: "fetchPersonalManagerMetadataQuery",
      fetcher: personalManagerService.fetchMetadata,
    });

    return {
      loadingPersonalManagerAdviserMetadataFetch: fetchProps.isLoading,
      personalManagerAdviserMetadata: fetchProps?.data || null,
    };
  };
