// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ShowPriceEventActionEnum,
  EventPayloadShowPriceType,
  EventShowPriceDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class ShowPriceEvent extends AbstractGTMEventSegment<GTMTypesEnum.show_price> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: ShowPriceEventActionEnum.show_price,
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadShowPriceType {
    const showPriceData = data as EventShowPriceDataType;
    const segment = this.getSegment();

    return {
      ...segment,
      [PayloadKeysEnum.parameters]: {
        ...segment[PayloadKeysEnum.parameters],
        [ParametersEnum.event_action]: showPriceData.event_action,
      },
    };
  }
}

export const showPriceEvent: ShowPriceEvent = new ShowPriceEvent();
