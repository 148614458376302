// Core
import React from "react";
import dynamic from "next/dynamic";

// Components
import { Loading } from "components/ui/Loading";

export const DynamicImport = <P extends Record<string, unknown>>(
  promiseImport: Promise<Record<string, React.ComponentType<P>>>,
  moduleName: string,
): React.ComponentType<P> => {
  return dynamic<P>(
    () =>
      promiseImport
        .then((mod) => mod[moduleName])
        .catch((err) => {
          throw err;
        }),
    {
      ssr: false,
      loading: () => <Loading hasSpinning />,
    },
  );
};
