export type CurrencyConfigType = {
  symbol: string;
  currencyIso: string;
  decimal: string;
  separator: string;
  precision: number;
  pattern: string;
  negativePattern: string;
};

const CURRENCY_COUNTRY_CODE = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

export const currencyConfig: Record<string, CurrencyConfigType> = {
  [CURRENCY_COUNTRY_CODE.EN]: {
    symbol: "£",
    currencyIso: "GBP",
    decimal: ",",
    separator: "",
    precision: 2,
    pattern: "!#",
    negativePattern: "-!#",
  },
  [CURRENCY_COUNTRY_CODE.FR]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: " ",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
  [CURRENCY_COUNTRY_CODE.DE]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: ".",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
  [CURRENCY_COUNTRY_CODE.AT]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: ".",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
  [CURRENCY_COUNTRY_CODE.NL]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: ".",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
};
