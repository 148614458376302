// Definitions
import type { BadgeProps as AntBadgeProps } from "antd";

// Components
import { Badge as AntBadge } from "antd";

// Utils
import st from "./Styles.module.less";

export type BadgeProps = AntBadgeProps & {
  loading?: boolean;
  offset?: AntBadgeProps["offset"];
};

const BADGE_CONFIG_BASE = {
  offset: [0, 0],
} as { offset: BadgeProps["offset"] };

export const Badge = (props: BadgeProps) => {
  const { count, children, offset = BADGE_CONFIG_BASE.offset, title = "" } = props;

  return (
    <AntBadge title={title} count={count} offset={offset} className={st.badge}>
      {children}
    </AntBadge>
  );
};
