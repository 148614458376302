// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadPopupType,
  EventPopupDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ANALYTICS_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class PopupEvent extends AbstractGTMEventSegment<GTMTypesEnum.popup> {
  protected readonly segmentShape: EventPayloadPopupType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadPopupType {
    const { popupName } = data as EventPopupDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] =
      `${popupName}_${ANALYTICS_ACTION_TYPE.view} and ${popupName}_${ANALYTICS_ACTION_TYPE.click}`;

    return this.getSegment();
  }
}

export const popupEvent: PopupEvent = new PopupEvent();
