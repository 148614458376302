// Core
import { forwardRef } from "react";

// Components
import { InputBase } from "components/ui/FormFields/InputBase";
import { SearchBarVehicle } from "client/core/search-bar/components/search-bar-vehicle";

// Definitions
import type { InputRefBaseType } from "models/Base";
import type { InputBaseType } from "components/ui/FormFields/InputBase";

// Utils
import st from "./styles.module.less";

export const SearchBarInput = forwardRef<InputRefBaseType, InputBaseType>((props, ref) => {
  const {
    type = "text",
    maxLength = 100,
    placeholder = "Search...",
    onChange,
    value = "",
    onBlur,
    ...rest
  } = props;

  return (
    <div className={st["search-bar-input"]}>
      <SearchBarVehicle />
      <InputBase
        {...rest}
        className={st.input}
        type={type}
        size={"large"}
        ref={ref}
        autoComplete={"off"}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
});

SearchBarInput.displayName = "SearchBarInput";
