// Core
import { useEffect, useState } from "react";

// Definitions
import type { CartPreviewType } from "client/core/cart/models";
import { ITEM_LIST_ID_TYPE, ROUTE_NAME_KEYS } from "client/utils/gtm/gtm.types";

// Utils
import { triggerViewCartGtmEvent } from "client/core/cart/utils/cart-gtm-events";

type UseCartPreviewTrackProps = {
  loading: boolean;
  cartPreview: CartPreviewType;
};

export const useCartPreviewTrack = ({ cartPreview, loading }: UseCartPreviewTrackProps) => {
  const [viewCartEventSent, setViewCartEventSent] = useState(false);

  useEffect(() => {
    if (!viewCartEventSent && cartPreview && !loading) {
      setViewCartEventSent(true);
      triggerViewCartGtmEvent({
        cartPreview,
        itemListId: ITEM_LIST_ID_TYPE["cart-items"],
        route: ROUTE_NAME_KEYS.cart,
      });
    }
  }, [viewCartEventSent, cartPreview, loading]);
};
