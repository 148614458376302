// Definitions
import type { PersonalManagerType } from "client/core/personal-manager/models";

// Domains
import { personalManagerKeys } from "client/core/personal-manager/store";

// Utils
import { personalManagerService } from "client/core/personal-manager/service";
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";

type UseProfilePersonalManagerAdviserFetchType = {
  loadingPersonalManagerAdviserFetch: boolean;
  personalManagerAdviser: PersonalManagerType | null;
};

export const usePersonalManagerAdviserFetch = (): UseProfilePersonalManagerAdviserFetchType => {
  const fetchPersonalManagerProps = useQueryAsync<
    PersonalManagerType,
    PersonalManagerType,
    [string] | string[]
  >({
    key: [personalManagerKeys.personalManager],
    name: "fetchProfilePersonalManagerQuery",
    fetcher: personalManagerService.fetchProfilePersonalManager,
  });

  return {
    loadingPersonalManagerAdviserFetch: fetchPersonalManagerProps.isLoading,
    personalManagerAdviser: fetchPersonalManagerProps.data || null,
  };
};
