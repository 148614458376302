// Components
import { CartCellActions } from "client/core/cart/components/cart-cell-actions";
import { CartCellPromoLabel } from "client/core/cart/components/cart-cell-promo-label";
import { CartCellPriceWithOldPrice } from "client/core/cart/components/cart-cell-price-with-old-price";

// Definitions
import type { TFunction } from "next-i18next";
import type { CartTabProductType } from "client/core/cart/models";
import type { ColumnsTypeCustom } from "components/ui/TableAnt/TableAnt.types";
import type { PriceVatViewType } from "client/core/settings-price-vat/models";
import { PRICE_VAT_VIEW_KEYS } from "client/core/settings-price-vat/models";

// Utils
import { getHeader } from "client/core/cart/utils/common";
import { checkIsVatCountry, getVatSignByCountry } from "client/core/cart/utils/countries";
import { CELL_CUSTOM_FIELD_TYPE } from "client/core/cart/components/cart-cell-custom";

const EMPTY_STR = " ";
const CART_PRODUCT_COLUMN = {
  articleNo: "articleNo",
  quantity: "quantity",
  images: "images",
  name: "name",
  deliveryAt: "deliveryAt",
  priceRecommended: "priceRecommended",
  priceNonVat: "priceNonVat",
  priceVat: "priceVat",
  margin: "margin",
  priceTotalVat: "priceTotalVat",
  priceTotalNonVat: "priceTotalNonVat",
  brand: "brand",
  pledge: "pledge",
  promotions: "promotions",
} as const;

type GetCartPreviewProductsColumnsProps = {
  t: TFunction;
  country?: string;
  onRemoveProduct?: (product: CartTabProductType) => void;
  onFetchAlternatives?: ({ id, articleId }: { id: number; articleId: number }) => void;
  canSwitchVat?: boolean;
  viewPriceVat?: PriceVatViewType;
};

export const getCartPreviewProductsColumns = (
  columnProps: GetCartPreviewProductsColumnsProps,
): ColumnsTypeCustom<CartTabProductType> => {
  const { t, country, onRemoveProduct, onFetchAlternatives, canSwitchVat, viewPriceVat } =
    columnProps;

  const CellPromoLabel = (value: CartTabProductType["promotions"]) => {
    return CartCellPromoLabel({ item: value?.prime });
  };

  const CellActionAdd = (_: unknown, record: CartTabProductType) => {
    return CartCellActions({
      hasActReplaceActive: !record.isAvailable,
      hasActReplaceText: t("cart:decor:product:notAvailable"),
      disabled: !record.isAvailable,
      product: record,
      onRemove: onRemoveProduct,
      onUpdate: onFetchAlternatives,
    });
  };

  const CellPriceVat = (_: CartTabProductType["priceVat"], record: CartTabProductType) => {
    return CartCellPriceWithOldPrice({
      country: record?.priceVat?.country,
      value: record?.priceVat?.value,
      oldValue: record?.oldVatPrice?.value,
      isEmpty: true,
      disabled: !record?.isAvailable,
      testId: "cell-price-vat",
    });
  };

  const CellPriceNonVat = (_: CartTabProductType["priceNonVat"], record: CartTabProductType) => {
    return CartCellPriceWithOldPrice({
      country: record?.priceNonVat?.country,
      value: record?.priceNonVat?.value,
      oldValue: record?.oldPriceNonVat?.value,
      isEmpty: true,
      disabled: !record?.isAvailable,
      testId: "cell-price-non-vat",
    });
  };

  const CellTotalPriceVat = (
    _: CartTabProductType["priceTotalVat"],
    record: CartTabProductType,
  ) => {
    return CartCellPriceWithOldPrice({
      country: record?.priceTotalVat?.country,
      value: record?.priceTotalVat?.value,
      oldValue: record?.oldTotalPriceVat?.value,
      isEmpty: true,
      disabled: !record?.isAvailable,
      testId: "cell-price-total-vat",
    });
  };

  const CellTotalPriceNonVat = (
    _: CartTabProductType["priceTotalNonVat"],
    record: CartTabProductType,
  ) => {
    return CartCellPriceWithOldPrice({
      country: record?.priceTotalNonVat?.country,
      value: record?.priceTotalNonVat?.value,
      oldValue: record?.oldTotalPriceNonVat?.value,
      isEmpty: true,
      disabled: !record?.isAvailable,
      testId: "cell-price-total-non-vat",
    });
  };

  const maskT = "cart:table";
  const isVatCountry = checkIsVatCountry(country?.toLocaleUpperCase());
  const vatPostfix = t(getVatSignByCountry(country?.toLocaleUpperCase()));

  const priceVatCol = {
    title: getHeader(maskT, "priceVat", t),
    key: CART_PRODUCT_COLUMN.priceVat,
    render: CellPriceVat,
    width: 94,
  };

  const priceNonVatCol = {
    title: getHeader(maskT, "priceNonVat", t),
    key: CART_PRODUCT_COLUMN.priceNonVat,
    render: CellPriceNonVat,
    width: 94,
  };

  const priceTotalVatCol = {
    title: getHeader(maskT, "priceTotalVat", t),
    key: CART_PRODUCT_COLUMN.priceTotalVat,
    render: CellTotalPriceVat,
    width: 94,
  };

  const priceTotalNonVatCol = {
    title: getHeader(maskT, "priceTotalNonVat", t),
    key: CART_PRODUCT_COLUMN.priceTotalNonVat,
    render: CellTotalPriceNonVat,
    width: 94,
  };

  const priceColumnsModelMap = {
    [PRICE_VAT_VIEW_KEYS.notVat]: priceNonVatCol,
    [PRICE_VAT_VIEW_KEYS.withVat]: priceVatCol,
  };

  const priceColByCountry = isVatCountry ? priceVatCol : priceNonVatCol;
  const priceCol =
    canSwitchVat && viewPriceVat ? priceColumnsModelMap[viewPriceVat] : priceColByCountry;

  const totalPriceColumnsModelMap = {
    [PRICE_VAT_VIEW_KEYS.notVat]: priceTotalNonVatCol,
    [PRICE_VAT_VIEW_KEYS.withVat]: priceTotalVatCol,
  };

  const priceTotalColByCountry = isVatCountry ? priceTotalVatCol : priceTotalNonVatCol;
  const priceTotalCol =
    canSwitchVat && viewPriceVat ? totalPriceColumnsModelMap[viewPriceVat] : priceTotalColByCountry;

  return [
    {
      title: EMPTY_STR,
      key: CART_PRODUCT_COLUMN.promotions,
      dataIndex: CART_PRODUCT_COLUMN.promotions,
      render: CellPromoLabel,
      width: 40,
    },
    {
      title: EMPTY_STR,
      key: CART_PRODUCT_COLUMN.images,
      inputType: CELL_CUSTOM_FIELD_TYPE["image-thumb"],
      width: 72,
    },
    {
      title: getHeader(maskT, "article", t),
      key: CART_PRODUCT_COLUMN.articleNo,
      inputType: CELL_CUSTOM_FIELD_TYPE.cartArticleNo,
      width: 120,
    },
    {
      title: getHeader(maskT, "brand", t),
      key: CART_PRODUCT_COLUMN.name,
      inputType: CELL_CUSTOM_FIELD_TYPE.brandAndName,
      width: 310,
    },
    {
      title: getHeader(maskT, "quantity", t),
      key: CART_PRODUCT_COLUMN.quantity,
      inputType: CELL_CUSTOM_FIELD_TYPE.counter,
      width: 126,
    },
    priceCol,
    {
      title: getHeader(maskT, "deliveryAt", t),
      key: CART_PRODUCT_COLUMN.deliveryAt,
      inputType: CELL_CUSTOM_FIELD_TYPE.deliveryAt,
      width: 104,
    },
    {
      title: getHeader(maskT, "priceRecommended", t, { vatPostfix }),
      key: CART_PRODUCT_COLUMN.priceRecommended,
      inputType: CELL_CUSTOM_FIELD_TYPE.priceRecommended,
      width: 104,
    },
    {
      title: getHeader(maskT, "surcharge", t),
      key: CART_PRODUCT_COLUMN.margin,
      inputType: CELL_CUSTOM_FIELD_TYPE.discount,
      width: 104,
    },
    {
      title: getHeader(maskT, "pledge", t),
      key: CART_PRODUCT_COLUMN.pledge,
      inputType: CELL_CUSTOM_FIELD_TYPE.pledge,
      width: 120,
    },
    priceTotalCol,
    {
      title: EMPTY_STR,
      key: "action",
      render: CellActionAdd,
      width: 78,
    },
  ];
};
