// Components
import { Control, Controller } from "react-hook-form";
import { InputCounter } from "components/ui/FormFields/InputCounter";

// Definitions
import type { InputCounterType } from "components/ui/FormFields/InputCounter";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormInputCounterType = InputCounterType & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormInputCounter = (props: WrappedFormInputCounterType) => {
  const { name, control, onChange, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputCounter {...rest} {...field} onChange={onChange} />}
    />
  );
};
