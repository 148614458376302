// Core
import { PropsWithChildren } from "react";

// Utils
import { cartPreviewVehiclesCache } from "client/core/cart/contexts/cart-preview-vehicles";

// Utils
import { useToggle } from "client/core/cart/hooks/use-toggle";

export const CartPreviewVehiclesStoreProvider = (
  props: PropsWithChildren<{ isEdit?: boolean }>,
) => {
  const { children, isEdit = false } = props;
  const { isToggle: edit, onToggle: setEdit } = useToggle(isEdit);
  const { isToggle: select, onToggle: setSelect } = useToggle(false);

  return (
    <cartPreviewVehiclesCache.Provider value={{ edit, setEdit, select, setSelect }}>
      {children}
    </cartPreviewVehiclesCache.Provider>
  );
};
