// Core
import { PropsWithChildren, ReactElement } from "react";

// Utils
import { cartCache } from "client/core/cart/contexts/cart";

// Utils
import { useToggle } from "client/core/cart/hooks/use-toggle";

type CartStoreProviderPropsType = PropsWithChildren<{ children: ReactElement }>;

export const CartStoreProvider = (props: CartStoreProviderPropsType) => {
  const { isToggle: availableCart, onToggle: onToggleAvailableCart } = useToggle(false);

  return (
    <cartCache.Provider value={{ availableCart, onToggleAvailableCart }}>
      {props.children}
    </cartCache.Provider>
  );
};

export const CartStoreConsumer = cartCache.Consumer;
