// Core
import { z } from "zod";

export const PERMISSION_ACT = {
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
} as const;

const LOYALTY_STATUSES = {
  standard: "standard",
  silver: "silver",
  gold: "gold",
  platinum: "platinum",
} as const;

const COUNTRY_CODES = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

const LANGUAGE_CODES = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  NL: "NL",
} as const;

const permissionFeatAliasSchema = z.enum([
  "banner_top",
  "banner_top_go_core",
  "service_agent_holidays_banner_top",
  "banner_top_olympic_games",
  "christmas_banner",

  "dashboard_nps_banner",
  "dashboard_go_core_banner",
  "dashboard_vehicle_selector_catalog_classic",
  "dashboard_vehicle_selector_catalog_tabs",

  "nps_survey_modal",

  "navbar_catalog_tabs",
  "navbar_catalog_classic",

  "search_bar_tabs",
  "search_bar_classic",

  "selector_reg_num",
  "selector_kba",
  "switcher_vat_price",
  "documents",

  "graphic_catalog_redirect",

  "cart_repair_estimate",
  "cart_total_vat_price",
  "cart_vat_price",
  "cart_actions_vat_price",
  "cart_continue_shopping_catalog_classic",
  "cart_continue_shopping_catalog_tabs",

  "saved_basket_repair_estimate",
  "saved_basket_total_vat_price",
  "saved_basket_vat_price",

  "order_total_vat_price",
  "orders_actions_replay_payment",
  "order_vat_price",

  "products_actions_vat_price",
  "product_details_vat_price",
  "product_details_mondu_deactivated",
  "product_details_mondu_activated",

  "repair_estimates",
  "repair_estimate_vat_price",
  "repair_estimate_total_vat_price",
  "repair_estimate_actions_vat_price",

  "client_vehicle_by_attr",
  "client_vehicle_by_reg_number",
  "client_vehicle_by_kba",

  "returns_claims_tracking",
  "returns_claims_label",
  "returns_products",
  "returns",
  "return_dont_have_vin_button",

  "customers",

  "settings_mondu",
]);

export type PermissionFeatAliasType = z.infer<typeof permissionFeatAliasSchema>;

const LanguageEnum = z.enum([
  LANGUAGE_CODES.FR,
  LANGUAGE_CODES.DE,
  LANGUAGE_CODES.EN,
  LANGUAGE_CODES.NL,
]);

const permissionFeatRuleScheme = z.object({
  [PERMISSION_ACT.READ]: z.enum([PERMISSION_ACT.READ]).optional(),
  [PERMISSION_ACT.UPDATE]: z.enum([PERMISSION_ACT.UPDATE]).optional(),
  [PERMISSION_ACT.CREATE]: z.enum([PERMISSION_ACT.CREATE]).optional(),
  [PERMISSION_ACT.DELETE]: z.enum([PERMISSION_ACT.DELETE]).optional(),
});

const permissionFeatScheme = z.record(
  permissionFeatAliasSchema.or(z.string()),
  z.object({
    rules: permissionFeatRuleScheme.nullable(),
    description: z.string(),
  }),
);

export type PermissionFeatType = z.infer<typeof permissionFeatScheme>;

const permissionsScheme = z.object({
  pages: z.array(z.string()),
  feature: permissionFeatScheme,
});

const staticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});

const profileSettingsSchema = z.object({
  viewDisplay: staticDataItemSchema.nullable(),
  showPriceVat: z.boolean(),
});

export type ProfileSettingsType = z.infer<typeof profileSettingsSchema>;

const loyaltyStatusSchema = z
  .enum([
    LOYALTY_STATUSES.standard,
    LOYALTY_STATUSES.silver,
    LOYALTY_STATUSES.gold,
    LOYALTY_STATUSES.platinum,
  ])
  .or(z.literal(""));

const profileSchema = z.object({
  id: z.number().nullable(),
  projectId: z.number().nullable(),
  name: z.string().optional(),
  firstName: z.string(),
  image: z.string().optional(),
  email: z.string().optional(),
  surname: z.string(),
  language: LanguageEnum,
  phonePrefix: staticDataItemSchema.nullable(),
  phoneNumber: z.string(),
  customerId: z.number().nullable(),
  countryId: z.number().nullable(),
  basketProductsCount: z.number().nullable(),
  countryCode: z.enum([
    COUNTRY_CODES.FR,
    COUNTRY_CODES.DE,
    COUNTRY_CODES.EN,
    COUNTRY_CODES.AT,
    COUNTRY_CODES.NL,
  ]),
  loyaltyStatus: loyaltyStatusSchema,
  permission: permissionsScheme,
  settings: profileSettingsSchema.nullable(),
});

export type ProfileType = z.infer<typeof profileSchema>;
