// Core
import { useRouter } from "next/router";
import QS from "qs";

// Utils
import { QS_QUERY_CONFIG } from "utils/constants";
import { book } from "init/book";

type UseNotificationsViewAllType = {
  onViewAllNotifications: () => void;
};

export const useNotificationsViewAll = (): UseNotificationsViewAllType => {
  const router = useRouter();

  const onViewAllNotifications = () => {
    void (async () => {
      const newParams = { activeTab: "1" };

      await router.replace({
        pathname: book.notifications,
        query: QS.stringify(newParams, QS_QUERY_CONFIG),
      });
    })();
  };

  return {
    onViewAllNotifications,
  };
};
