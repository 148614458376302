const BuildEnvVariable = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

const APP_PORT = process.env.PORT;
const NODE_ENV = process.env.NODE_ENV || "development";
const BUILD_ENV = process.env.BUILD_ENV || "development";
const BUILD_LOGGER = process.env.BUILD_LOGGER || "development";
const isDevelopment = BUILD_ENV === BuildEnvVariable.DEVELOPMENT;
const isProduction = BUILD_ENV === BuildEnvVariable.PRODUCTION;

module.exports = {
  BUILD_ENV,
  BUILD_LOGGER,
  NODE_ENV,
  APP_PORT,
  isProduction,
  isDevelopment,
};
