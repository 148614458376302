// Core
import { Fragment, ElementType } from "react";

// Definitions
import { SkeletonProps as AntdSkeletonProps } from "antd";
import { SkeletonButtonProps } from "antd/es/skeleton/Button";

// Components
import { Skeleton as AntdSkeleton } from "antd";

type SkeletonItemsSizeType = "large" | "small" | "default";
type SkeletonItemsProps = AntdSkeletonProps & {
  size?: SkeletonItemsSizeType;
  items?: number;
  active?: boolean;
  className?: string;
  nodeItem?: ElementType;
};

const SkeletonButton = (props: SkeletonButtonProps) => {
  return <AntdSkeleton.Button {...props} />;
};

export const SkeletonItems = (props: SkeletonItemsProps) => {
  const { nodeItem: ElementItem = Fragment, items = 3, active = true, ...rest } = props;

  return (
    <>
      {Array(items || 1)
        .fill(0)
        .map((_, idx) => (
          <ElementItem key={idx}>
            <AntdSkeleton.Input active={active} {...rest} />
          </ElementItem>
        ))}
    </>
  );
};

SkeletonItems.Button = SkeletonButton;
