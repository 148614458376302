// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadAddPaymentInfoType,
  EventAddPaymentInfoDataType,
  EventsDataUnionType,
  EventPayloadCartProductItemType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  EcommerceEnum,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
  AFFILIATION_TYPE,
} from "client/utils/gtm/gtm.types";

class AddPaymentInfoEvent extends AbstractGTMEventSegment<GTMTypesEnum.add_payment_info> {
  protected readonly segmentShape: EventPayloadAddPaymentInfoType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.cart,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.payment_type]: "",
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadAddPaymentInfoType {
    const { route, checkout, itemListId } = data as EventAddPaymentInfoDataType;
    const { currency, priceVat } = super.getPriceConfig(String(checkout.totalPrice?.country));

    const segment = this.getSegment();

    const coupon = checkout.paymentMethodsSecondary.find(
      (item) => item.isActive && item.meta?.coupon?.number,
    );

    if (coupon) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.coupon] = String(coupon);
    }

    if (route) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    }

    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(
      checkout?.deliveryDate,
    );

    const currentPaymentMethod = checkout.paymentMethodsPrimary.find((method) => method.isActive);

    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] = Number(checkout.totalPrice?.value);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = currency;

    if (currentPaymentMethod) {
      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.payment_type] = String(
        currentPaymentMethod.id,
      );
    }

    checkout.products.forEach((product) => {
      const price = Number(priceVat ? product.priceVat?.value : product.priceNonVat?.value);
      const itemVariant = product.isVinCompatibility
        ? EcommerceProductVariantsEnum.vin_compitable
        : EcommerceProductVariantsEnum.vin_incompitable;

      const productData: EventPayloadCartProductItemType = {
        [EcommerceItemsEnum.item_id]: Number(product.articleId),
        [EcommerceItemsEnum.item_name]: String(product.name),
        [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
        [EcommerceItemsEnum.item_brand]: String(product.brand?.name),
        ...(product.genericId && { [EcommerceItemsEnum.item_category3]: product.genericId }),
        [EcommerceEnum.item_list_id]: itemListId,
        [EcommerceItemsEnum.item_variant]: itemVariant,
        [EcommerceItemsEnum.price]: price,
        [EcommerceItemsEnum.quantity]: Number(product.quantity?.value),
      };

      segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items].push(productData);
    });

    return segment;
  }
}

export const addPaymentInfoEvent: AddPaymentInfoEvent = new AddPaymentInfoEvent();
