// Core
import cx from "classnames";

// Components
import { Grid } from "antd";
import { Image } from "components/ui/Image";
import { Link } from "components/common/Link";

// Utils
import st from "./Styles.module.less";
import { book } from "init/book";

export enum LogoType {
  light = "light",
  dark = "dark",
}
export enum LogoSrcList {
  light = "/assets/images/pro-logo-light.svg",
  dark = "/assets/images/pro-logo-dark.svg",
}
export enum LogoSrcMobileList {
  light = "/assets/images/pro-logo-light-mobile.svg",
  dark = "/assets/images/pro-logo-dark-mobile.svg",
}
type LogoProps = {
  width?: number;
  height?: number;
  alt?: string;
  type?: "light" | "dark";
  testId?: string;
};

export const Logo = (props: LogoProps) => {
  const { alt = "Autodoc Pro", width = 200, height = 40, type = LogoType.light, testId } = props;
  const { lg = true, md = true } = Grid.useBreakpoint();

  const logoType = LogoType[type] || LogoType.light;
  const logoSrc = md
    ? LogoSrcList[logoType] || LogoSrcList.light
    : LogoSrcMobileList[logoType] || LogoSrcList.light;

  const logoStyle = cx(st.logo, {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });

  return (
    <div className={logoStyle} data-test-id={testId}>
      <Link href={book.home} shallow={false} className={st.box}>
        <Image
          key={logoSrc}
          src={logoSrc}
          width={width}
          height={height}
          alt={alt}
          unoptimized
          priority
        />
      </Link>
    </div>
  );
};
