// Definitions
import type { MenuPropsType } from "components/ui/Menu";

// Components
import { Menu } from "components/ui/Menu";

// Utils
import st from "./Styles.module.less";

type NavbarProps = {
  menuItems: MenuPropsType["items"];
};

export const Navbar = ({ menuItems }: NavbarProps) => {
  return (
    <nav className={st.navbar} data-test-id="navigation">
      <Menu items={menuItems} mode="horizontal" theme="dark" />
    </nav>
  );
};
