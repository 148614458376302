// Definitions
import type { LinkProps } from "../../Link";

// Components
import { Link } from "../../Link";
import { Text } from "../../Typography/Text";
import { Tooltip } from "components/ui/Tooltip";
import { Icon } from "components/ui/Icon";

// Utils
import st from "./Styles.module.less";

type InputLabelType = {
  text: string;
  required?: boolean;
  linkProps?: LinkProps & { text: string };
  linkComponent?: React.ElementType;
  helperContent?: React.ReactElement;
};

export const InputLabel = (props: InputLabelType) => {
  const { required, helperContent, text, linkComponent: LinkRender = Link, linkProps } = props;

  const LabelBase = ({ children }: { children?: React.ReactNode }) => {
    return (
      <div className={st.label}>
        <Text size="14" color="gray-900" font="system" fontWeight="600">
          <>
            {text}
            {required && <span> *</span>}
            {helperContent && (
              <span className={st["label-helper"]}>
                <Tooltip
                  placement="top"
                  type="primary"
                  gutter="small"
                  color="primary"
                  title={helperContent}
                  width="large"
                >
                  <Icon name="InputLabelInfo" />
                </Tooltip>
              </span>
            )}
          </>
        </Text>
        {children}
      </div>
    );
  };

  if (linkProps) {
    const { text: linkText, ...otherLinkProps } = linkProps;
    return (
      <LabelBase>
        <LinkRender {...otherLinkProps}>{linkText}</LinkRender>
      </LabelBase>
    );
  }

  return <LabelBase />;
};
