// Core
import { useEffect, useState } from "react";

// Utils
import { createContentLinkedList } from "client/core/header-banner/utils/common";

type UseHeaderBannerCreateContentType = {
  content: string;
};

export const useCreateContentBannerContent = (
  data: string[],
  timeOut: number,
): UseHeaderBannerCreateContentType => {
  const content = createContentLinkedList(data);

  const [dataIndex, setDataIndex] = useState<number>(Number(content[0].next));

  useEffect(() => {
    const interval = setInterval(() => setDataIndex(content[dataIndex].next), timeOut);

    return () => clearInterval(interval);
  }, [dataIndex]);

  return { content: content[dataIndex].data };
};
