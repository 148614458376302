// Definitions
import type { ItemListIdType, RouteNameKeysType } from "client/utils/gtm/gtm.types";
import { GTMTypesEnum } from "client/utils/gtm/gtm.types";
import type { CartPreviewType, CartTabProductType } from "client/core/cart/models";

// Utils
import { gtmService } from "client/utils/gtm";

type EventRemoveFromCartDataType = {
  product: CartTabProductType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export const triggerRemoveFromCartGtmEvent = (eventData: EventRemoveFromCartDataType) => {
  const { product, itemListId, route } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.remove_from_cart, {
    product,
    itemListId,
    ...(route && { route }),
  });
};

type EventViewCartDataType = {
  cartPreview: CartPreviewType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export const triggerViewCartGtmEvent = (eventData: EventViewCartDataType) => {
  const { cartPreview, itemListId, route } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.view_cart, {
    cartPreview,
    itemListId,
    ...(route && { route }),
  });
};
