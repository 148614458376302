// Core
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const useProfileCountdownTimer = (targetDate: string): string => {
  const [remainingTime, setRemainingTime] = useState<string>("");

  useEffect(() => {
    const countdown = setInterval(() => {
      const currentDate = dayjs();
      const targetDateTime = dayjs(targetDate);
      const durations = dayjs.duration(targetDateTime.diff(currentDate));

      if (durations.asMilliseconds() <= 0) {
        clearInterval(countdown);
        setRemainingTime("00:00:00");
      } else {
        const days = durations.days().toString().padStart(2, "0");
        const hours = durations.hours().toString().padStart(2, "0");
        const minutes = durations.minutes().toString().padStart(2, "0");

        const formattedTime = `${days}:${hours}:${minutes}`;
        setRemainingTime(formattedTime);
      }
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [targetDate]);

  return remainingTime;
};
