// Core
import { Element } from "react-scroll";
import cx from "classnames";

// Definitions
import type { PropsWithChildren, ReactElement } from "react";

// Components
import { Layout as AntdLayout } from "antd";

// Utils
import { ScrollEnum } from "data/common";
import st from "./Styles.module.less";

type LayoutPropsType = PropsWithChildren<{
  children: ReactElement | ReactElement[];
  type?: "private" | "public";
}>;

export const LayoutApp = ({ type = "public", children }: LayoutPropsType) => {
  const layoutStyles = cx(st.layout, { [st[type]]: type });
  return (
    <Element name={ScrollEnum.toHeader}>
      <AntdLayout className={layoutStyles}>{children}</AntdLayout>
    </Element>
  );
};
