// Core
import { useContext } from "react";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

// Utils
import { PermissionContext } from "contexts/usePermissionContext";

type UseCartPreviewPriceVatAbility = {
  canSwitchVat: boolean;
};

export const useCartPreviewPriceVatAbility = (): UseCartPreviewPriceVatAbility => {
  const ability = useContext(PermissionContext);

  const canSwitchVat = ability.can(PERMISSION_ACT.READ, "cart_actions_vat_price");

  return { canSwitchVat };
};
