// Definitions
import { GTMTypesEnum, GTMConfigType } from "client/utils/gtm/gtm.types";
import type { ConsentCookies } from "client/core/consent-cookies/models";
import { eventsMap } from "./gtm-events-register";

// Utils
import { verifyBuild } from "utils/verify-build";
import { cookiesService } from "utils/cookies";

const CONSENT_COOKIE_KEY = "atd-pro-portal-consent";

const serviceActiveDefault = verifyBuild().isProduction;

const eventsUnrestricted = [GTMTypesEnum.consent_mode_option, GTMTypesEnum.consent_mode_def];

const eventsByConsentLevel: Record<keyof ConsentCookies, GTMTypesEnum[]> = {
  necessary: [],
  statistics: [
    GTMTypesEnum.pageLoad,
    GTMTypesEnum.authorization,
    GTMTypesEnum.registration,
    GTMTypesEnum.search,
    GTMTypesEnum.selector_car,
    GTMTypesEnum.catalog,
    GTMTypesEnum.show_price,
    GTMTypesEnum.add_to_cart,
    GTMTypesEnum.begin_checkout,
    GTMTypesEnum.purchase,
    GTMTypesEnum.add_tab,
    GTMTypesEnum.close_tab,
    GTMTypesEnum.tab_click,
    GTMTypesEnum.view_item_list,
    GTMTypesEnum.view_item,
    GTMTypesEnum.select_item,
    GTMTypesEnum.remove_from_cart,
    GTMTypesEnum.view_cart,
    GTMTypesEnum.add_payment_info,
    GTMTypesEnum.dashboard_banner,
    GTMTypesEnum.popup,
    GTMTypesEnum.header_top,
    GTMTypesEnum.footer_menu,
    GTMTypesEnum.notification_page,
    GTMTypesEnum.notification,
    GTMTypesEnum.ecommerce_clear,
  ],
};

const checkEventsConsentGranted = (name: GTMTypesEnum): boolean => {
  if (eventsUnrestricted.includes(name)) {
    return true;
  }

  const userConsent = cookiesService.fromJson<ConsentCookies>(CONSENT_COOKIE_KEY);
  const userConsentStatistics =
    userConsent?.statistics && eventsByConsentLevel.statistics.includes(name);
  const userConsentNecessary =
    userConsent?.necessary && eventsByConsentLevel.necessary.includes(name);

  return Boolean(userConsentStatistics || userConsentNecessary);
};

export const gtmConfig: GTMConfigType = {
  gtmId: String(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID),
  eventsMap,
  checkEventsConsentGranted,
  active: serviceActiveDefault,
};
