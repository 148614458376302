// Core
import { useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import type { UsePersonalManagerAdviserMetadataFetchType } from "client/core/personal-manager/hooks/use-personal-manager-metadata-fetch";

// Utils
import { book } from "init/book";
import { verifyEnvironment } from "utils/verify-environment";
import { GenesysChat } from "client/core/personal-manager/utils/genesys";

type ArgsType = {
  loadingMetadata?: boolean;
  metadata?: UsePersonalManagerAdviserMetadataFetchType["personalManagerAdviserMetadata"] | null;
  profileLanguage?: string;
};
type UsePersonaManagerAdviserChatType = {
  onStartAdviserChat: () => void;
};
export const usePersonaManagerAdviserChatCreate = (
  args: ArgsType,
): UsePersonaManagerAdviserChatType => {
  const { loadingMetadata, metadata = null, profileLanguage } = args;
  const router = useRouter();
  const [genesysChat, onInitGenesysChat] = useState<GenesysChat | null>(null);

  useEffect(() => {
    if (loadingMetadata) {
      return;
    }

    if (metadata && !genesysChat) {
      const GENESYS_URL = process.env.NEXT_PUBLIC_GENESYS_URL || "";
      const config = {
        main: {
          debug: verifyEnvironment().isDevelopment,
          lang: profileLanguage,
          i18n: `/genesys/i18n/widgets-${profileLanguage}.i18n.json`,
        },
        webchat: {
          dataURL: GENESYS_URL,
          privacyPolicyURL: `/${String(router.locale)}${book.privacyPolicy}`,
          devEnabled: verifyEnvironment().isDevelopment,
        },
      };
      onInitGenesysChat(new GenesysChat(metadata, config));
    }
  }, [loadingMetadata, profileLanguage, metadata, genesysChat]);

  const handleStartAdviserChat = useCallback(() => {
    void (async () => {
      if (!(metadata && genesysChat)) {
        throw new Error("Personal manager metadata not provided");
      }
      await genesysChat.openChat();
    })();
  }, [metadata, genesysChat]);

  return {
    onStartAdviserChat: handleStartAdviserChat,
  };
};
