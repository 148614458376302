// Core
import { createContext } from "react";

type CartPreviewProductAlternativeRowCacheType = {
  loading: boolean;
  onSubmit: () => void;
};

export const cartPreviewProductAlternativeRowCache =
  createContext<CartPreviewProductAlternativeRowCacheType>({
    loading: false,
    onSubmit: () => {},
  });
