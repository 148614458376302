// Components
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { WrapperPrice } from "components/common/WrapperPrice";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./styles.module.less";

type CartCellPriceWithOldProps = {
  value?: number | null;
  oldValue?: number | null;
  country?: string;
  testId?: string;
  isEmpty?: boolean;
  disabled?: boolean;
};

export const CartCellPriceWithOldPrice = (props: CartCellPriceWithOldProps) => {
  const {
    country = "",
    oldValue = null,
    value = null,
    testId = "cell-price-with-old-price",
    isEmpty = false,
    disabled,
  } = props;

  if (!value && isEmpty) {
    return (
      <div className={st.prices} data-test-id={testId}>
        {WrapperNodeDefault()}
      </div>
    );
  }

  return (
    <div className={st.prices} data-test-id={testId}>
      {oldValue ? (
        <>
          <Text color="orange-900" fontWeight="600" size="14">
            <WrapperPrice country={country} value={value} disabled={disabled} isEmpty={isEmpty} />
          </Text>
          <Text color="gray-700" decoration="line-through" size="14">
            <WrapperPrice
              country={country}
              value={oldValue}
              disabled={disabled}
              isEmpty={isEmpty}
            />
          </Text>
        </>
      ) : (
        <WrapperPrice country={country} value={value} disabled={disabled} isEmpty={isEmpty} />
      )}
    </div>
  );
};
