/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* Core */
import winston from "winston";
import TransportStream from "winston-transport";

type BrowserLogInfoType = {
  level: string;
  message: {
    isStarted: boolean;
    isFinished: boolean;
    operation: "Query" | "Mutation";
    document: string;
    producer: "Client";
    status: string | number;
    params: unknown;
    data: unknown;
  };
};

class BrowserConsoleTransport extends TransportStream {
  private methods = {
    debug: "debug",
    error: "error",
    info: "info",
    warn: "warn",
  } as const;

  log = (info: BrowserLogInfoType, next: () => void) => {
    if (Object.keys(this.methods).includes(info.level)) {
      const {
        document = "",
        operation = "",
        producer = "",
        status = null,
        isStarted = false,
        isFinished = false,
        data = null,
        params = null,
      } = info.message;

      setTimeout(() => {
        const meth = this.methods.info;
        const isErrorType = info.level === "error";

        const stylesRequestError = "font-size: 13px; background: transparent; color: firebrick";
        const stylesResponseError = "font-size: 13px; background: transparent; color: #ff0005";
        const stylesRequest = isErrorType
          ? stylesRequestError
          : "font-size: 13px; color: deepskyblue";
        const stylesResponse = isErrorType
          ? stylesResponseError
          : "font-size: 13px; color: #A47104";

        const action = JSON.stringify({ document, operation, producer });
        const actionStatus =
          (isStarted ? "was started." : "") || (isFinished ? "was finished." : "");
        const actionStatusCode = status ? `responseCode ${status}\n` : "";
        const response = data ? "result: " : "";
        const messageParams = params ? "params:" : "";
        const messageRequest = `${action} ${actionStatus}\n` + messageParams;
        const messageResponse = actionStatusCode + response;

        console[meth]("%c" + "ReactQuery: " + `${messageRequest}`, stylesRequest, params || "");
        messageResponse &&
          console[meth]("%c" + "ReactQuery: " + `${messageResponse}`, stylesResponse, data);
      });
    }

    this.emit("logged", info);
    next();
  };
}

export const browserReactQueryInformationLogger = winston.createLogger({
  transports: [new BrowserConsoleTransport()],
});
