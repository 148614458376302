// Core
import { z } from "zod";

export const CART_PREVIEW_VEHICLE_FIELD_TYPE = {
  selected: "selected",
} as const;

export const cartPreviewVehicleForm = {
  shape: {
    selected: "empty",
  },
  schema: () => {
    return z.object({
      [CART_PREVIEW_VEHICLE_FIELD_TYPE.selected]: z.string().trim(),
    });
  },
};
