// Core
import { useContext } from "react";

// Utils
import { cartPreviewVehiclesCache } from "client/core/cart/contexts/cart-preview-vehicles";

export const useCartPreviewVehiclesStore = () => {
  const context = useContext(cartPreviewVehiclesCache);
  if (!context) {
    throw new Error(
      "useCartPreviewVehiclesStore should only be called inside CartPreviewVehiclesStoreProvider",
    );
  }
  return context;
};
