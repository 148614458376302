// Core
import { z } from "zod";

// Definitions
import { criteriaItemSchema, CriteriaItem, RadioButtonItem } from "./FormFields";
import { SortType } from "./Sort";
import type { BaseUnknownRecordType } from "./Base";
import type { FilterQueryRequestType } from "../utils/filter-query";

const READ_TYPES = {
  ALL: "ALL",
  READ: "READ",
  UNREAD: "UNREAD",
} as const;

type ReadType = keyof typeof READ_TYPES;

const radioObjSchema = z.object({
  id: z.number(),
  label: z.string(),
  value: z.string(),
  count: z.number().optional(),
  isSelected: z.boolean().optional(),
});

export const filterSchema = z.object({
  priceRange: z.array(z.number()).optional(),
  brand: z.array(z.string()).optional(),
  generic: z.array(z.string()).optional(),
  delivery: z.string().optional(),
  priceFrom: z.number().nullable().optional(),
  priceTo: z.number().nullable().optional(),
  vehicleId: z.number().optional(),
  nodeId: z.number().optional(),
  installation: radioObjSchema
    .nullable()
    .or(z.array(radioObjSchema.nullable()))
    .nullable()
    .optional(),
  criteria: z
    .array(
      z
        .array(
          criteriaItemSchema
            .or(z.array(criteriaItemSchema.nullable().optional()))
            .nullable()
            .optional(),
        )
        .or(
          z.record(
            z.string(),
            criteriaItemSchema
              .or(z.array(criteriaItemSchema.nullable().optional()))
              .nullable()
              .optional(),
          ),
        )
        .nullable()
        .optional(),
    )
    .default([])
    .nullable()
    .optional(),
  fittingScore: z.string().optional(),
});
export type FilterType = z.infer<typeof filterSchema>;

export type FilterRequestInstallationType = { installation?: string };
export type FilterRequestFittingScoreType = { fittingScore?: string };
type FilterRequestValuesType =
  | FilterType
  | FilterRequestInstallationType
  | FilterRequestFittingScoreType;

export type FilterParamsType = {
  filter?: FilterRequestValuesType | null;
  page?: number | string;
  perPage?: number | string;
  sort?: SortType;
  read?: ReadType;
};

export type FilterStaticParamsType = {
  filter?: FilterQueryRequestType | null;
  page?: number | string;
  perPage?: number | string;
  sort?: SortType;
};

export type FilterQueryParamsType = FilterParamsType & BaseUnknownRecordType;
export type FilterStaticQueryParamsType<T extends Record<string, unknown> | void = {}> =
  FilterStaticParamsType & BaseUnknownRecordType & T;

export const FILTER_DYNAMIC_TYPES_KEYS = {
  checkboxGroup: "checkbox-group",
  radioGroup: "radio-group",
  priceRange: "price-range",
  radioGroupSide: "radio-group-side",
  criteriaGroup: "criteria-group",
  vehicleFilter: "vehicle-filter",
} as const;
export type FilterDynamicTypesKeysType =
  (typeof FILTER_DYNAMIC_TYPES_KEYS)[keyof typeof FILTER_DYNAMIC_TYPES_KEYS];

export const FILTER_DYNAMIC_FIELDS_KEYS = {
  priceRange: "priceRange",
  brand: "brand",
  delivery: "delivery",
  installation: "installation",
  criteria: "criteria",
  generic: "generic",
  fittingScore: "fittingScore",
  vehicleId: "vehicleId",
} as const;
export type FilterDynamicFieldsKeysType =
  (typeof FILTER_DYNAMIC_FIELDS_KEYS)[keyof typeof FILTER_DYNAMIC_FIELDS_KEYS];

export type FilterDynamicTypesEnumType = keyof { [key in FilterDynamicTypesKeysType]: string };

const filterDynamicStaticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});
type FilterDynamicStaticDataItemType = z.infer<typeof filterDynamicStaticDataItemSchema>;
export type FilterDynamicItemOptionType = FilterDynamicStaticDataItemType & {
  count?: number;
  isSelected?: boolean;
};

export type FilterDynamicItemVehicle = {
  label: string;
  value: number;
};

export type FilterDynamicItemPriceRange = {
  minPrice?: number;
  maxPrice?: number;
  value?: [number, number];
};

export type FilterHorizontalDynamicMountingSideType = {
  id?: number;
  label?: string;
  value?: string;
  isSelected?: boolean;
  data?: RadioButtonItem[] | null;
};

export type FilterHorizontalDynamicCriteriaType = {
  id?: number;
  name?: string;
  value?: number;
  data?: CriteriaItem[];
};

export type FilterDynamicItemDataType =
  | FilterDynamicItemVehicle
  | FilterDynamicItemOptionType[]
  | FilterDynamicItemPriceRange
  | FilterHorizontalDynamicMountingSideType
  | FilterHorizontalDynamicCriteriaType[];

export type FilterDynamicItemType = {
  data: FilterDynamicItemDataType;
  id: number;
  type: FilterDynamicTypesEnumType;
  fieldName?: FilterDynamicFieldsKeysType;
};

export type TableActionType = "sort" | "paginate";

export type FilterProductsParamsType = FilterParamsType & {
  nodeId?: string | number;
  vehicleId?: number;
  keyword?: string;
  oem?: string;
  vin?: string;
  articleId?: string;
};
