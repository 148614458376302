// Core
import { MouseEvent } from "react";

// Components
import { Text } from "components/ui/Typography/Text";
import { Icon } from "components/ui/Icon";

// Utils
import st from "./Styles.module.less";

type TagBadgeProps = {
  label: string;
  onClose?: () => void;
  testId?: string;
};

export const TagBadge = (props: TagBadgeProps) => {
  const { label, onClose, testId = "tag-badge" } = props;

  const handleClose = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClose?.();
  };

  return (
    <div className={st["tag-badge"]} data-test-id={testId}>
      <div className={st["tag-badge-text"]}>
        <Text className={st["tag-badge-label"]} size="12" color="white-100" wrap="nowrap">
          {label}
        </Text>
      </div>
      {onClose && (
        <div
          className={st["tag-badge-close"]}
          onClick={handleClose}
          data-test-id={"tag-badge-close"}
        >
          <Icon name="Close" size="mini" />
        </div>
      )}
    </div>
  );
};
