// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";
import type {
  CartPreviewType,
  CartProductParamsType,
  CartProductUpdateType,
} from "client/core/cart/models";

// Domains
import { cartKeys, initialCartState } from "client/core/cart/store";
import { cartService } from "client/core/cart/service";

// Utils
import { queryClient } from "init/queryClient";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type OnRemoveCartPreviewProductCbType = (
  data: CartProductParamsType,
) => Promise<[HttpErrorType | null]>;

type QueryKeyType = string[];

type UseCartPreviewProductRemoveType = {
  loadingCartPreviewProductRemove: boolean;
  onRemoveRepairCartPreviewProduct: OnRemoveCartPreviewProductCbType;
};

export const useCartPreviewProductRemoveMutation = (): UseCartPreviewProductRemoveType => {
  const removeProductProps = useMutationAsync<
    void,
    Partial<CartProductUpdateType>,
    Promise<CartProductUpdateType>,
    QueryKeyType
  >({
    key: [cartKeys.preview],
    name: "removeCartPreviewProductMutation",
    fetcher: cartService.removeCartPreviewProduct,
    options: {
      onSuccess: (_, payload) => {
        const queryCartKey = [cartKeys.preview];
        queryClient.setQueryData<CartPreviewType>(queryCartKey, (prevState) => {
          if (!prevState?.tabs.length) {
            return initialCartState.preview;
          }
          const tabs = prevState.tabs.map((tab) => {
            const products = tab.products.filter((product) => {
              return product.id !== payload.productId;
            });

            return { ...tab, products };
          });

          return {
            ...prevState,
            tabs,
          };
        });
      },
      onError: (err) => {
        return Promise.resolve(err);
      },
    },
  });

  const onRemove = useCallback<OnRemoveCartPreviewProductCbType>(async ({ id }) => {
    try {
      await removeProductProps.mutateAsync({ id: Number(id) });
      return [null];
    } catch (err) {
      return [err as HttpErrorType];
    }
  }, []);

  return {
    loadingCartPreviewProductRemove: removeProductProps.isLoading,
    onRemoveRepairCartPreviewProduct: onRemove,
  };
};
