// Core
import { PropsWithChildren } from "react";

// Utils
import { cartPreviewProductsRowCache } from "client/core/cart/contexts/cart-preview-products-row";

type CartPreviewProductsRowProviderType = PropsWithChildren<{
  loading?: boolean;
  name?: string;
  tooltip?: string;
  isDisabled?: boolean;
  onSubmit?: () => void;
  onChange?: () => void;
}>;

export const CartPreviewProductsRowProvider = (props: CartPreviewProductsRowProviderType) => {
  const { children, name, tooltip, isDisabled, onChange } = props;

  return (
    <cartPreviewProductsRowCache.Provider
      value={{
        name,
        isDisabled,
        tooltip,
        onChange,
      }}
    >
      {children}
    </cartPreviewProductsRowCache.Provider>
  );
};
