// Definitions
import type { ReactNode } from "react";
import type { CartTabProductType } from "client/core/cart/models";

// Components
import { CartCellButton } from "client/core/cart/components/cart-cell-button";

// Hooks
import { useCartPreviewProductAlternativeRow } from "client/core/cart/hooks/use-cart-preview-product-alternative-row";

const CELL_CUSTOM_FIELD_TYPE = {
  action: "action",
} as const;
type CellCustomFieldType = keyof typeof CELL_CUSTOM_FIELD_TYPE;

type CartPreviewProductAlternativesCellCustomProps = {
  inputType: CellCustomFieldType;
  children: ReactNode;
  className: string;
  record?: CartTabProductType;
};

export const CartPreviewProductAlternativesCellCustom = (
  props: CartPreviewProductAlternativesCellCustomProps,
) => {
  const { inputType, children, record, ...restProps } = props;
  const { loading, onSubmit } = useCartPreviewProductAlternativeRow();

  switch (inputType) {
    case CELL_CUSTOM_FIELD_TYPE.action:
      return (
        <td {...restProps}>
          <CartCellButton
            danger
            block
            loading={loading}
            onSubmit={onSubmit}
            iconName="Cart"
            iconSize="small"
            htmlType="submit"
            testId="cell-custom-submit-button"
          />
        </td>
      );
    default:
      return <td {...restProps}>{children}</td>;
  }
};
