const VAT_COUNTRY_CODE = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

const vatCountries: Array<string> = [VAT_COUNTRY_CODE.DE, VAT_COUNTRY_CODE.EN];

export const checkIsVatCountry = (country?: string) => {
  if (!country) return true;
  return vatCountries.includes(country.toLocaleUpperCase());
};

export const getVatSignByCountry = (country: string = VAT_COUNTRY_CODE.EN, invert = false) => {
  const isVatCountry = checkIsVatCountry(country);
  if (!invert) {
    return isVatCountry ? "common:withVat" : "common:notVat";
  }
  return !isVatCountry ? "common:withVat" : "common:notVat";
};
