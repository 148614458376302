// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadHeaderTopType,
  EventHeaderTopDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ANALYTICS_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class HeaderTopEvent extends AbstractGTMEventSegment<GTMTypesEnum.header_top> {
  protected readonly segmentShape: EventPayloadHeaderTopType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadHeaderTopType {
    const { hotTrickerName } = data as EventHeaderTopDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] =
      `${hotTrickerName}_${ANALYTICS_ACTION_TYPE.view} and ${hotTrickerName}_${ANALYTICS_ACTION_TYPE.click}`;

    return this.getSegment();
  }
}

export const headerTopEvent: HeaderTopEvent = new HeaderTopEvent();
