// Core
import { useCallback } from "react";
import { useRouter, NextRouter } from "next/router";
import QS from "qs";
import { useQuery } from "@apollo/client";

// Definitions
import type { FilterParamsType } from "models/Filter";
import type {
  NotificationType,
  NotificationsResponseType,
  NotificationsParamsType,
  PaginationType,
} from "bus/notifications/models";
import type { NotificationTagType, NotificationReadType } from "bus/notifications/models";

// Utils
import { initMetaState, QS_QUERY_CONFIG } from "utils/constants";
import { getParamsFilterStaticFromQueryStr } from "utils/filters";
import NOTIFICATIONS_GET_ALL_QUERY from "bus/notifications/gql/get-all-query.graphql";

export const getComposedQueryParams = (
  propsQuery: NotificationsParamsType["params"],
  urlQuery: NextRouter["query"],
) => {
  const initialQueryParams = {
    perPage: propsQuery?.perPage || initMetaState.perPage,
    page: propsQuery?.page || initMetaState.page,
    ...(propsQuery?.read && { read: propsQuery.read }),
    ...(propsQuery?.type && { type: propsQuery.type }),
  };
  const urlQueryParams = getParamsFilterStaticFromQueryStr<{
    type?: NotificationTagType;
    read?: NotificationReadType;
  }>(urlQuery);
  return {
    ...initialQueryParams,
    ...urlQueryParams,
    ...(urlQueryParams?.page && { page: Number(urlQueryParams.page) }),
    ...(urlQueryParams?.perPage && { perPage: Number(urlQueryParams.perPage) }),
  };
};

type UseCallbackFetchNotifications = (listData?: FilterParamsType | null) => void;
type UseNotificationsFetchType = {
  loadingNotificationsFetch?: boolean;
  pagination: PaginationType;
  notifications: NotificationType[];
  totalUnread: number;
  onRefetchNotifications: UseCallbackFetchNotifications;
};

export const useNotificationsFetch = (
  props?: NotificationsParamsType,
): UseNotificationsFetchType => {
  const router = useRouter();
  const queryParams = getComposedQueryParams(props?.params, router.query);

  const notificationsAllQuery = useQuery<NotificationsResponseType>(NOTIFICATIONS_GET_ALL_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: queryParams,
  });

  const onRefetch = useCallback<UseCallbackFetchNotifications>(
    (listData) => {
      void (async () => {
        const pathname = router.pathname;
        if (listData) {
          await router.replace(
            {
              pathname,
              query: QS.stringify(listData, QS_QUERY_CONFIG),
            },
            void 0,
            { shallow: true },
          );
        }
      })();
    },
    [router],
  );

  const notifications = notificationsAllQuery?.data?.getAllNotifications;

  return {
    loadingNotificationsFetch: notificationsAllQuery.loading,
    pagination: notifications?.meta || initMetaState,
    notifications: notifications?.items || [],
    totalUnread: notifications?.totalUnread || Number(null),
    onRefetchNotifications: onRefetch,
  };
};
