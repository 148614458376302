// Definitions
import type { CountriesType } from "bus/staticData/models";
import type { StaticDataItemType } from "bus/staticData/models";
import type { BaseUnknownRecordType } from "models/Base";

// Domains
import { staticDataKeys } from "bus/staticData/store";
import { staticDataService } from "bus/staticData/service";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";

type CountriesQueryKeyType = [
  string,
  {
    country?: string;
  },
];

export type UseCountriesType = {
  countries: StaticDataItemType[];
  loadingCountries: boolean;
};
type UseCountriesFetchProps = {
  country?: string;
};

export const useCountriesFetch = (props: UseCountriesFetchProps): UseCountriesType => {
  const { country } = props;
  const queryParams = {
    ...(country && { country }),
  };

  const queryKey: CountriesQueryKeyType = [staticDataKeys.staticData, queryParams];
  const fetchCountriesProps = useQueryAsync<
    CountriesType,
    CountriesType,
    CountriesQueryKeyType,
    BaseUnknownRecordType
  >({
    key: queryKey,
    name: "fetchCountriesQuery",
    fetcher: staticDataService.fetchCountries,
  });

  return {
    loadingCountries: fetchCountriesProps.isLoading,
    countries: fetchCountriesProps.data?.items || [],
  };
};
