// Core
import { createContext } from "react";

type CartCompleteType = {
  availableCart: boolean;
  onToggleAvailableCart: (value: boolean) => void;
};

export const cartCache = createContext<CartCompleteType>({
  availableCart: false,
  onToggleAvailableCart: (value: boolean) => value,
});
