// Core
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Link } from "components/common/Link";
import { Icon } from "components/ui/Icon";
import { Container } from "components/common/Container";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { book } from "init/book";
import st from "./styles.module.less";

const CART_STEP_VALUE = {
  preview: 0,
  deliveryAddress: 1,
} as const;
type CartNavigationProps = {
  step: number;
};

export const CartNavigation = ({ step }: CartNavigationProps) => {
  const { t } = useTranslation();

  const renderLink = () => {
    if (step === CART_STEP_VALUE.preview) {
      return (
        <Link
          href={`${book.newCatalog}/new`}
          type="secondary"
          size="16"
          fontWeight="600"
          testId="continue-shopping-link"
          icon={() => <Icon name="ArrowLeft" />}
        >
          {t("cart:steps.continueBtn")}
        </Link>
      );
    }
    // TODO: remove rule or split for enitty OrdersDeliveryAddressEdit
    if (step === CART_STEP_VALUE.deliveryAddress) {
      return (
        <Link
          href={book.ordersCheckout}
          type="secondary"
          size="16"
          fontWeight="600"
          icon={() => <Icon name="ArrowLeft" />}
        >
          {t("cart:steps.previousBtn")}
        </Link>
      );
    }
  };

  return (
    <nav className={st["cart-nav"]} data-test-id="cart-nav">
      <Container>
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
          <Col span={8}>
            <div className={st.content} data-test-id="nav-back-link">
              {renderLink()}
            </div>
          </Col>
        </Row>
      </Container>
    </nav>
  );
};
