// Core
import { z } from "zod";
import { TFunction } from "i18next";

export enum PersonalMangerOperatorAssesmentFormEnum {
  rating = "rating",
  comment = "comment",
}

export const inputConfig = {
  [PersonalMangerOperatorAssesmentFormEnum.rating]: {
    label: PersonalMangerOperatorAssesmentFormEnum.rating,
    minError: "inputs:errorMessages.min",
    min: 1,
  },
  [PersonalMangerOperatorAssesmentFormEnum.comment]: {
    label: "inputs:comment.label",
    placeholder: "inputs:comment.placeholder",
    max: 2056,
  },
};

const PersonalManagerOperatorAssesmentFormScheme = z.object({
  rating: z.number(),
  comment: z.string().optional(),
});

export type PersonalManagerOperatorAssesmentFormType = z.infer<
  typeof PersonalManagerOperatorAssesmentFormScheme
>;

export const personalManagerOperatorAssesmentForm = {
  shape: {
    rating: 0,
    comment: "",
  },
  schema: (t: TFunction) =>
    z.object({
      [PersonalMangerOperatorAssesmentFormEnum.rating]: z.number().min(
        1,
        t(inputConfig[PersonalMangerOperatorAssesmentFormEnum.rating].minError, {
          label: inputConfig[PersonalMangerOperatorAssesmentFormEnum.rating].label,
          count: inputConfig[PersonalMangerOperatorAssesmentFormEnum.rating].min,
        }),
      ),
      [PersonalMangerOperatorAssesmentFormEnum.comment]: z.string().trim().optional(),
    }),
};
