// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";
import { CartPreviewType, CartTabType } from "client/core/cart/models";

// Domains
import { cartKeys, initialCartState } from "client/core/cart/store";

// Utils
import { queryClient } from "init/queryClient";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";
import { cartService } from "client/core/cart/service";

type OnUpdateCartPreviewVehicleCbType = (
  data: Partial<CartTabType>,
) => Promise<CartPreviewType | HttpErrorType>;
type UseCartPreviewVehicleMutation = {
  loadingCartPreviewVehicle: boolean;
  onUpdateCartPreviewVehicle: OnUpdateCartPreviewVehicleCbType;
};
type CartPreviewVehicleUpdateMutationKeyType = [string, number];
type CartPreviewVehicleMutationType = Promise<CartPreviewType>;

export const useCartPreviewVehicleUpdateMutation = (
  vehicleId?: number,
): UseCartPreviewVehicleMutation => {
  const queryKey: CartPreviewVehicleUpdateMutationKeyType = [cartKeys.preview, Number(vehicleId)];

  const cartPreviewVehicleProps = useMutationAsync<
    CartPreviewType,
    Partial<CartPreviewType>,
    CartPreviewVehicleMutationType,
    CartPreviewVehicleUpdateMutationKeyType
  >({
    key: queryKey,
    name: "updateCartPreviewVehiclesMutation",
    fetcher: cartService.updateCartPreviewVehicles,
    fetcherPayload: { id: vehicleId },
    options: {
      onSuccess: (data) => {
        if (!data) return;

        const queryCartKey = [cartKeys.preview];
        queryClient.setQueryData<CartPreviewType>(queryCartKey, (prevState) => {
          if (!prevState?.tabs.length) {
            return initialCartState.preview;
          }

          const tabs = prevState.tabs.map((tab, indexTab) => {
            if (tab.id === vehicleId) {
              return data.tabs[indexTab];
            }

            return {
              ...tab,
              deliveryDate: data.tabs[indexTab].deliveryDate,
              selected: data.tabs[indexTab].selected,
            };
          });

          return {
            ...prevState,
            totalPrice: data.totalPrice,
            totalPriceVat: data.totalPriceVat,
            vatPercentage: data.vatPercentage,
            vatPrice: data.vatPrice,
            deliveryDate: data.deliveryDate,
            selected: data.selected,
            checkoutAvailable: data.checkoutAvailable,
            tabs,
          };
        });
      },
    },
  });

  const onUpdateCartPreviewVehicle = useCallback<OnUpdateCartPreviewVehicleCbType>(async (data) => {
    try {
      return await cartPreviewVehicleProps.mutateAsync(data);
    } catch (err) {
      return err as HttpErrorType;
    }
  }, []);

  return {
    loadingCartPreviewVehicle: cartPreviewVehicleProps.isLoading,
    onUpdateCartPreviewVehicle,
  };
};
