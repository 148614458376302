// Core
import React, { ReactElement, forwardRef } from "react";
import cx from "classnames";

// Components
import { Radio } from "antd";

// Definitions
import { RadioGroupProps } from "antd";
import type { CheckboxOptionType } from "antd";
import type { RadioChangeEvent, RadioGroupButtonStyle } from "antd/lib/radio";

// Utils
import st from "./Styles.module.less";

export type OptionType =
  | string
  | number
  | CheckboxOptionType
  | {
      id: number;
      label: string;
      value: string;
      icon?: ReactElement;
    };

export type OptionEventType = RadioChangeEvent | OptionType;

export type InputRadioGroupType = Omit<RadioGroupProps, "options" | "buttonStyle"> & {
  hasDisabled?: boolean;
  type?: "primary" | "secondary";
  buttonStyle?: RadioGroupButtonStyle | "icon";
  options?: Array<OptionType>;
  value?: OptionType;
  onChange?: (event: OptionEventType) => void;
};

export const InputRadioGroup = forwardRef<HTMLDivElement, InputRadioGroupType>(
  (props, ref): ReactElement => {
    const {
      className,
      hasDisabled = false,
      buttonStyle = "",
      children,
      type,
      options,
      value,
      onChange,
      ...rest
    } = props;

    const radioStyle = cx(
      st.radio,
      {
        [st.disabled]: hasDisabled,
      },
      type && { [st[type]]: type },
      className,
    );

    const handleRadioButtonChange = (event: RadioChangeEvent) => {
      const targetValue = String(event.target.value);

      const currentOption = options?.find((option) => {
        if (typeof option === "string" || typeof option === "number") {
          return String(option) === targetValue;
        }

        return option.value === targetValue;
      });

      if (currentOption) {
        onChange && onChange?.(currentOption);
      }
    };

    if (buttonStyle === "icon" && options?.length) {
      return (
        <Radio.Group
          {...rest}
          className={radioStyle}
          onChange={handleRadioButtonChange}
          disabled={hasDisabled}
          value={value?.value}
          ref={ref}
        >
          {options.map((option, idx) => {
            if (typeof option === "string" || typeof option === "number") {
              return (
                <Radio.Button key={idx} value={option}>
                  {option}
                </Radio.Button>
              );
            }
            return (
              <Radio.Button key={idx} value={option.value}>
                {"icon" in option ? option.icon : option.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      );
    }

    return (
      <Radio.Group
        {...rest}
        onChange={onChange}
        value={value}
        className={radioStyle}
        disabled={hasDisabled}
        options={options}
        ref={ref}
      >
        {children}
      </Radio.Group>
    );
  },
);
InputRadioGroup.displayName = "InputRadioGroup";
