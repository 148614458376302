// Core
import { PropsWithChildren, ReactElement, createContext, useState } from "react";

// Definitions
import type { OrdersPaymentSavedCreditCardType } from "client/core/order-payment/models";

type PaymentSavedCardsContextProps = {
  paymentResult: OrdersPaymentSavedCreditCardType | null;
  setPaymentResult: (card: OrdersPaymentSavedCreditCardType | null) => void;
};

export const PaymentSavedCardsContext = createContext<PaymentSavedCardsContextProps>({
  paymentResult: null,
  setPaymentResult: () => {},
});

type PaymentSavedCardsProviderProps = PropsWithChildren<{ children: ReactElement }>;

export const PaymentSavedCardsProvider = (props: PaymentSavedCardsProviderProps) => {
  const [paymentResult, setPaymentResult] = useState<OrdersPaymentSavedCreditCardType | null>(null);

  return (
    <PaymentSavedCardsContext.Provider
      value={{
        paymentResult,
        setPaymentResult,
      }}
    >
      {props.children}
    </PaymentSavedCardsContext.Provider>
  );
};
