// Core
import { i18n } from "next-i18next";

// Definition
import { NotificationMessage } from "components/ui/Notification/Notification.types";

const httpErrorMessage: NotificationMessage = {
  title: "Unavailable",
  desc: "Something went wrong. Please, try again later",
};
const httpSuccessMessageGeneric: NotificationMessage = {
  title: "Success",
  desc: "",
};
const httpSuccessMessageUpdate: NotificationMessage = {
  title: "Success",
  desc: "You data was updated successfully!",
};
const httpErrorMessageNoData: NotificationMessage = {
  title: "No Data",
  desc: "Data for the app was not loaded successfully",
};

export const getHttpErrorMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errors.title") || httpErrorMessage.title,
  desc: i18n?.t("common:errors.desc") || httpErrorMessage.desc,
});
export const getHttpSuccessMessageCallBackWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("intro:callBack.success.title") || httpSuccessMessageGeneric.title,
  desc: i18n?.t("intro:callBack.success.desc") || httpSuccessMessageGeneric.desc,
});
export const getHttpSuccessMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:notifications.update.title") || httpSuccessMessageUpdate.title,
  desc: i18n?.t("common:notifications.update.desc") || httpSuccessMessageUpdate.desc,
});
export const getHttpErrorMessageNoPersonalManagerWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("personal-manager:operatorAssesment.noManagerError.title") ||
    httpErrorMessageNoData.title,
  desc:
    i18n?.t("personal-manager:operatorAssesment.noManagerError.desc") ||
    httpErrorMessageNoData.desc,
});
