// Core
import { mergeRight } from "ramda";
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  EventPayloadRegistrationType,
  EventRegistrationDataType,
  ParametersEnum,
  VisitorTypeEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class RegistrationEvent extends AbstractGTMEventSegment<GTMTypesEnum.registration> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.user_id]: 0,
      [ParametersEnum.visitor_type]: VisitorTypeEnum.unknown_user,
      [ParametersEnum.registration_step]: 0,
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadRegistrationType {
    const registrationData = data as EventRegistrationDataType;
    const params = mergeRight(this.segmentShape[PayloadKeysEnum.parameters], registrationData);
    return {
      [PayloadKeysEnum.parameters]: params,
    };
  }
}

export const registrationEvent: RegistrationEvent = new RegistrationEvent();
