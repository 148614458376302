// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import type { PersonalManagerCallBackType } from "client/core/personal-manager/models";
import type { BaseQueryKey } from "models/Base";
import type { HttpErrorType } from "models/Http";
import type { FormSubmitParams } from "models/Forms";
import { HttpStatusCode, HttpServerErrorCode } from "models/Http";

// Domains
import { personalManagerKeys } from "client/core/personal-manager/store";

// Utils
import { GET_ENUMS, GET_PARAMS } from "utils/constants";
import {
  getHttpErrorMessageWithTranslations,
  getHttpSuccessMessageCallBackWithTranslations,
} from "client/core/personal-manager/utils/notification-messages";
import { fillServerErrorsToForm } from "utils/forms";
import { notificationService } from "utils/notifications";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";
import { personalManagerService } from "client/core/personal-manager/service";

type UsePersonalManagerAdviserCallbackPropsType = {
  loadingPersonalManagerCallBack: boolean;
  onPersonalManagerCallBack: (values: FormSubmitParams<PersonalManagerCallBackType>) => void;
};

export const usePersonalManagerAdviserCallbackCreate =
  (): UsePersonalManagerAdviserCallbackPropsType => {
    const router = useRouter();
    const queryKey: BaseQueryKey = [personalManagerKeys.callBack];
    const personalManagerCallBackMutation = useMutationAsync<
      PersonalManagerCallBackType,
      PersonalManagerCallBackType,
      unknown,
      BaseQueryKey
    >({
      key: queryKey,
      name: "personalManagerCallBackMutation",
      fetcher: personalManagerService.personalManagerCallBack,
      options: {
        onError: () => void 0,
      },
    });

    const onPersonalManagerCallBack = useCallback(
      (data: FormSubmitParams<PersonalManagerCallBackType>) => {
        void (async () => {
          try {
            await personalManagerCallBackMutation.mutateAsync(data.values);
            notificationService.showSuccess(getHttpSuccessMessageCallBackWithTranslations());
            data?.acts?.reset?.();
            await router.replace({
              pathname: router.pathname,
            });
          } catch (error) {
            /* toDo: move to error handling service */
            const err = error as HttpErrorType;
            /* handle http errors */
            const { callBackLimit, personalCallBackLimit } = HttpServerErrorCode;
            const isError400 = err.status === HttpStatusCode.badRequest;
            const isError422 = err.status === HttpStatusCode.unprocessableEntity;
            const isError500 = err.status >= HttpStatusCode.server;
            const isErrorLimitExceeded = err.errorCode === callBackLimit;
            const isErrorFoundInQueue = err.errorCode === personalCallBackLimit;

            if (isError422 && (isErrorLimitExceeded || isErrorFoundInQueue)) {
              await router.push({
                pathname: router.pathname,
                query: {
                  [GET_PARAMS.popup]: isErrorLimitExceeded
                    ? GET_ENUMS.popup.callBackLimit
                    : GET_ENUMS.popup.personalCallBackLimit,
                },
              });
              return;
            }
            if (isError400 || isError500 || isError422) {
              notificationService.showError(getHttpErrorMessageWithTranslations());
            }
            /* propagate errors to form */
            const errs = (error as HttpErrorType).errors;
            if (error instanceof Object && errs) {
              fillServerErrorsToForm(errs, data?.acts?.setError);
            }
          }
        })();
      },
      [personalManagerCallBackMutation],
    );

    return {
      loadingPersonalManagerCallBack: personalManagerCallBackMutation.isLoading,
      onPersonalManagerCallBack,
    };
  };
