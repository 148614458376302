// Core
import { useEffect, useState } from "react";

// Definitions
import { NOTIFICATION_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import { triggerNotificationGtmEvent } from "bus/notifications/utils/notifications-gtm-events";

const VIEW_PREVIEW_TIMER = 2500;

export const useNotificationsTrackViewPreview = () => {
  const [viewPreviewEventSent, setViewPreviewEventSent] = useState(false);
  let viewPreviewEventTimer: NodeJS.Timeout;

  useEffect(() => {
    if (!viewPreviewEventSent) {
      viewPreviewEventTimer = setTimeout(() => setViewPreviewEventSent(true), VIEW_PREVIEW_TIMER);
    } else {
      triggerNotificationGtmEvent({
        actionType: NOTIFICATION_ACTION_TYPE.preview,
      });
    }

    return () => clearTimeout(viewPreviewEventTimer);
  }, [viewPreviewEventSent]);
};
