// Core
import { useQuery } from "react-query";

// Definitions
import { BaseMutationPayloadType, BaseUnknownRecordType } from "models/Base";
import { FetchDataType, HttpErrorType } from "models/Http";
import { QueryKey } from "react-query/types/core/types";
import { UseQueryOptions } from "react-query/types/react/types";

// Utils
import { onError } from "utils/react-query/fetcher-error";
import { queryFetcher } from "utils/react-query/query-fetcher";
import { HttpStatusCode } from "models/Http";

type QueryPayloadType<TQueryParams = BaseUnknownRecordType> = {
  id?: number | string;
  params?: TQueryParams;
  meta?: BaseUnknownRecordType;
};

type UseQueryAsyncOptionsType<TQueryFnData, TData, TQueryKey extends QueryKey, TFetcherParams> = {
  key: TQueryKey;
  name: string;
  fetcher: FetchDataType<TQueryFnData>;
  fetcherPayload?: QueryPayloadType<TFetcherParams>;
  fetcherType?: "query" | "mutation";
  handlerError403?: () => Promise<void>;
  handlerError404?: () => Promise<void>;
  handlerError422?: () => Promise<void>;
  options?: Omit<
    UseQueryOptions<TQueryFnData, HttpErrorType, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >;
};

/* core */
export const useQueryAsync = <
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TFetcherParams = BaseUnknownRecordType,
>(
  props: UseQueryAsyncOptionsType<TQueryFnData, TData, TQueryKey, TFetcherParams>,
) => {
  const {
    key,
    name,
    fetcher,
    fetcherPayload: payload,
    fetcherType = "query",
    handlerError404,
    handlerError422,
    options,
  } = props;

  const fetcherPayload = {
    ...(payload?.id && { id: payload.id }),
    ...(payload?.params && { params: payload.params }),
    ...(payload?.meta && { meta: payload.meta }),
  };

  const handleError = (err: HttpErrorType) => {
    void (async () => {
      await onError({ err, handlerError404, handlerError422 });
    })();
  };

  return useQuery<TQueryFnData, HttpErrorType, TData, TQueryKey>(
    key,
    () =>
      queryFetcher<TQueryFnData, BaseMutationPayloadType<TFetcherParams>>({
        name,
        fetcher,
        fetcherType,
        fetcherPayload,
      }),
    {
      onError: handleError,
      ...options,
      useErrorBoundary: (error) => {
        return error.status >= HttpStatusCode.server;
      },
    },
  );
};
