// Definitions
import {
  PersonalManagerFAQList,
  PersonalManagerFAQType,
} from "client/core/personal-manager/models";

// Hooks
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import { verifyEnvironment } from "utils/verify-environment";
import {
  personalManagerFaqDE,
  personalManagerFaqFR,
  personalManagerFaqNL,
  personalManagerFaqAT,
} from "client/core/personal-manager/data/personal-manager";

const PERSONAL_MANAGER_COUNRY_FAQ_KEY = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

export const usePersonalManagerFaqFetch = (): {
  loadingPersonalManagerFaq: boolean;
  personalManagerFaq: PersonalManagerFAQList;
} => {
  const { profile } = useProfileContext();
  const countryCode = profile?.countryCode;
  const { isDevelopment } = verifyEnvironment();

  const mapList: { [key: string]: PersonalManagerFAQType[] } = {
    [PERSONAL_MANAGER_COUNRY_FAQ_KEY.EN]: personalManagerFaqFR,
    [PERSONAL_MANAGER_COUNRY_FAQ_KEY.FR]: personalManagerFaqFR,
    [PERSONAL_MANAGER_COUNRY_FAQ_KEY.DE]: personalManagerFaqDE,
    [PERSONAL_MANAGER_COUNRY_FAQ_KEY.AT]: personalManagerFaqAT,
    [PERSONAL_MANAGER_COUNRY_FAQ_KEY.NL]: personalManagerFaqNL,
  };

  if (isDevelopment) {
    return {
      loadingPersonalManagerFaq: false,
      personalManagerFaq: {
        items: mapList[countryCode || "EN"],
      },
    };
  }

  return {
    loadingPersonalManagerFaq: false,
    personalManagerFaq: { items: countryCode ? mapList[countryCode] : [] },
  };
};
