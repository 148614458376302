// Core
import { createContext, PropsWithChildren, useEffect, useState } from "react";

// Definitions
import type { PriceVatViewType } from "client/core/settings-price-vat/models";
import { PRICE_VAT_VIEW_KEYS } from "client/core/settings-price-vat/models";

type PriceVatType = {
  priceVatRepairEstimate: PriceVatViewType;
  setPriceVatRepairEstimate: (value: PriceVatViewType) => void;
  priceVatCartPreview: PriceVatViewType;
  setPriceVatCartPreview: (value: PriceVatViewType) => void;
  priceVatCatalog: PriceVatViewType;
  setPriceVatCatalog: (value: PriceVatViewType) => void;
};

export const SettingsPriceVatContext = createContext<PriceVatType>({
  priceVatRepairEstimate: PRICE_VAT_VIEW_KEYS.empty,
  setPriceVatRepairEstimate: () => {},
  priceVatCartPreview: PRICE_VAT_VIEW_KEYS.empty,
  setPriceVatCartPreview: () => {},
  priceVatCatalog: PRICE_VAT_VIEW_KEYS.empty,
  setPriceVatCatalog: () => {},
});

export const SettingsPriceVatProvider = ({
  children,
  viewPriceVat = PRICE_VAT_VIEW_KEYS.empty,
}: PropsWithChildren<{ viewPriceVat?: PriceVatViewType }>) => {
  const [priceVatRepairEstimate, setPriceVatRepairEstimate] =
    useState<PriceVatViewType>(viewPriceVat);
  const [priceVatCartPreview, setPriceVatCartPreview] = useState<PriceVatViewType>(viewPriceVat);
  const [priceVatCatalog, setPriceVatCatalog] = useState<PriceVatViewType>(viewPriceVat);

  useEffect(() => {
    if (viewPriceVat) {
      setPriceVatRepairEstimate(viewPriceVat);
      setPriceVatCartPreview(viewPriceVat);
      setPriceVatCatalog(viewPriceVat);
    }
  }, [viewPriceVat]);

  return (
    <SettingsPriceVatContext.Provider
      value={{
        priceVatRepairEstimate,
        setPriceVatRepairEstimate,
        priceVatCartPreview,
        setPriceVatCartPreview,
        priceVatCatalog,
        setPriceVatCatalog,
      }}
    >
      {children}
    </SettingsPriceVatContext.Provider>
  );
};
