// Core
import { forwardRef, ReactElement } from "react";
import cx from "classnames";

// Definitions
import type { CheckboxProps } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";

// Components
import { Checkbox } from "antd";

// Utils
import st from "./Styles.module.less";

enum InputCheckboxColorEnumType {
  base = "base",
}
enum InputCheckboxAlignEnumType {
  middle = "middle",
  top = "top",
  bottom = "bottom",
}
export enum InputCheckboxSizeEnumType {
  small = "small",
  middle = "middle",
  large = "large",
}
enum InputCheckboxModeEnumType {
  default = "default",
  indeterminate = "indeterminate",
}
enum InputCheckboxIndeterminateValueEnumType {
  empty = "empty",
  all = "all",
  partially = "partially",
}

const getValueModeIndeterminate = (value: string): boolean => {
  switch (value) {
    case InputCheckboxIndeterminateValueEnumType.all: {
      return true;
    }
    case InputCheckboxIndeterminateValueEnumType.empty:
    case InputCheckboxIndeterminateValueEnumType.partially: {
      return false;
    }
    default:
      return false;
  }
};

export type InputCheckboxType = CheckboxProps & {
  children?: ReactElement | ReactElement[];
  hasDisabled?: boolean;
  value?: boolean | string;
  mode?: keyof typeof InputCheckboxModeEnumType;
  size?: keyof typeof InputCheckboxSizeEnumType;
  color?: keyof typeof InputCheckboxColorEnumType;
  align?: keyof typeof InputCheckboxAlignEnumType;
  onChange?: (e: unknown) => void;
};

export const InputCheckbox = forwardRef<HTMLInputElement, InputCheckboxType>((props, ref) => {
  const {
    className,
    hasDisabled = false,
    children,
    value,
    color = InputCheckboxColorEnumType.base,
    size = InputCheckboxSizeEnumType.large,
    mode = InputCheckboxModeEnumType.default,
    align = InputCheckboxAlignEnumType.middle,
    ...rest
  } = props;

  const isModeIndeterminate = mode === InputCheckboxModeEnumType.indeterminate;
  const isModeIndeterminateStatus =
    value !== InputCheckboxIndeterminateValueEnumType.all &&
    value !== InputCheckboxIndeterminateValueEnumType.empty &&
    isModeIndeterminate;
  const checkedValue = isModeIndeterminate
    ? getValueModeIndeterminate(value as string)
    : Boolean(value);

  const handleChange = (evt: CheckboxChangeEvent) => {
    if (isModeIndeterminate) {
      const checked = evt.target.checked;
      const inputValue = evt.target.value;
      if (checked) {
        if (inputValue === InputCheckboxIndeterminateValueEnumType.all) {
          return rest?.onChange?.(InputCheckboxIndeterminateValueEnumType.empty);
        }
        if (inputValue === InputCheckboxIndeterminateValueEnumType.partially) {
          return rest?.onChange?.(InputCheckboxIndeterminateValueEnumType.all);
        }
        if (inputValue === InputCheckboxIndeterminateValueEnumType.empty) {
          return rest?.onChange?.(InputCheckboxIndeterminateValueEnumType.all);
        }
      }
      if (inputValue === InputCheckboxIndeterminateValueEnumType.all) {
        return rest?.onChange?.(InputCheckboxIndeterminateValueEnumType.empty);
      }
      if (inputValue === InputCheckboxIndeterminateValueEnumType.partially) {
        return rest?.onChange?.(InputCheckboxIndeterminateValueEnumType.all);
      }
      if (inputValue === InputCheckboxIndeterminateValueEnumType.empty) {
        return rest?.onChange?.(InputCheckboxIndeterminateValueEnumType.all);
      }
      return;
    }
    rest.onChange?.(evt);
  };
  const checkboxStyle = cx(
    st.checkbox,
    {
      ...(InputCheckboxSizeEnumType[size] && {
        [st[`checkbox-size-${size}`]]: InputCheckboxSizeEnumType[size],
      }),
      ...(InputCheckboxColorEnumType[color] && {
        [st[`checkbox-color-${color}`]]: InputCheckboxColorEnumType[color],
      }),
      ...(InputCheckboxAlignEnumType[align] && {
        [st[`checkbox-align-${align}`]]: InputCheckboxAlignEnumType[align],
      }),
    },
    className,
  );

  return (
    <Checkbox
      {...rest}
      className={checkboxStyle}
      disabled={hasDisabled}
      ref={ref}
      indeterminate={isModeIndeterminateStatus}
      checked={checkedValue}
      value={value}
      onChange={handleChange}
    >
      {children}
    </Checkbox>
  );
});
InputCheckbox.displayName = "InputCheckbox";
