// Core
import { useMemo } from "react";

// Domains
import { useCountriesFetch } from "bus/staticData/hooks/useCountriesFetch";
import { UserCountryItemType } from "bus/user/models";
import { UserCountryCodesEnum } from "bus/user/models";

// Utils
import { mapUserCountriesIcon } from "utils/maps";

export type UseUserCountriesStaticDataFetchType = {
  loadingStaticData: boolean;
  countries: UserCountryItemType[];
};

type UseUserCountriesStaticDataFetchProps = {
  country?: UserCountryCodesEnum;
};

export const useUserCountriesStaticDataFetch = (
  props: UseUserCountriesStaticDataFetchProps,
): UseUserCountriesStaticDataFetchType => {
  const { country } = props;

  const { loadingCountries, countries } = useCountriesFetch({ country });
  const countriesWithIcons = useMemo(
    () => (countries ? mapUserCountriesIcon(countries) : []),
    [countries],
  );

  return {
    loadingStaticData: loadingCountries,
    countries: countriesWithIcons,
  };
};
