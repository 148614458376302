/* Core */
import { QueryClient, QueryCache } from "react-query";

export const options = {
  defaultOptions: {
    queries: {
      staleTime: 0, // default: 0. Time after data is not actual
      cacheTime: 1000 * 60 * 5, // default
      retry: 0, // default 3
      retryOnMount: true, // default
      refetchActive: true, // default
      refetchInactive: false, // default
      refetchOnMount: true, // default: true
      refetchOnWindowFocus: false, // default: true
      refetchOnReconnect: true, // default
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      // eslint-disable-next-line  no-console
      console.warn(`Error global handler: ${JSON.stringify(error)}`);
      throw error;
    },
  }),
};

export const queryClient = new QueryClient(options);
