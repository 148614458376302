// benefits
export { default as BenefitBonus } from "./benefit-bonus.svg";
export { default as BenefitCrm } from "./benefit-crm.svg";
export { default as BenefitDelivery } from "./benefit-delivery.svg";
export { default as BenefitEstimate } from "./benefit-estimate.svg";
export { default as BenefitManager } from "./benefit-manager.svg";
export { default as BenefitPromo } from "./benefit-promo.svg";
export { default as BenefitReturn } from "./benefit-return.svg";
export { default as BenefitSearch } from "./benefit-search.svg";
// controls
export { default as ControlArrowDown } from "./control-arrow-down.svg";
export { default as ControlArrowUpn } from "./control-arrow-up.svg";
export { default as ControlCloseModal } from "./control-close-modal.svg";
export { default as ControlEdit } from "./control-edit.svg";
export { default as ControlRemove } from "./control-remove.svg";
// countries
export { default as CountryFr } from "./country-fr.svg";
export { default as CountryDe } from "./country-de.svg";
export { default as CountryAt } from "./country-at.svg";
export { default as CountryNl } from "./country-nl.svg";
// mounting
export { default as MountingBackBoth } from "./mounting-back-both.svg";
export { default as MountingBackLeft } from "./mounting-back-left.svg";
export { default as MountingBackRight } from "./mounting-back-right.svg";
export { default as MountingBackWiper } from "./mounting-back-wiper.svg";
export { default as MountingBothLeft } from "./mounting-both-left.svg";
export { default as MountingBothRight } from "./mounting-both-right.svg";
export { default as MountingFrontBoth } from "./mounting-front-both.svg";
export { default as MountingFrontBothWiper } from "./mounting-front-both-wiper.svg";
export { default as MountingFrontLeft } from "./mounting-front-left.svg";
export { default as MountingFrontLeftWiper } from "./mounting-front-left-wiper.svg";
export { default as MountingFrontRight } from "./mounting-front-right.svg";
export { default as MountingFrontRightWiper } from "./mounting-front-right-wiper.svg";
export { default as MountingBothBoth } from "./mounting-both-both.svg";
// order
export { default as OrderDateDelivery } from "./order-date-delivery.svg";
export { default as OrderDateDelivered } from "./order-date-delivered.svg";
export { default as OrderStatusNew } from "./order-status-new.svg";
export { default as OrderStatusTest } from "./order-status-test.svg";
export { default as OrderStatusAssembled } from "./order-status-assembled.svg";
export { default as OrderStatusShipped } from "./order-status-shipped.svg";
export { default as OrderStatusCancelled } from "./order-status-cancelled.svg";
export { default as OrderStatusDelivered } from "./order-status-delivered.svg";

// order payment status
export { default as OrderPaymentPaid } from "./order-payment-paid.svg";
export { default as OrderPaymentReceived } from "./order-payment-received.svg";
export { default as OrderPaymentWaiting } from "./order-payment-waiting.svg";
// other
export { default as ArrowRight } from "./arrow-right.svg";
export { default as AboutFilledMinus } from "./about-filled-minus.svg";
export { default as AboutFilledPlus } from "./arrow-right.svg";
export { default as ArrowLeft } from "./arrow-left.svg";
export { default as ArrowWhiteUp } from "./arrow-white-up.svg";
export { default as ArrowWhiteDown } from "./arrow-white-down.svg";
export { default as Basket } from "./basket.svg";
export { default as StepProcess } from "./step-process.svg";
export { default as StepWait } from "./step-wait.svg";
export { default as HomeFilled } from "./home-filled.svg";
export { default as User } from "./user.svg";
export { default as Bell } from "./bell.svg";
export { default as Cart } from "./cart.svg";
export { default as Headphones } from "./headphones.svg";
export { default as EuropeanUnion } from "./european-union.svg";
export { default as CarOutlined } from "./car-outlined.svg";
export { default as Delete } from "./delete.svg";
export { default as Change } from "./change.svg";
export { default as Envelop } from "./envelop.svg";
export { default as Phone } from "./phone.svg";
export { default as Refresh } from "./refresh.svg";
export { default as Truck } from "./truck.svg";
export { default as Check } from "./check.svg";
export { default as CheckCircleFilled } from "./check-circle-filled.svg";
export { default as Close } from "./close.svg";
export { default as CloseCircle } from "./close-circle.svg";
export { default as Recycle } from "./recycle.svg";
export { default as Wrench } from "./wrench.svg";
export { default as ShoppingBag } from "./shopping-bag.svg";
export { default as SettingsCustom } from "./settings-custom.svg";
export { default as PlayCustom } from "./play-custom.svg";
export { default as MessageCustom } from "./message-custom.svg";
export { default as CharacterA } from "./character-a.svg";
export { default as Percent } from "./percent.svg";
export { default as Warehouse } from "./warehouse.svg";
export { default as Warehouse2 } from "./warehouse2.svg";
export { default as Revenue } from "./revenue.svg";
export { default as Document } from "./document.svg";
export { default as ArrowRightDown } from "./arrow-right-down.svg";
export { default as Globus } from "./globus.svg";
export { default as User2 } from "./user2.svg";
export { default as PlusOutlined } from "./plus-outlined.svg";
export { default as PlusCircleOutlined } from "./plus-circle-outlined.svg";
export { default as RightOutlined } from "./right-outlined.svg";
export { default as LeftOutlined } from "./left-outlined.svg";
export { default as ExportOutlined } from "./export-outlined.svg";
export { default as SearchOutlined } from "./search-outlined.svg";
export { default as MinusOutlined } from "./minus-outlined.svg";
export { default as ExclamationCircleOutlined } from "./exclamation-circle-outlined.svg";
export { default as LoadingOutlined } from "./loading-outlined.svg";
export { default as EditOutlined } from "./edit-outlined.svg";
export { default as UndoOutlined } from "./undo-outlined.svg";
export { default as CloseOutlined } from "./close-outlined.svg";
export { default as SaveOutlined } from "./save-outlined.svg";
export { default as PrinterOutlined } from "./printer-outlined.svg";
export { default as DownloadOutlined } from "./download-outlined.svg";
export { default as DollarCircleFilled } from "./dollar-circle-filled.svg";
export { default as EuroCircleFilled } from "./euro-circle-filled.svg";
export { default as CompassFilled } from "./compass-filled.svg";
export { default as StarFilled } from "./star-filled.svg";
export { default as HddFilled } from "./hdd-filled.svg";
export { default as FileFilled } from "./file-filled.svg";
export { default as SignalFilled } from "./signal-filled.svg";
export { default as PieChartFilled } from "./pie-chart-filled.svg";
export { default as DownOutlined } from "./down-outlined.svg";
export { default as UpOutlined } from "./up-outlined.svg";
export { default as CheckSquareFilled } from "./check-square-filled.svg";
export { default as CloseSquareOutlined } from "./close-square-outlined.svg";
export { default as ErrorSign } from "./error-sign.svg";
export { default as SuccessSign } from "./success-sign.svg";
export { default as ChevronLeft } from "./chevron-left.svg";
export { default as ChevronRight } from "./chevron-right.svg";
export { default as ChevronDown } from "./chevron-down.svg";
export { default as VinCheck } from "./vin-check.svg";
export { default as CoreExchange } from "./core-exchange.svg";
export { default as CopyOutlined } from "./copy-outlined.svg";
export { default as Coupon } from "./coupon.svg";
export { default as TurnoverBonus } from "./turnover-bonus.svg";
export { default as FreeSpare } from "./free-spare.svg";
export { default as SpecialPromo } from "./special-promo.svg";
export { default as FreeReturns } from "./free-returns.svg";
export { default as CreationRepair } from "./creation-repair.svg";
export { default as SpecialSearch } from "./special-search.svg";
export { default as SimpleEasy } from "./simple-easy.svg";
export { default as ShevronRight } from "./shevron-right.svg";
export { default as ShevronLeft } from "./shevron-left.svg";
export { default as Leaf } from "./leaf.svg";
export { default as ProCross } from "./pro-cross.svg";
export { default as ServiceDedicated } from "./service-dedicated.svg";
export { default as ServiceVisit } from "./service-visit.svg";
export { default as ServiceAssist } from "./service-assist.svg";
export { default as AccountAlways } from "./account-always.svg";
export { default as AccountContact } from "./account-contact.svg";
export { default as AccountAvailableDays } from "./account-available-days.svg";
export { default as Pending } from "./pending.svg";
export { default as ArrowUpCounter } from "./arrow-up-counter.svg";
export { default as ArrowDownCounter } from "./arrow-down-counter.svg";
export { default as ProfileCurvey } from "./profile-curvey.svg";
export { default as ErrorCircleFilled } from "./error-circle-filled.svg";
export { default as RarTypesCommercial } from "./rar-types-commercial.svg";
export { default as RarTypesShipping } from "./rar-types-shipping.svg";
export { default as RarTypesWarrantly } from "./rar-types-warrantly.svg";
export { default as RarTypesCore } from "./rar-types-core.svg";
export { default as RarDeliveryError } from "./rar-delivery-error.svg";
export { default as Info } from "./info.svg";
export { default as ArrowRightNavigate } from "./arrow-right-navigate.svg";
export { default as ArrowDown } from "./arrow-down.svg";
export { default as ProductsViewGrid } from "./products-view-grid.svg";
export { default as ProductsViewList } from "./products-view-list.svg";
export { default as ProductsViewTable } from "./products-view-table.svg";
export { default as InputLabelInfo } from "./input-label-info.svg";
export { default as GoCoreLogo } from "./go-core-logo.svg";
export { default as DropFilled } from "./drop-filled.svg";
export { default as InfoError } from "./info-error.svg";
export { default as InfoWarning } from "./info-warning.svg";
