// Core
import { i18n } from "next-i18next";

// Definition
import { NotificationMessage } from "components/ui/Notification/Notification.types";

const httpErrorMessage: NotificationMessage = {
  title: "Unavailable",
  desc: "Something went wrong. Please, try again later",
};
const httpErrorMessageNotFound: NotificationMessage = {
  title: "Error",
  desc: "Article not found",
};
const httpErrorMessageCartProductUpdate: NotificationMessage = {
  title: "Error",
  desc: "Article does not match the car",
};
const httpSuccessMessageRemoveCartPreviewVehicle: NotificationMessage = {
  title: "Success",
  desc: "Vehicle removed",
};
export const httpSuccessMessageCartProductUpdate: NotificationMessage = {
  title: "Success",
  desc: "Product updated",
};
export const getHttpErrorMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errors.title") || httpErrorMessage.title,
  desc: i18n?.t("common:errors.desc") || httpErrorMessage.desc,
});
export const getHttpErrorMessageNotFoundProductWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errorsNotFoundProduct.title") || httpErrorMessageNotFound.title,
  desc: i18n?.t("common:errorsNotFoundProduct.desc") || httpErrorMessageNotFound.desc,
});
export const getHttpErrorMessageCartProductUpdateWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("cart:notifications.updateProductCartError.title") ||
    httpErrorMessageCartProductUpdate.title,
  desc:
    i18n?.t("cart:notifications.updateProductCartError.desc") ||
    httpErrorMessageCartProductUpdate.desc,
});
export const getHttpSuccessMessageRemoveCartPreviewVehicleWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("cart:notifications.removeVehicle.title") ||
      httpSuccessMessageRemoveCartPreviewVehicle.title,
    desc:
      i18n?.t("cart:notifications.removeVehicle.desc") ||
      httpSuccessMessageRemoveCartPreviewVehicle.desc,
  });
export const getHttpErrorMessageNotFoundCartProductUpdateWithTranslations =
  (): NotificationMessage => ({
    title: i18n?.t("cart:notifications.notFoundProduct.title") || httpErrorMessageNotFound.title,
    desc: i18n?.t("cart:notifications.notFoundProduct.desc") || httpErrorMessageNotFound.desc,
  });
export const getHttpSuccessMessageCartProductUpdateWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("cart:notifications.updateProductCart.title") ||
    httpSuccessMessageCartProductUpdate.title,
  desc:
    i18n?.t("cart:notifications.updateProductCart.desc") ||
    httpSuccessMessageCartProductUpdate.desc,
});
