// Core
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

// Components
import { Title } from "components/ui/Typography/Title";
import { ErrorReporter } from "./ErrorReporter";

export type ServerErrorProps = {
  statusCode: number;
};

export const ServerError = ({ statusCode }: ServerErrorProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const errorMsg = t("common:errorBoundary.title");
  const title = (
    <Title tag="h1" font="second" size="36">
      {errorMsg}
    </Title>
  );

  return (
    <ErrorReporter
      image={{ src: `/assets/images/error-${statusCode}.svg`, alt: errorMsg }}
      reset={router.reload}
      title={title}
      btnLabel={t("buttons:tryAgain")}
    />
  );
};
