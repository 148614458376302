// Core
import { Fragment, useMemo, MouseEvent } from "react";
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { WrapperDateDefault } from "components/common/WrapperDateDefault";
import { DecorDivider } from "components/common/DecorDivider";
import { IndicatorCircle } from "components/ui/IndicatorCircle";
import { SkeletonItems } from "components/ui/SkeletonItems";
import { Button } from "components/ui/Button";
import { NoData } from "components/ui/NoData";
import { Text } from "components/ui/Typography/Text";
import { Title } from "components/ui/Typography/Title";

// Hooks
import { useNotificationsFetch } from "bus/notifications/hooks/useNotificationsFetch";
import { useNotificationsViewAll } from "bus/notifications/hooks/useNotificationsViewAll";
import { useNotificationsTrackViewPreview } from "bus/notifications/hooks/useNotificationsTrackViewPreview";

// Definitions
import { NOTIFICATION_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import st from "./Styles.module.less";
import { rowGutters, dateFormatNotification } from "utils/constants";
import { triggerNotificationGtmEvent } from "bus/notifications/utils/notifications-gtm-events";

export const NotificationsNavbar = () => {
  const { t } = useTranslation();
  const { loadingNotificationsFetch, notifications } = useNotificationsFetch();
  const { onViewAllNotifications } = useNotificationsViewAll();
  const onShowAllNotifications = () => {
    triggerNotificationGtmEvent({
      actionType: NOTIFICATION_ACTION_TYPE.view_all_btn,
    });
    onViewAllNotifications();
  };
  useNotificationsTrackViewPreview();

  const notificationsNotRead = useMemo(
    () => notifications.filter((notif) => !notif.isRead).slice(0, 5),
    [notifications],
  );

  const onClickContainer = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (loadingNotificationsFetch) {
    return (
      <div className={st["popover-notification"]} onClick={onClickContainer}>
        {[1, 2, 3].map((_, index) => {
          return (
            <Fragment key={index}>
              <SkeletonItems items={1} active className={st["skeleton-content"]} />
              <SkeletonItems items={1} active className={st["skeleton-content-time"]} />
              <DecorDivider className={st["popover-notification-divider"]} />
            </Fragment>
          );
        })}
      </div>
    );
  }

  return (
    <div className={st["popover-notification"]} onClick={onClickContainer}>
      {!notificationsNotRead.length ? (
        <NoData />
      ) : (
        <div>
          {notificationsNotRead.map((notification, index) => (
            <div key={notification.id}>
              <Row gutter={rowGutters}>
                <Col span={5}>
                  <div className={st["popover-notification-item"]}>
                    <div className={st["popover-notification-indicator"]}>
                      <IndicatorCircle />
                    </div>
                    <div className={st.content}>
                      <div>
                        <Title tag="h5" size="14" color="gray-900" fontWeight="400">
                          {notification.title}
                        </Title>
                      </div>

                      <Text size="14" color="gray-700">
                        {notification.preview}
                      </Text>

                      <div className={st["popover-notification-time"]}>
                        <Text size="12" fontWeight="400" color="gray-600">
                          {WrapperDateDefault(
                            Number(notification.sentAt),
                            String(dateFormatNotification),
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {notifications.length > index + 1 && (
                <DecorDivider type="horizontal" gutter="small" />
              )}
            </div>
          ))}
          <div className={st["popover-notification-action-container"]}>
            <Button type="primary" ghost size="middle" onClick={onShowAllNotifications}>
              {t("buttons:viewAllNotifications")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
