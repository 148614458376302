// Core
import { useTranslation } from "next-i18next";

// Components
import { Skeleton } from "antd";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { WrapperDateDefault } from "components/common/WrapperDateDefault";
import { Icon } from "components/ui/Icon";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./styles.module.less";

const DATE_FORMAT_DELIVERY = "DD MMMM YYYY";

type CartDeliveryInfoProps = {
  title?: string;
  desc?: string;
  data?: number | null;
  loadingFetch?: boolean;
  loadingUpdate?: boolean;
};

export const CartDeliveryInfo = (props: CartDeliveryInfoProps) => {
  const { title, data = null, desc, loadingFetch, loadingUpdate } = props;
  const { i18n } = useTranslation();

  return (
    <div className={st["delivery-info"]} data-test-id="delivery-info">
      <div className={st.date} data-test-id="delivery-info-date">
        {title && (
          <Text tag="p" size="16" fontWeight="600" color="gray-900">
            {title}
          </Text>
        )}
        <>
          {loadingFetch || loadingUpdate ? (
            <Skeleton.Input active />
          ) : (
            <Text tag="div" size="16" fontWeight="600" color="gray-900">
              {WrapperDateDefault(data, DATE_FORMAT_DELIVERY, i18n.language)}
            </Text>
          )}
        </>
      </div>
      {desc && (
        <div className={st["delivery-info-message"]} data-test-id="delivery-info-message">
          <span className={st["delivery-info-message-icon"]}>
            <Icon name="ExclamationCircleOutlined" />
          </span>
          <Text tag="div" color="gray-500" alignmentVertical="middle">
            {WrapperNodeDefault(desc)}
          </Text>
        </div>
      )}
    </div>
  );
};
