// Core
import { Trans, useTranslation } from "next-i18next";

// Components
import { Link } from "components/common/Link";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";

// Hooks
import { usePersonalManagerFaqFetch } from "client/core/personal-manager/hooks/use-personal-manager-faq-fetch";

// Utils
import st from "./styles.module.less";
import { book } from "init/book";

const AccordionDynamic = DynamicImport(import("components/ui/Accordion/Collapse"), "Accordion");
const AccordionPanelDynamic = DynamicImport(import("components/ui/Accordion/Panel"), "Panel");
const Accordion = Object.assign(AccordionDynamic, { Panel: AccordionPanelDynamic });

export const PersonalManagerFAQ = () => {
  const { t } = useTranslation();
  const { personalManagerFaq, loadingPersonalManagerFaq } = usePersonalManagerFaqFetch();

  return (
    <div className={st.faq}>
      <div className={st["faq-title"]}>
        <Title tag="h4">{t("personal-manager:faq.title")}</Title>
      </div>
      <div className={st["faq-content"]}>
        <Accordion isLoading={loadingPersonalManagerFaq} skeletonCount={6} expandIconPosition="end">
          {personalManagerFaq.items.map((item, itemIdx) => {
            return (
              <Accordion.Panel
                header={{
                  title: t(item.faqTitleBlock),
                  type: "personal-faq",
                  titleSize: "large",
                }}
                key={itemIdx}
                compact
              >
                {!!item.content &&
                  item.content.map((block, blockIdx) => {
                    return (
                      <div className={st["faq-block-content"]} key={blockIdx}>
                        <Title tag="h5" size="14" fontWeight="600">
                          <>
                            {blockIdx + 1}. {t(block.title)}
                          </>
                        </Title>
                        <div className={st["faq-block-desc"]}>
                          <Text size="14" fontWeight="400">
                            <Trans
                              i18nKey={block.desc}
                              components={[
                                <Link key={book.benefits} href={book.benefits} />,
                                <Link key={book.returns} href={book.returns} />,
                                <Link key={book.termConditions} href={book.termConditions} />,
                              ]}
                            />
                          </Text>
                        </div>
                      </div>
                    );
                  })}
              </Accordion.Panel>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};
