// Core
import { useTranslation } from "next-i18next";

// Components
import { Grid, Space } from "antd";
import { Button } from "components/ui/Button";

// Definitions
import type { ButtonsBecomeType } from "./ButtonsBecome.types";

// Utils
import { book } from "init/book";
import st from "./Styles.module.less";
import { routerPush } from "utils/router";

type ButtonsBecomePropsType = {
  type?: ButtonsBecomeType;
  testId?: string;
};

export const ButtonsBecome = ({ type, testId }: ButtonsBecomePropsType) => {
  const { t } = useTranslation();
  const { md = true } = Grid.useBreakpoint();

  if (!type) {
    return null;
  }

  const handleNavigate = () => {
    void (async () => {
      await routerPush(book[type]);
    })();
  };

  const btnSize = !md ? "small" : "large";
  const specificTestId = testId ? `${testId}-${type === "signin" ? type : "registration"}` : "";
  const btnI18n = type === "signin" ? "buttons:haveLoginAndPass" : "buttons:registration";

  return (
    <div className={st.become} data-test-id={testId}>
      <Space size={12}>
        <Button
          mode="custom"
          type="primary"
          size={btnSize}
          testId={specificTestId}
          onClick={handleNavigate}
        >
          {t(btnI18n)}
        </Button>
      </Space>
    </div>
  );
};
