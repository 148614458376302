// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";
import type { CartPreviewType } from "client/core/cart/models";

// Domains
import { cartKeys, initialCartState } from "client/core/cart/store";

// Utils
import { cartService } from "client/core/cart/service";
import { queryClient } from "init/queryClient";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type UseCallbackUpdateCartPreviewProductAlternative = (data: {
  articleId: number | null;
}) => Promise<[null, CartPreviewType] | [HttpErrorType]>;

type UseCartPreviewProductAlternativeUpdate = {
  loadingCartPreviewProductAlternativeUpdateMutation: boolean;
  onUpdateCartPreviewProductAlternativeMutation: UseCallbackUpdateCartPreviewProductAlternative;
};

export const useCartPreviewProductAlternativeUpdateMutation = (
  id: number,
  keyId: number,
): UseCartPreviewProductAlternativeUpdate => {
  const queryKey = [cartKeys.productsAlternatives, { keyId, id }];

  const updateCartPreviewProductAlternativeProps = useMutationAsync<
    CartPreviewType,
    { articleId: number | null },
    CartPreviewType
  >({
    key: queryKey,
    name: "updateCartPreviewProductAlternativeMutation",
    fetcher: cartService.updateCartPreviewProductAlternative,
    fetcherPayload: { id },
    options: {
      onSuccess: (data) => {
        if (!data) return;

        const queryCartKey = [cartKeys.preview];
        queryClient.setQueryData<CartPreviewType>(queryCartKey, (prevState) => {
          if (!prevState?.tabs.length) {
            return initialCartState.preview;
          }

          const tabs = prevState.tabs.map((tab, indexTab) => {
            const products = data.tabs[indexTab].products;

            return {
              ...tab,
              deliveryDate: data.tabs[indexTab].deliveryDate,
              selected: data.tabs[indexTab].selected,
              products,
            };
          });

          return {
            ...prevState,
            totalPrice: data.totalPrice,
            totalPriceVat: data.totalPriceVat,
            vatPrice: data.vatPrice,
            vatPercentage: data.vatPercentage,
            deliveryDate: data.deliveryDate,
            selected: data.selected,
            checkoutAvailable: data.checkoutAvailable,
            tabs,
          };
        });
      },
      onError: () => void 0,
    },
  });

  const onUpdate = useCallback<UseCallbackUpdateCartPreviewProductAlternative>(async (data) => {
    try {
      const updatedData = await updateCartPreviewProductAlternativeProps.mutateAsync(data);

      return [null, updatedData];
    } catch (err) {
      return [err as HttpErrorType];
    }
  }, []);

  return {
    loadingCartPreviewProductAlternativeUpdateMutation:
      updateCartPreviewProductAlternativeProps.isLoading,
    onUpdateCartPreviewProductAlternativeMutation: onUpdate,
  };
};
