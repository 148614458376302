// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadAddToCartType,
  EventAddToCartDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  EcommerceEnum,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
  AFFILIATION_TYPE,
} from "client/utils/gtm/gtm.types";

class AddToCartEvent extends AbstractGTMEventSegment<GTMTypesEnum.add_to_cart> {
  protected readonly segmentShape: EventPayloadAddToCartType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.catalog,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadAddToCartType {
    const { route, carId, categoryId, subCategoryId, product, quantity, itemListId } =
      data as EventAddToCartDataType;
    const { currency, priceVat } = super.getPriceConfig(String(product.priceNonVat?.country));
    const price = Number(priceVat ? product.vatPrice?.value : product.priceNonVat?.value);
    const itemVariant = product.isVinCompatibility
      ? EcommerceProductVariantsEnum.vin_compitable
      : EcommerceProductVariantsEnum.vin_incompitable;
    const segment = this.getSegment();

    if (route) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    }
    if (carId) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.car_id] = carId;
    }

    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(product.deliveryAt);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = currency;
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] = price * quantity;
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items][0] = {
      [EcommerceItemsEnum.item_id]: Number(product.articleId),
      [EcommerceItemsEnum.item_name]: String(product.name),
      [EcommerceItemsEnum.item_brand]: String(product.brand?.name),
      [EcommerceItemsEnum.price]: price,
      [EcommerceItemsEnum.quantity]: Number(product.quantity?.value),
      [EcommerceItemsEnum.item_variant]: itemVariant,
      [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
      [EcommerceEnum.item_list_id]: itemListId,
      ...(categoryId && { [EcommerceItemsEnum.item_category]: categoryId }),
      ...(subCategoryId && { [EcommerceItemsEnum.item_category2]: subCategoryId }),
      ...(product.genericId && { [EcommerceItemsEnum.item_category3]: product.genericId }),
    };

    return segment;
  }
}

export const addToCartEvent: AddToCartEvent = new AddToCartEvent();
