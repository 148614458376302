// Core
import { ReactNode, ElementType } from "react";
import cx from "classnames";

// Definitions
import type { BadgeProps } from "components/ui/Badge";
import type { PopoverLightListProps } from "components/ui/PopoverLightList";
import type { LinkProps } from "components/ui/Link";

// Components
import { Skeleton } from "antd";
import { Icon } from "components/ui/Icon";
import { Link } from "components/ui/Link";
import { Badge } from "components/ui/Badge";
import { PopoverLightList } from "components/ui/PopoverLightList";

// Utils
import st from "./Styles.module.less";

const LightListItemBase = (props: { children: ReactNode; loading?: boolean }) => {
  const { children, loading } = props;
  const listItemStyle = cx(st["light-list-item"], {
    [st["light-list-item-loading"]]: Boolean(loading),
  });
  if (loading) {
    return (
      <li className={listItemStyle}>
        <Skeleton.Input active size="small" />
      </li>
    );
  }
  return <li className={listItemStyle}>{children}</li>;
};
const LightListItemLink = (linkProps: {
  children: LinkProps["children"];
  link?: LinkProps & { component?: ElementType };
}) => {
  const { link, children } = linkProps;
  if (!link) {
    return <>{children}</>;
  }
  const { component: ItemLink = Link, href, ...otherLinkProps } = link;
  return (
    <ItemLink href={String(href)} {...otherLinkProps}>
      {children}
    </ItemLink>
  );
};
const LightListItemBadge = (badgeProps: {
  children: BadgeProps["children"];
  loading?: boolean;
  badge?: BadgeProps | null;
}) => {
  const { loading, badge = null, children = "" } = badgeProps;

  if (!badge) {
    return <>{children}</>;
  }

  if (loading) {
    return (
      <Badge {...badge} count={<Skeleton.Avatar size="small" shape="circle" />}>
        {children}
      </Badge>
    );
  }

  return <Badge {...badge}>{children}</Badge>;
};
const LightListItemPopover = (popoverProps: {
  children: PopoverLightListProps["children"];
  popover?: PopoverLightListProps["config"];
}) => {
  const { popover, children } = popoverProps;
  if (!popover) {
    return <>{children}</>;
  }
  return <PopoverLightList config={popover}>{children}</PopoverLightList>;
};

export type LightListItemType = {
  key: string;
  text?: ReactNode;
  icon?: string;
  link?: LinkProps & {
    component?: ElementType;
  };
  badge?: BadgeProps;
  popover?: PopoverLightListProps["config"];
  loading?: boolean;
  type?: "actions";
  handleTrackClick?: () => void;
};
const LightListItem = (props: LightListItemType) => {
  const { type, text, icon, link, badge, popover, loading, handleTrackClick } = props;

  const itemIconStyle = cx(st["light-list-item-icon"], {
    [st["light-list-item-icon-actions"]]: type === "actions",
  });

  const handleTrackItemClick = () => {
    handleTrackClick?.();
  };

  const contentJSX = (
    <div onClick={handleTrackItemClick}>
      {icon && <Icon name={icon} size="small" className={itemIconStyle} />}
      {text && <>{text}</>}
    </div>
  );
  return (
    <LightListItemBase loading={loading && !badge}>
      <LightListItemLink link={link}>
        <LightListItemBadge badge={badge} loading={loading}>
          <LightListItemPopover popover={popover}>{contentJSX}</LightListItemPopover>
        </LightListItemBadge>
      </LightListItemLink>
    </LightListItemBase>
  );
};

export type LightListProps = {
  mode?: "vertical" | "horizontal";
  items?: LightListItemType[];
  type?: "actions";
  testId?: string;
};
export const LightList = (props: LightListProps) => {
  const { mode = "horizontal", items = [], type, testId } = props;

  const listStyle = cx(st["light-list"], {
    [st["light-list-vertical"]]: mode === "vertical",
    [st["light-list-horizontal"]]: mode === "horizontal",
  });

  return (
    <ul className={listStyle} data-test-id={testId}>
      {items.map((itemProps) => {
        const { key, ...otherProps } = itemProps;
        return <LightListItem key={key} type={type} {...otherProps} />;
      })}
    </ul>
  );
};
