// Definitions
import type { PropsWithChildren, ReactElement, ReactNode } from "react";
import type { DrawerProps as DrawerAntProps } from "antd/lib/drawer";

// Components
import { Drawer as DrawerAnt } from "antd";

// Utils
import { getDrawerWidth } from "./utils";

import st from "./Styles.module.less";

type DrawerProps = PropsWithChildren<
  DrawerAntProps & {
    children: ReactElement;
    width?: string | number;
    title?: ReactNode;
    placement?: "top" | "bottom" | "right" | "left";
    onClose?: () => void;
    visible: boolean;
    closable?: boolean;
  }
>;

export const Drawer = (props: DrawerProps) => {
  const {
    children,
    title,
    width,
    placement = "right",
    visible,
    closable,
    onClose,
    ...rest
  } = props;
  const drawerWidth = width ? width : getDrawerWidth();

  return (
    <DrawerAnt
      title={title}
      width={drawerWidth}
      placement={placement}
      onClose={onClose}
      open={visible}
      closable={closable}
      className={st.drawer}
      {...rest}
    >
      {children}
    </DrawerAnt>
  );
};
