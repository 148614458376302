// Core
import {
  HttpServerErrorCode,
  ServerErrorFormatType,
  ServerErrorRawFormatType,
  ServerErrorFieldFormatType,
  ServerErrorValidationFieldFormatType,
} from "models/Http";

export enum HttpTypesEnum {
  UNAUTHORIZED = "Unauthorized",
  FORBIDDEN = "Forbidden",
  SERVER_ERROR = "InternalServerError",
}

export class HttpError extends Error {
  public readonly status: number | string | undefined;

  public readonly errors?: ServerErrorValidationFieldFormatType | ServerErrorFieldFormatType | null;

  public readonly errorCode?: HttpServerErrorCode;

  public readonly hasError?: boolean;

  constructor(status: number, errorShape: ServerErrorFormatType | ServerErrorRawFormatType) {
    super();
    this.name = errorShape.type || "HttpError";
    this.message = errorShape.message || "";
    this.status = status;
    this.errors = errorShape.errors;
    this.errorCode = errorShape.errorCode;
    this.hasError = errorShape.hasError || true;
    Object.setPrototypeOf(this, new.target.prototype);
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      status: this.status,
      errors: this.errors,
      errorCode: this.errorCode,
      hasError: this.hasError,
      stacktrace: this.stack,
    };
  }
}

export const HTTP_ERROR_SERVER = (value = "") => ({
  type: HttpTypesEnum.SERVER_ERROR,
  message: `Internal server error in "${value}" service`,
});
