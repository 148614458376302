// Core
import escapeStringRegexp from "escape-string-regexp";

// Components
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./styles.module.less";

type SearchBarOptionProps = {
  label: string;
  keyword: string;
};

export const SearchBarOption = ({ label, keyword = "" }: SearchBarOptionProps) => {
  const regexp = new RegExp(escapeStringRegexp(keyword), "i");
  const firstKeyIndex = label.search(regexp);
  const lastKeyIndex = firstKeyIndex + keyword.length;
  const keyStr = firstKeyIndex >= 0 ? label.substring(firstKeyIndex, lastKeyIndex) : "";
  const firstStr = firstKeyIndex >= 0 ? label.substring(0, firstKeyIndex) : "";
  const lastStr = firstKeyIndex >= 0 ? label.substring(lastKeyIndex, label.length) : label;

  return (
    <div className={st.label}>
      <Text color="gray-950">
        <>
          {firstStr}
          {keyStr && <span className={st.keyword}>{keyStr}</span>}
          {lastStr}
        </>
      </Text>
    </div>
  );
};
