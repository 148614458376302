// Core
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Footer } from "components/common/Footer";
import { Logo } from "components/common/Logo";
import { TecDocLogo } from "components/common/TecDocLogo";
import { FooterCopyrite } from "components/common/FooterCopyrite";
import { FooterMenuPublic } from "components/common/FooterMenuPublic";
import { UserCountrySelectorFooter } from "components/common/UserCountrySelectorFooter";
import { Title } from "components/ui/Typography/Title";
import { Button } from "components/ui/Button";

// Definitions
import { FOOTER_MENU_ITEM } from "client/utils/gtm/gtm.types";

// Utils
import { rowGutters } from "utils/constants";
import { triggerFooterMenuGtmEvent } from "utils/footer-gtm-events";
import st from "./Styles.module.less";

// Hooks
import { useConsentCookiesReset } from "client/core/consent-cookies/hooks/use-consent-cookies-reset";

type LayoutFooterPublicType = {
  hasCountrySelector?: boolean;
  hasNavigation?: boolean;
};
export const LayoutFooterPublic = ({
  hasCountrySelector,
  hasNavigation = true,
}: LayoutFooterPublicType) => {
  const { t } = useTranslation();
  const { resetCookie } = useConsentCookiesReset();
  const handleResetCookies = () => {
    triggerFooterMenuGtmEvent({
      actionType: FOOTER_MENU_ITEM.cookie,
    });
    setTimeout(() => resetCookie(), 1000);
  };

  return (
    <Footer testId="footer">
      <Row gutter={rowGutters} className={st["footer-top"]}>
        <Col xl={6} md={8} xs={24}>
          <div className={st["footer-logo"]}>
            <Logo alt={t("common:firstPartPageTitle")} testId="logo-footer" />
            {hasCountrySelector && (
              <div className={st["footer-selector-country"]}>
                <Title
                  tag="h5"
                  size="16"
                  fontWeight="600"
                  color="blue-600"
                  transform="uppercase"
                  className={st["footer-selector-title"]}
                >
                  {t("common:countrySelect")}
                </Title>
                <UserCountrySelectorFooter testId="user-country-selector-footer" />
              </div>
            )}
          </div>
          <Button
            btnType="navigation-on-dark"
            onClick={handleResetCookies}
            size="large"
            data-kmtx-cookies-popup-rerun
          >
            {t("cookies:consentCookies.buttons.resetCookies")}
          </Button>
        </Col>
        {hasNavigation && (
          <Col xl={18} xs={24}>
            <FooterMenuPublic />
          </Col>
        )}
      </Row>

      <Row gutter={rowGutters} align="middle" className={st["footer-middle"]}>
        <Col span={24}>
          <TecDocLogo testId="tec-doc-logo-footer" copyright={t("common:tecDoc.copyright")} />
        </Col>
      </Row>

      <Row gutter={rowGutters} align="middle" className={st["footer-bottom"]}>
        <Col span={24}>
          <FooterCopyrite />
        </Col>
      </Row>
    </Footer>
  );
};
