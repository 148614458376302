// Core
import cx from "classnames";

// Utils
import st from "./Styles.module.less";
import { getFormatIconName } from "./utils";

// Assets
import { default as iconSpriteStaticImg } from "./assets/sprite.svg";
import * as IconsAll from "./assets/icons";

type IconProps = {
  name: string;
  rotate?: boolean;
  className?: string;
  size?: "mini" | "small" | "middle" | "large" | "extraLarge" | "fullWidth";
  testId?: string;
  onClick?: () => void;
  gutter?: "mr-small";
};

const iconSprite = iconSpriteStaticImg as { src: string; width: number; height: number };
const iconSpriteUrl = iconSprite.src;

export const Icon = (props: IconProps) => {
  const { name, size, rotate, testId, gutter, className, ...otherProps } = props;
  const iconStyle = cx(
    "anticon",
    st.icon,
    {
      [st.mini]: size === "mini",
      [st.small]: size === "small",
      [st.middle]: size === "middle",
      [st.large]: size === "large",
      [st["extra-large"]]: size === "extraLarge",
      [st["full-width"]]: size === "fullWidth",
      [st.rotate]: rotate,
    },
    gutter && { [st[gutter]]: gutter },
    className,
  );

  const hasName = name in IconsAll;

  if (!hasName) {
    console.warn(`The icon ${name} does not exist in the list`);
    return null;
  }
  const iconFileName = getFormatIconName(name);

  return (
    <span
      className={iconStyle}
      onClick={props.onClick}
      role="img"
      data-test-id={testId}
      {...otherProps}
    >
      <svg width="1em" height="1em" fill="currentColor">
        <use xlinkHref={`${iconSpriteUrl}#${iconFileName}`} />
      </svg>
    </span>
  );
};
