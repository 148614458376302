// Core
import { z } from "zod";

// Definitions
import type { HttpRequestParams } from "../../models/Http";

export enum UserCountryCodesEnum {
  en = "en",
  fr = "fr",
  de = "de",
  at = "at",
  nl = "nl",
}

export enum UserLanguagesEnum {
  en = "en",
  fr = "fr",
  de = "de",
  nl = "nl",
}

export const userCountryItemSchema = z
  .object({
    id: z.number(),
    value: z.nativeEnum(UserCountryCodesEnum),
    label: z.string(),
    icon: z.string().optional(),
    name: z.string().optional(),
  })
  .nullable();

export type UserCountryItemType = z.infer<typeof userCountryItemSchema>;
export type UserCountryCodesKeys = keyof typeof UserCountryCodesEnum;
export type UserLanguagesKeys = keyof typeof UserLanguagesEnum;

export type UserCountrySelectorType = {
  country: UserCountryItemType;
};

const UserCallBackParamsScheme = z.object({
  id: z.number().optional(),
  fullName: z.string(),
  phoneNumber: z.string(),
  phonePrefix: z
    .object({
      id: z.number(),
      value: z.string(),
      label: z.string(),
      icon: z.string().optional(),
      options: z
        .array(
          z.object({
            id: z.number(),
            value: z.string(),
            label: z.string(),
          }),
        )
        .optional(),
    })
    .nullable(),
  email: z.string(),
});

export type UserCallBackType = z.infer<typeof UserCallBackParamsScheme>;
export type UserCallBackParamsType = HttpRequestParams<UserCallBackType>;

const UserGeoSchema = z
  .object({
    country: z.nativeEnum(UserCountryCodesEnum),
    language: z.nativeEnum(UserLanguagesEnum),
  })
  .nullable();
export type UserGeoType = z.infer<typeof UserGeoSchema>;

const UserStoredShema = z.nativeEnum(UserCountryCodesEnum).optional();
export type UserStoredType = z.infer<typeof UserStoredShema>;
