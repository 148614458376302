// Components
import { CartNavigation } from "client/core/cart/components/cart-navigation";
import { CartContentLayout } from "client/core/cart/components/cart-content-layout";
import { CartServices } from "client/core/cart/components/cart-services";
import { CartPreview } from "client/core/cart/components/cart-preview";

export const CartContent = () => {
  return (
    <CartContentLayout testId="cart-content">
      <CartNavigation step={0} />
      <CartContentLayout>
        <CartPreview />
      </CartContentLayout>
      <CartServices />
    </CartContentLayout>
  );
};
