// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventSelectItemDataType,
  EventsDataUnionType,
  EventPayloadProductItemType,
  EventSelectItemPayloadType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  EcommerceEnum,
  ITEM_LIST_ID_TYPE,
  AFFILIATION_TYPE,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
} from "client/utils/gtm/gtm.types";

class SelectItemEvent extends AbstractGTMEventSegment<GTMTypesEnum.select_item> {
  protected readonly segmentShape: EventSelectItemPayloadType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.shipping_date]: 0,
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.product,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.item_list_id]: ITEM_LIST_ID_TYPE["catalog-items"],
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventSelectItemPayloadType {
    const { route, carId, productData, categoryId, subCategoryId, itemListId } =
      data as EventSelectItemDataType;
    const segment = this.getSegment();

    if (route) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    }
    if (carId) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.car_id] = carId;
    }

    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(
      productData.deliveryAt,
    );
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.item_list_id] = itemListId;

    const { priceVat } = super.getPriceConfig(String(productData.priceNonVat?.country));
    const price = Number(priceVat ? productData.vatPrice?.value : productData.priceNonVat?.value);
    const itemVariant = productData.isVinCompatibility
      ? EcommerceProductVariantsEnum.vin_compitable
      : EcommerceProductVariantsEnum.vin_incompitable;

    const productGtmData: EventPayloadProductItemType = {
      [EcommerceItemsEnum.item_id]: Number(productData.articleId),
      [EcommerceItemsEnum.item_name]: productData.name,
      [EcommerceItemsEnum.item_brand]: String(productData.brand?.name),
      [EcommerceItemsEnum.price]: price,
      [EcommerceItemsEnum.quantity]: Number(productData.quantity?.value),
      [EcommerceItemsEnum.item_variant]: itemVariant,
      [EcommerceItemsEnum.item_list_id]: itemListId,
      [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
      ...(categoryId && { [EcommerceItemsEnum.item_category]: categoryId }),
      ...(subCategoryId && { [EcommerceItemsEnum.item_category2]: subCategoryId }),
      ...(productData.genericId && { [EcommerceItemsEnum.item_category3]: productData.genericId }),
    };

    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items][0] = productGtmData;

    return segment;
  }
}

export const selectItemEvent: SelectItemEvent = new SelectItemEvent();
