// Definitions
import type { Dayjs } from "dayjs";
import type { SelectItem } from "components/ui/FormFields/InputSelect/InputSelect.types";

// Data
import { countryIcons } from "client/core/personal-manager/data/locales";

const mapItemWithIcon = (item?: SelectItem, locale?: string): SelectItem => {
  const localeItem = countryIcons.find((el) => !!locale && el.value === locale);

  return item
    ? {
        ...item,
        ...(localeItem?.icon && {
          icon: localeItem.icon,
        }),
      }
    : null;
};
export const mapCountriesToIcons = (items: SelectItem[]): SelectItem[] =>
  items.map((item) => mapItemWithIcon(item, String(item?.value)));

export const getCountryItemByLocale = (items: SelectItem[], locale: string): SelectItem => {
  const countryItem = items.find((el) => el?.value === locale.toLocaleLowerCase());
  return mapItemWithIcon(countryItem, String(countryItem?.value));
};

export const disabledOutLimitPickerTime =
  (minTime?: Dayjs, maxTime?: Dayjs) => (current: Dayjs | null) => {
    if (!current) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }

    const minutes = [...Array(60).keys()];
    const hours = [...Array(24).keys()];
    const currentDayStart = current.clone().startOf("day");
    const minDayStart = minTime?.clone().startOf("day");
    const currentDayEnd = current.clone().endOf("day");
    const maxDayEnd = maxTime?.clone().endOf("day");

    if (
      (minTime && minDayStart && currentDayStart.isBefore(minDayStart)) ||
      (maxTime && maxDayEnd && currentDayEnd.isAfter(maxDayEnd))
    ) {
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
      };
    }

    const conditionDay =
      (minTime && current.isSame(minTime, "day")) || (maxTime && current.isSame(maxTime, "day"));

    return {
      disabledHours: () =>
        !conditionDay
          ? []
          : hours.filter(
              (hour) => (maxTime && hour > maxTime.hour()) || (minTime && hour < minTime.hour()),
            ),
      disabledMinutes: () =>
        !conditionDay
          ? []
          : (minTime && current.hour() !== minTime?.hour()) ||
              (maxTime && current.hour() !== maxTime?.hour())
            ? []
            : minutes.filter(
                (minute) =>
                  (maxTime && minute > maxTime.minute()) || (minTime && minute < minTime.minute()),
              ),
    };
  };

export const disabledOutLimitPickerDate =
  (minDate?: Dayjs, maxDate?: Dayjs) =>
  (current: Dayjs): boolean => {
    const isBeforeMin = minDate ? current.isBefore(minDate.startOf("day")) : false;
    const isAfterMax = maxDate ? current.isAfter(maxDate.startOf("day")) : false;

    return current && (isBeforeMin || isAfterMax);
  };
