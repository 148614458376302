// Components
import { Control, Controller } from "react-hook-form";
import { InputAutoComplete } from "components/ui/FormFields/InputAutoComplete";

// Definitions
import type { InputAutoCompletePropsType } from "components/ui/FormFields/InputAutoComplete";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormAutocompleteType = InputAutoCompletePropsType & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormAutocomplete = (props: WrappedFormAutocompleteType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputAutoComplete {...rest} {...field} ref={field.ref} />}
    />
  );
};
