// Core
import { useEffect } from "react";

// Definitions
import type { ProfileType } from "client/core/profile/models";
import type { UserGeoType } from "bus/user/models";

type UseKmtxVariablesProps = {
  profile: ProfileType | null;
  geo?: UserGeoType;
};

export const useKmtxVariables = ({ profile, geo }: UseKmtxVariablesProps) => {
  useEffect(() => {
    window.JsVars = { client_id: profile?.customerId, geo_country_code: geo?.country };
  }, [profile, geo]);
};
