// Core
import Router from "next/router";

// Utils
import { book } from "init/book";

export const errorHandler404 = async () => {
  await Router.replace(book.notFound);
};

export const componentError = (error: Error) => {
  throw error;
};
