// Core
import { notification as AntdNotification } from "antd";

// Definitions
import {
  IconType as NotificationIconType,
  ArgsProps as NotificationArgsProps,
} from "antd/lib/notification";
import type {
  NotificationMessage,
  NotificationMessageTitle,
  NotificationMessageDesc,
} from "./Notification.types";

// Utils
import st from "./Styles.module.less";
import { NOTIFICATION_DURATION, NOTIFICATION_MAX_COUNT } from "./utils";

const baseConfig = {
  className: st.notification,
  duration: NOTIFICATION_DURATION,
  maxCount: NOTIFICATION_MAX_COUNT,
};

const MessageTitle = (props: NotificationMessageTitle) => {
  const { type = "info", text } = props;
  return <span className={`${st.title} ${st[type]}`}>{text}</span>;
};

const MessageDesc = ({ text }: NotificationMessageDesc) => {
  return <span className={st.desc}>{text}</span>;
};

const customView = (
  { title, desc }: NotificationMessage,
  type: NotificationIconType = "info",
): NotificationArgsProps => {
  return {
    ...baseConfig,
    message: title ? <MessageTitle type={type} text={title} /> : null,
    description: <MessageDesc text={desc} />,
  };
};

export const notificationMessages = {
  customView,
};

export const notification = AntdNotification;
