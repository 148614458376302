// Core
import { z } from "zod";

export enum FormCartPreviewEnum {
  selected = "selected",
}
const initialCartPreviewForm = {
  selected: "empty",
};
export const cartPreviewForm = {
  shape: initialCartPreviewForm,
  schema: () => {
    return z.object({
      [FormCartPreviewEnum.selected]: z.string().trim(),
    });
  },
};
