// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadNotificationType,
  EventNotificationDataType,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  NOTIFICATION_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class NotificationEvent extends AbstractGTMEventSegment<GTMTypesEnum.notification> {
  protected readonly segmentShape: EventPayloadNotificationType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: "" as keyof typeof NOTIFICATION_ACTION_TYPE,
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadNotificationType {
    const { actionType } = data as EventNotificationDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;

    return segment;
  }
}

export const notificationEvent: NotificationEvent = new NotificationEvent();
