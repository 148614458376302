// Components
import { Control, Controller } from "react-hook-form";
import { InputTextArea } from "components/ui/FormFields/InputTextArea";

// Definitions
import type { TextAreaProps } from "antd/lib/input";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormCheckboxType = TextAreaProps & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormTextArea = (props: WrappedFormCheckboxType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <InputTextArea {...rest} {...field} ref={field.ref} />;
      }}
    />
  );
};
