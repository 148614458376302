// Components
import { Text } from "components/ui/Typography/Text";

// Utils
import { PROJECT_LABEL_HREF } from "utils/constants";
import st from "./Styles.module.less";

export const FooterCopyrite = () => {
  return (
    <div className={st["footer-copyrite"]}>
      <Text box="full" alignmentVertical="middle" alignmentHorizontal="center" color="blue-700">
        <>
          <span>&#169;&nbsp;</span>
          <span>{`${new Date().getFullYear()} ${PROJECT_LABEL_HREF}`}</span>
        </>
      </Text>
    </div>
  );
};
