// Core
import { ReactNode } from "react";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Button } from "components/ui/Button";
import { Image } from "components/ui/Image";

// Utils
import st from "./Styles.module.less";

export type StandardErrorReporterProps = {
  image?: { src: string; alt: string };
  reset: () => void;
  title: ReactNode;
  description?: ReactNode;
  btnLabel: string;
};

export const StandardErrorReporter = ({
  image,
  reset,
  title,
  description = null,
  btnLabel,
}: StandardErrorReporterProps) => {
  return (
    <Row
      justify="space-around"
      align="middle"
      gutter={Row.gutterType.md.horizontal}
      className={st["error-fallback"]}
      data-test-id="error-fallback"
    >
      <Col
        xs={{ span: 24, order: 1 }}
        md={{ span: 12, order: 0 }}
        lg={{ span: 8, offset: 2, order: 0 }}
      >
        <div className={st["error-message"]}>{title}</div>

        {description && <div className={st.description}>{description}</div>}
        <Button block size="large" type="primary" onClick={reset}>
          {btnLabel}
        </Button>
      </Col>
      {image && (
        <Col
          xs={{ span: 24, order: 0 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 8, offset: 2, order: 0 }}
        >
          <div className={st.illustration}>
            <Image src={image.src} alt={image.alt} layout="fill" priority />
          </div>
        </Col>
      )}
    </Row>
  );
};
