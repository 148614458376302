// Core
import winston from "winston";

// Utils
import { verifyEnvironment } from "utils/verify-environment";

export const serverProductionRestLogger = winston.createLogger({
  transports: [
    // TODO: в качестве транспорта использовать Sentry
    new winston.transports.Http({
      // host: process.env.NEXT_PUBLIC_HOST,
      // port: process.env.NEXT_PUBLIC_PORT,
      // path: "/api/logs/rest",
    }),
  ],
  format: winston.format.json(),
  silent: verifyEnvironment().isProduction || verifyEnvironment().isStaging,
});
