// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  EventPayloadAuthorizationType,
  EventAuthorizationDataType,
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class AuthorizationEvent extends AbstractGTMEventSegment<GTMTypesEnum.authorization> {
  protected readonly segmentShape = null;

  makeSegment(data: EventsDataUnionType): EventPayloadAuthorizationType {
    const authorizationData = data as EventAuthorizationDataType;
    const segment = this.getSegment();

    return {
      [PayloadKeysEnum.parameters]: {
        ...segment?.[PayloadKeysEnum.parameters],
        ...(authorizationData[ParametersEnum.user_id] && {
          [ParametersEnum.user_id]: authorizationData[ParametersEnum.user_id],
        }),
        ...(authorizationData[ParametersEnum.visitor_type] && {
          [ParametersEnum.visitor_type]: authorizationData[ParametersEnum.visitor_type],
        }),
        ...(authorizationData[ParametersEnum.authorization_type] && {
          [ParametersEnum.authorization_type]: authorizationData[ParametersEnum.authorization_type],
        }),
        ...(authorizationData[ParametersEnum.error_type] && {
          [ParametersEnum.error_type]: authorizationData[ParametersEnum.error_type],
        }),
      },
    };
  }
}

export const authorizationEvent: AuthorizationEvent = new AuthorizationEvent();
