// Core
import { i18n, TFunction } from "next-i18next";
import dayjs, { Dayjs } from "dayjs";
import { keys, mergeDeepWith } from "ramda";
import QS from "qs";

// Definition
import type { TOptions } from "i18next";
import type { ApiErrorType, ServerErrorFormatType, ServerErrorRawFormatType } from "models/Http";
import type { VehicleTabInnerPageKeysType, VehicleTabInnerPageType } from "bus/vehicleTabs/models";
import type { MenuData } from "data/footer";

// Utils
import { ERRORS_VALIDATION_KEY } from "utils/constants";
import { mountingSides } from "data/mountingSides";
import { logger } from "utils/logger";
import { QS_QUERY_CONFIG } from "utils/constants";

export const composeGmailLink = (mailTo: string): string => {
  return `https://mail.google.com/mail/u/0/?view=cm&source=mailto&to=${mailTo}`;
};

export const checkTypeApiError = (value: unknown) => value instanceof Object && "error" in value;

export const isApiError = (
  value: unknown,
  status: number | null = null,
  route = "",
): value is ApiErrorType => {
  status &&
    logger.browserReactQueryInformationLogger.info("API error. status:", status, "route:", route);
  return checkTypeApiError(value);
};

export const getUniqueID = (): string => {
  const chr4 = () => Math.random().toString(16).slice(-4);
  return `${chr4()}-${chr4()}-${chr4()}`;
};

export const getFormatDate = (value: number, format: string): string => {
  return dayjs(value).isValid() ? dayjs(value).format(format) : "";
};

export const getFormatErrorValidation = (
  error: ServerErrorRawFormatType,
): ServerErrorFormatType => {
  if (!error.errors) {
    return {
      ...error,
      errors: void 0,
    };
  }
  const errFieldsArr = Object.entries(error.errors);

  const newFields = errFieldsArr.reduce((acc, item) => {
    const key = item[0];
    const details = item[1];
    const message = i18n?.t(`${ERRORS_VALIDATION_KEY}${details.alias}`) || details.desc;

    return {
      ...acc,
      [key]: message,
    };
  }, {});

  return {
    ...error,
    errors: newFields,
  };
};

export const getHeader = (main: string, key: string, t: TFunction, options?: TOptions): string => {
  return t(`${main}.${key}`, options);
};

export const getVisibleList = <T>(items: T[], count: number): T[] => {
  return items.length <= count ? items : items.slice(0, count);
};

export const getHiddenList = <T>(items: T[], count: number): T[] => {
  return items.slice(count, items.length);
};

export const getTextFromArrayWithSeparator = (
  arr: (string | undefined | null)[],
  separator: string,
) => arr.filter((item) => item).join(separator);

export const hasDataPrimitiveField = <T>(data: T, checkNull = false): boolean => {
  const isString = typeof data === "string";
  const isBoolean = typeof data === "boolean";
  const isNumber = Number.isFinite(data);
  const isNull = checkNull ? data === null : false;
  return isString || isBoolean || isNumber || isNull;
};

export const hasDataFields = <T>(data: T | null, requiredFields: string[]): boolean => {
  if (data === null) return true;
  return requiredFields.every((field) => typeof data === "object" && field in data);
};

export const hasDataArrayField = <T extends Array<any>>(data: T): boolean => {
  if (!Array.isArray(data)) {
    return false;
  }
  return data.length === 0 || data.every((field) => field in data);
};

export const getCurrentTabStep = (
  innerPage: VehicleTabInnerPageType | null = null,
): VehicleTabInnerPageKeysType | null => {
  if (!innerPage) return null;
  for (const key in innerPage) {
    const keyType = key as VehicleTabInnerPageKeysType;
    if (innerPage[keyType]?.active) {
      return keyType;
    }
  }
  return null;
};

export const getValueFromField = (field: Dayjs | null, valueName: string) => {
  const data = dayjs(field).isValid() ? dayjs(field).format(`${valueName}`) : 0;
  return Number(data);
};

export const disabledTime = (startValue: Dayjs | null, endValue: Dayjs | null, minuteStep = 30) => {
  return {
    disabledHours: () => {
      if (getValueFromField(startValue, "H") === 0) return [];
      if (getValueFromField(startValue, "m") + Number(minuteStep) >= 60)
        return Array.from(Array(Number(getValueFromField(startValue, "H") + 1)).keys());
      return Array.from(Array(getValueFromField(startValue, "H")).keys());
    },
    disabledMinutes: () => {
      if (
        getValueFromField(startValue, "H") === getValueFromField(endValue, "H") &&
        getValueFromField(startValue, "H") !== 0
      )
        return Array.from(Array(Number(getValueFromField(startValue, "m") + 1)).keys());
      return [];
    },
  };
};

export const changeTime = (initDate: string, changeInMinutes = 60): string => {
  const minute = 1000 * 60;
  const initDateToMileseconds = new Date(initDate).getTime();
  return new Date(initDateToMileseconds + minute * changeInMinutes).toString();
};

export const getMountingIconByValue = (value: string): string | null => {
  const mountingSide = mountingSides.find((item) => {
    return item.value === value;
  });
  return mountingSide?.iconName || null;
};

export const calculatePercentageOfNumber = (expValue: number, diffValue: number): number => {
  const currentPercentSum = ((expValue - diffValue) / expValue) * 100;
  return Math.round(currentPercentSum * 100) / 100;
};

export const isString = (value: unknown): boolean => typeof value === "string";
export const isArrOfStrings = (v: unknown[] = []): boolean => {
  return v.every((opt) => isString(opt));
};

export const isEmail = (email: string): boolean => new RegExp("^[^><]+@[^><]+$").test(email);

export const maskEmail = (email: string): string => {
  if (!isEmail(email)) {
    return email;
  }

  const [user, domain] = email.split("@");
  const userLen = user.length;

  if (userLen < 2) {
    return ["***", domain].join("@");
  }

  const numCharsToSpare = userLen > 4 ? 2 : 1;
  const rx = new RegExp(`^([\\s\\S]{${numCharsToSpare}})(.*)([\\s\\S]{${numCharsToSpare}})$`);

  return [
    user.replace(rx, (_, a: string, b: string, c: string) => `${a}...${b ? c : ""}`),
    domain,
  ].join("@");
};

export const getDateFromTimestamp = (params: {
  timestamp: number;
  format: string;
  locale: string;
}): string => {
  const { timestamp, format, locale = "" } = params;
  const valueWithLocale = dayjs.unix(timestamp).locale(locale).format(format);
  const valueNoLocale = dayjs.unix(timestamp).format(format);
  const renderValue = locale ? valueWithLocale : valueNoLocale;

  return dayjs(timestamp).isValid() ? renderValue : "";
};

export const formatByPattern = (value: string | number, pattern: string) => {
  let currentIdx = 0;
  const values = value.toString().split("");
  return pattern.replace(/#/g, () => {
    const char = values[currentIdx];
    currentIdx += 1;
    return char ? char : "";
  });
};

export const mergeDeepPreferNonEmpty = <T, U = T, V = T>(left: U, right: V): T => {
  const concatValues = (l: U, r: V) => {
    const isNonEmptyLeft = keys(l).length > 0 || l === void 0 || l;
    return isNonEmptyLeft ? l : r;
  };

  return mergeDeepWith(concatValues, left, right) as T;
};

export const getFilteredMenuItems = (data: MenuData, pages?: string[]): MenuData => {
  if (!pages || !pages.length) {
    return data;
  }
  const filteredItems = data.items.filter((item) => !pages.includes(String(item.href)));
  return { ...data, items: filteredItems };
};

export const getEncodeParams = (values: Record<string, unknown>) => {
  return QS.stringify(values, QS_QUERY_CONFIG);
};
