// Definitions
import { CartPreviewType } from "client/core/cart/models";

// Domains
import { cartKeys, initialCartState } from "client/core/cart/store";
import { useCartStore } from "client/core/cart/hooks/use-cart-store";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { cartService } from "client/core/cart/service";

type UseCartPreviewFetchType = {
  loadingCartPreviewFetch: boolean;
  cartPreview: CartPreviewType;
};

export const useCartPreviewFetch = (): UseCartPreviewFetchType => {
  const { onToggleAvailableCart } = useCartStore();

  const fetchCartProps = useQueryAsync<CartPreviewType, CartPreviewType, [string] | string[]>({
    key: [cartKeys.preview],
    name: "fetchCartPreview",
    fetcher: cartService.fetchCartPreview,
    options: {
      refetchOnMount: "always",
      onSuccess: (cartPreview) => {
        const hasCartPreviewVehicles = !cartPreview?.tabs?.length;
        onToggleAvailableCart(hasCartPreviewVehicles);
      },
    },
  });

  return {
    loadingCartPreviewFetch: fetchCartProps.isLoading || fetchCartProps.isRefetching,
    cartPreview: fetchCartProps.data || initialCartState.preview,
  };
};
