// Core
import { z } from "zod";
import { getFieldLabel } from "client/core/cart/utils/common";

export const CART_STRING_FIELD_TYPE = {
  title: "title",
} as const;

const errorReport = {
  required: "inputs:errorMessages.required",
  max: "inputs:errorMessages.max",
};

export const inputConfig = {
  [CART_STRING_FIELD_TYPE.title]: {
    placeholder: getFieldLabel("vehicleName", true),
    required: errorReport.required,
    max: 255,
    maxText: errorReport.max,
  },
};

export const cartStringEditForm = {
  shape: {
    title: "",
  },
  schema: () => {
    return z.object({
      [CART_STRING_FIELD_TYPE.title]: z.string().trim(),
    });
  },
};
