// Core
import { FC, SyntheticEvent, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "next-i18next";

// Components
import { Form } from "antd";
import { CartPreviewProductsRowProvider } from "client/core/cart/components/cart-preview-products-row-provider";

// Definitions
import type { CartTabProductType, CartProductQuantitiesType } from "client/core/cart/models";
import type { RowChildrenType } from "components/ui/TableAnt/TableAnt.types";

// Hooks
import { useCartPreviewProductUpdate } from "client/core/cart/hooks/use-cart-preview-product-update";

// Utils
import { cartPreviewProductsForm, CART_PREVIEW_PRODUCT_FIELD_TYPE } from "./config";

type CartPreviewProductsProps = {
  children: RowChildrenType<unknown> | RowChildrenType<CartTabProductType>[];
};

export const CartPreviewProductsForm: FC<CartPreviewProductsProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { onUpdateCartPreviewProduct } = useCartPreviewProductUpdate();

  const initialValue = Array.isArray(children)
    ? children.find((item) => item.key === CART_PREVIEW_PRODUCT_FIELD_TYPE.quantity)?.props.record
    : null;

  const formProps = useForm<CartProductQuantitiesType>({
    defaultValues: {
      ...cartPreviewProductsForm.shape,
      ...(initialValue && { quantity: initialValue.quantity }),
    },
    resolver: zodResolver(cartPreviewProductsForm.schema(t)),
    mode: "all",
    reValidateMode: "onChange",
  });
  const { watch, handleSubmit, setValue } = formProps;

  const handleSubmitForm = handleSubmit(async (values): Promise<void> => {
    if (initialValue && values.quantity?.value) {
      await onUpdateCartPreviewProduct?.({
        id: initialValue.id,
        quantity: values.quantity.value,
        isActive: initialValue.isActive,
      });
    }
  });

  const onSubmitForm = (event: SyntheticEvent) => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  useEffect(() => {
    if (initialValue?.quantity) {
      setValue("quantity", initialValue?.quantity);
    }
  }, [setValue, initialValue]);

  useEffect(() => {
    const subscription = watch(() => {
      void handleSubmitForm();
    });
    return () => subscription.unsubscribe();
  }, [watch, initialValue]);

  const titleAvailable = t("inputs:quantity.titleAvailable", {
    available: initialValue?.quantity?.available,
  });

  return (
    <CartPreviewProductsRowProvider tooltip={titleAvailable}>
      <FormProvider {...formProps}>
        <Form layout="vertical" component={false} onFinish={onSubmitForm}>
          <tr {...props}>
            <>{children}</>
          </tr>
        </Form>
      </FormProvider>
    </CartPreviewProductsRowProvider>
  );
};
