// Definitions
import type { ProfileVehicleType } from "client/core/profile-vehicle/models";

// Domains
import { profileVehicleKeys } from "client/core/profile-vehicle/store";
import { profileVehicleService } from "client/core/profile-vehicle/service";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { errorHandler404 } from "utils/error-handlers";

type ProfileVehicleParamsType = { vehicleId?: number };
type ProfileVehicleQueryKey = [string, ProfileVehicleParamsType];
type ProfileVehicleFetchType = {
  loadingFetchProfileVehicle: boolean;
  profileVehicle: ProfileVehicleType | null;
};
type UseProfileVehicleFetchProps = {
  vehicleId: number;
};

export const useProfileVehicleFetch = (
  props: UseProfileVehicleFetchProps,
): ProfileVehicleFetchType => {
  const { vehicleId } = props;

  const params = { ...(vehicleId && { vehicleId }) };
  const queryKey: ProfileVehicleQueryKey = [profileVehicleKeys.vehicle, params];

  const fetchProfileVehicleProps = useQueryAsync<
    ProfileVehicleType,
    ProfileVehicleType,
    ProfileVehicleQueryKey,
    ProfileVehicleParamsType
  >({
    key: queryKey,
    name: "fetchProfileVehicleQuery",
    fetcher: profileVehicleService.fetchVehicle,
    fetcherPayload: { params },
    options: {
      enabled: !!vehicleId,
    },
    handlerError404: errorHandler404,
  });

  return {
    loadingFetchProfileVehicle:
      fetchProfileVehicleProps.isLoading || fetchProfileVehicleProps.isRefetching,
    profileVehicle: fetchProfileVehicleProps?.data || null,
  };
};
