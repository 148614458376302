export const mountingSides = [
  {
    value: "vr",
    iconName: "MountingFrontRight",
  },
  {
    value: "rv",
    iconName: "MountingFrontRight",
  },
  {
    value: "bs",
    iconName: "MountingFrontRight",
  },
  {
    value: "vl",
    iconName: "MountingFrontLeft",
  },
  {
    value: "lv",
    iconName: "MountingFrontLeft",
  },
  {
    value: "fs",
    iconName: "MountingFrontLeft",
  },
  {
    value: "va",
    iconName: "MountingFrontBoth",
  },
  {
    value: "v",
    iconName: "MountingFrontBoth",
  },
  {
    value: "fb",
    iconName: "MountingFrontBoth",
  },
  {
    value: "r",
    iconName: "MountingBothRight",
  },
  {
    value: "l",
    iconName: "MountingBothLeft",
  },
  {
    value: "hr",
    iconName: "MountingBackRight",
  },
  {
    value: "rh",
    iconName: "MountingBackRight",
  },
  {
    value: "hl",
    iconName: "MountingBackLeft",
  },
  {
    value: "lh",
    iconName: "MountingBackLeft",
  },
  {
    value: "ha",
    iconName: "MountingBackBoth",
  },
  {
    value: "h",
    iconName: "MountingBackBoth",
  },
  {
    value: "vh",
    iconName: "MountingBothBoth",
  },
  {
    value: "fs",
    iconName: "MountingBothBoth",
  },
];
