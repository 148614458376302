// Definitions
import type { CartPreviewType, CartTabProductType } from "client/core/cart/models";
import type { ProductType } from "client/core/catalog-classic-product-details";
import type { OrdersCheckoutType } from "client/core/orders-checkout/models";

export enum GTMTypesEnum {
  pageLoad = "page_load",
  registration = "registration",
  authorization = "authorization",
  search = "search",
  selector_car = "selector_car",
  catalog = "catalog",
  show_price = "show_price",
  add_to_cart = "add_to_cart",
  remove_from_cart = "remove_from_cart",
  view_cart = "view_cart",
  add_payment_info = "add_payment_info",
  begin_checkout = "begin_checkout",
  purchase = "purchase",
  add_tab = "add_tab",
  close_tab = "close_tab",
  tab_click = "tab_click",
  consent_mode_option = "consent_mode_option",
  consent_mode_def = "consent_mode_def",
  view_item_list = "view_item_list",
  view_item = "view_item",
  select_item = "select_item",
  dashboard_banner = "dashboard_banner",
  popup = "popup",
  header_top = "header_top",
  footer_menu = "footer_menu",
  notification_page = "notification_page",
  notification = "notification",
  ecommerce_clear = "ecommerce_clear",
}

export enum PayloadKeysEnum {
  parameters = "parameters",
  ecommerce = "ecommerce",
}

export enum ParametersEnum {
  user_id = "user_id",
  visitor_type = "visitor_type",
  registration_step = "registration_step",
  query = "query",
  car_id = "car_id",
  model_id = "model_id",
  engine_id = "engine_id",
  maker_id = "maker_id",
  basket_id = "basket_id",
  event_action = "event_action",
  event_object = "event_object",
  parent_id = "parent_id",
  child_id = "child_id",
  shipping_date = "shipping_date",
  route_name = "route_name",
  free_search = "free_search",
  necessary = "necessary",
  statistics = "statistics",
  ad_storage = "ad_storage",
  analytics_storage = "analytics_storage",
  ad_storage_def = "ad_storage_def",
  analytics_storage_def = "analytics_storage_def",
  authorization_type = "authorization_type",
  error_type = "error_type",
  geo_country_code = "geo_country_code",
  crm_session_id = "crm_session_id",
  coupon = "coupon",
  banner_position = "banner_position",
}

export enum EcommerceEnum {
  currency = "currency",
  value = "value",
  items = "items",
  transaction_id = "transaction_id",
  shipping = "shipping",
  item_list_id = "item_list_id",
  payment_type = "payment_type",
  coupon = "coupon",
  used_bonus = "used_bonus",
  purchase_discount = "purchase_discount",
  tax = "tax",
}

export enum EcommerceItemsEnum {
  item_id = "item_id",
  item_name = "item_name",
  item_brand = "item_brand",
  item_category = "item_category",
  item_category2 = "item_category2",
  item_category3 = "item_category3",
  item_variant = "item_variant",
  price = "price",
  quantity = "quantity",
  affiliation = "affiliation",
  item_list_id = "item_list_id",
}

export enum EcommerceProductVariantsEnum {
  vin_compitable = "vin_compitable",
  vin_incompitable = "vin_incompitable",
}

export enum VisitorTypeEnum {
  logged_user = "logged_user",
  registered_user = "registered_user",
  unknown_user = "uknown_user",
}

export enum AuthorizationTypeEnum {
  authorization_type_success = "success",
  authorization_type_error = "error",
}

export enum ErrorTypeEnum {
  unknown = "unknown error",
  validation = "invalid validation",
  leadVerified = "lead not verified",
  leadRegistered = "lead already registered",
  forbidden = "access forbidden",
}
export const SELECTOR_CAR_EVENT_ACTION_KEYS = {
  car: "car",
  kba: "kba",
  history: "history",
  click_maker: "click_maker",
  click_model: "click_model",
  click_engine: "click_engine",
  choose_maker: "choose_maker",
  choose_model: "choose_model",
  choose_engine: "choose_engine",
  car_reset: "car_reset",
  clear_maker_only: "clear%maker%none%none",
  clear_maker_model_only: "clear%maker%model%none",
  clear_maker_model_engine: "clear%maker%model%engine",
} as const;
export type SelectorCarEventActionKeysType =
  (typeof SELECTOR_CAR_EVENT_ACTION_KEYS)[keyof typeof SELECTOR_CAR_EVENT_ACTION_KEYS];

export const SELECTOR_CAR_EVENT_OBJECT_KEYS = {
  popup: "popup",
  base: "base",
} as const;
export type SelectorCarEventObjectKeysType =
  (typeof SELECTOR_CAR_EVENT_OBJECT_KEYS)[keyof typeof SELECTOR_CAR_EVENT_OBJECT_KEYS];

export const CATALOG_EVENT_ACTION_KEYS = {
  click_open: "click_open",
  click_close: "click_close",
} as const;
export type CatalogEventActionType = keyof typeof CATALOG_EVENT_ACTION_KEYS;

export const CATALOG_EVENT_OBJECT_KEYS = {
  sidebar: "sidebar",
  main: "main",
} as const;
export type CatalogEventObjectType = keyof typeof CATALOG_EVENT_OBJECT_KEYS;

export enum ShowPriceEventActionEnum {
  show_price = "show_price",
  hide_price = "hide_price",
}

export enum CatalogTabCloseEventActionEnum {
  icon = "icon",
  limit = "limit",
}

export const ROUTE_NAME_KEYS = {
  product: "product",
  catalog: "catalog",
  cart: "cart",
  free_search: "free-search",
  oem_search: "oem-search",
} as const;
export type RouteNameKeysType = (typeof ROUTE_NAME_KEYS)[keyof typeof ROUTE_NAME_KEYS];

export enum ConsentEnum {
  granted = "granted",
  denied = "denied",
}

export const AFFILIATION_TYPE = {
  "autodoc.pro": "autodoc.pro",
} as const;
export type AffiliationType = keyof typeof AFFILIATION_TYPE;

export const ITEM_LIST_ID_TYPE = {
  "catalog-items": "catalog-items",
  "free-search-items": "free-search-items",
  "oem-search-items": "oem-search-items",
  "product-items": "product-items",
  "cart-items": "cart-items",
} as const;

export type ItemListIdType = keyof typeof ITEM_LIST_ID_TYPE;

export const DASHBOARD_BANNER_NAME = {
  go_core: "go_core",
} as const;

export const ANALYTICS_ACTION_TYPE = {
  view: "view",
  click: "click",
} as const;

export const POPUP_NAME = {
  go_core: "go_core",
} as const;

export const HEADER_HOT_TRICKER_NAME = {
  go_core: "go_core",
} as const;

export const FOOTER_MENU_ITEM = {
  cookie: "cookie",
  legal_notice: "legal_notice",
  about: "about",
  go_core: "go_core",
  benefits: "benefits",
  "term-conditions": "term-conditions",
  "benefits-terms-conditions": "benefits-terms-conditions",
  "privacy-policy": "privacy-policy",
  code_of_conduct: "code_of_conduct",
  payment: "payment",
  delivery: "delivery",
  "core-exchange": "core-exchange",
  returns_refunds: "returns_refunds",
} as const;

export const NOTIFICATION_ACTION_TYPE = {
  preview: "preview",
  click_bell: "click_bell",
  view_all_btn: "view_all_btn",
  view_more: "view_more",
  view_less: "view_less",
  go_core_shop: "go_core_shop",
  go_core_meet: "go_core_meet",
  read_all: "read_all",
} as const;

export type EventPayloadPageLoadType = {
  [PayloadKeysEnum.parameters]?: {
    [ParametersEnum.user_id]?: number;
    [ParametersEnum.crm_session_id]?: string;
    [ParametersEnum.geo_country_code]?: string;
    [ParametersEnum.visitor_type]?: VisitorTypeEnum;
  };
} | null;

export type EventPageLoadDataType = {
  [ParametersEnum.user_id]?: number;
  [ParametersEnum.crm_session_id]?: string;
  [ParametersEnum.geo_country_code]?: string;
  [ParametersEnum.visitor_type]?: VisitorTypeEnum;
};

export type EventPayloadAuthorizationType = {
  [PayloadKeysEnum.parameters]?: {
    [ParametersEnum.user_id]?: number;
    [ParametersEnum.visitor_type]?: VisitorTypeEnum;
    [ParametersEnum.authorization_type]?: AuthorizationTypeEnum;
    [ParametersEnum.error_type]?: ErrorTypeEnum;
  };
} | null;

export type EventAuthorizationDataType = {
  [ParametersEnum.user_id]?: number;
  [ParametersEnum.visitor_type]?: VisitorTypeEnum;
  [ParametersEnum.authorization_type]?: AuthorizationTypeEnum;
  [ParametersEnum.error_type]?: ErrorTypeEnum;
};

export type EventPayloadRegistrationType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.user_id]?: number;
    [ParametersEnum.visitor_type]?: VisitorTypeEnum;
    [ParametersEnum.registration_step]?: number;
  };
};

export type EventRegistrationDataType = {
  [ParametersEnum.user_id]?: number;
  [ParametersEnum.visitor_type]?: VisitorTypeEnum;
  [ParametersEnum.registration_step]?: number;
};

export type EventPayloadSearchType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.query]: string;
  };
};

export type EventSearchDataType = {
  [ParametersEnum.query]: string;
};

export type EventPayloadSelectorCarType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.engine_id]?: number;
    [ParametersEnum.event_action]: SelectorCarEventActionKeysType;
    [ParametersEnum.event_object]: SelectorCarEventObjectKeysType;
  };
};

export type EventSelectorCarDataType = {
  [ParametersEnum.maker_id]?: number;
  [ParametersEnum.model_id]?: number;
  [ParametersEnum.engine_id]?: number;
  [ParametersEnum.car_id]?: number;
  [ParametersEnum.event_action]: SelectorCarEventActionKeysType;
  [ParametersEnum.event_object]?: SelectorCarEventObjectKeysType;
};

export type EventPayloadCatalogType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.parent_id]: number;
    [ParametersEnum.child_id]?: number;
    [ParametersEnum.event_action]: CatalogEventActionType;
    [ParametersEnum.event_object]: CatalogEventObjectType;
  };
};
export type EventCatalogDataType = {
  [ParametersEnum.parent_id]?: number;
  [ParametersEnum.child_id]?: number;
  [ParametersEnum.event_action]?: CatalogEventActionType;
  [ParametersEnum.event_object]?: CatalogEventObjectType;
};

export type EventPayloadShowPriceType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: ShowPriceEventActionEnum;
  };
};
export type EventShowPriceDataType = {
  [ParametersEnum.event_action]: ShowPriceEventActionEnum;
};

export type EventCatalogTabDataType = {
  [ParametersEnum.car_id]: number | string;
};
export type EventCatalogTabPayloadType = {
  [PayloadKeysEnum.parameters]: EventCatalogTabDataType;
};

export type EventCatalogCloseTabDataType = EventCatalogTabDataType & {
  [ParametersEnum.event_action]?: CatalogTabCloseEventActionEnum;
};
export type EventCatalogCloseTabPayloadType = {
  [PayloadKeysEnum.parameters]: EventCatalogCloseTabDataType;
};

export type EventPayloadPurchaseItemsType = {
  [EcommerceItemsEnum.item_id]: number;
  [EcommerceItemsEnum.item_name]: string;
  [EcommerceItemsEnum.item_brand]: string;
  [EcommerceItemsEnum.affiliation]: AffiliationType;
  [EcommerceItemsEnum.price]: number;
  [EcommerceItemsEnum.quantity]: number;
  [EcommerceItemsEnum.item_variant]: EcommerceProductVariantsEnum;
  [EcommerceItemsEnum.item_category3]?: number;
  [EcommerceItemsEnum.item_list_id]: ItemListIdType;
};

export type EventPayloadProductItemType = {
  [EcommerceItemsEnum.item_id]: number;
  [EcommerceItemsEnum.item_name]: string;
  [EcommerceItemsEnum.item_brand]: string;
  [EcommerceItemsEnum.price]: number;
  [EcommerceItemsEnum.quantity]: number;
  [EcommerceItemsEnum.item_variant]: EcommerceProductVariantsEnum;
  [EcommerceItemsEnum.affiliation]: AffiliationType;
  [EcommerceItemsEnum.item_list_id]: ItemListIdType;
  [EcommerceItemsEnum.item_category]?: number;
  [EcommerceItemsEnum.item_category2]?: number;
  [EcommerceItemsEnum.item_category3]?: number | string;
};

export type EventPayloadAddToCartType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventPayloadRemoveFromCartType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventPayloadCartProductItemType = {
  [EcommerceItemsEnum.item_id]: number;
  [EcommerceItemsEnum.item_name]: string;
  [EcommerceItemsEnum.item_brand]: string;
  [EcommerceItemsEnum.price]: number;
  [EcommerceItemsEnum.quantity]: number;
  [EcommerceItemsEnum.item_variant]: EcommerceProductVariantsEnum;
  [EcommerceItemsEnum.affiliation]: AffiliationType;
  [EcommerceItemsEnum.item_list_id]: ItemListIdType;
  [EcommerceItemsEnum.item_category3]?: number | string;
};

export type EventPayloadViewCartType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadCartProductItemType[][];
  };
};

export type EventPayloadBeginCheckoutType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventPayloadAddPaymentInfoType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
    [ParametersEnum.coupon]?: string;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.payment_type]: string;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventViewItemListPayloadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.item_list_id]: ItemListIdType;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventViewItemPayloadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.shipping_date]: number;
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventSelectItemPayloadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.shipping_date]: number;
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.item_list_id]: ItemListIdType;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventClearEcommercePayloadType = {
  [PayloadKeysEnum.ecommerce]: null;
};

export type EventPayloadPurchaseType = {
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.transaction_id]?: string;
    [EcommerceEnum.shipping]?: number;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.tax]: number;
    [EcommerceEnum.coupon]?: string;
    [EcommerceEnum.used_bonus]?: number;
    [EcommerceEnum.purchase_discount]?: number;
    [EcommerceEnum.items]: EventPayloadPurchaseItemsType[];
  };
};

export type EventConsentOptionPayloadType = {
  [ParametersEnum.ad_storage]: ConsentEnum;
  [ParametersEnum.analytics_storage]: ConsentEnum;
};

export type EventConsentDefPayloadType = {
  [ParametersEnum.ad_storage_def]: ConsentEnum;
  [ParametersEnum.analytics_storage_def]: ConsentEnum;
};

export type EventPayloadDashboardBannerType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: string;
    [ParametersEnum.banner_position]?: number;
  };
};

export type EventPayloadPopupType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: string;
  };
};

export type EventPayloadHeaderTopType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: string;
  };
};

export type EventPayloadFooterMenuType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: keyof typeof FOOTER_MENU_ITEM;
  };
};

export type EventPayloadNotificationPageType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: string;
  };
};

export type EventPayloadNotificationType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: keyof typeof NOTIFICATION_ACTION_TYPE;
  };
};

export type EventViewItemListDataType = {
  itemListId: ItemListIdType;
  productsItems: ProductType[];
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventViewItemDataType = {
  productData: ProductType;
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventSelectItemDataType = {
  itemListId: ItemListIdType;
  productData: ProductType;
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventAddToCartDataType = {
  product: ProductType;
  quantity: number;
  itemListId: ItemListIdType;
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventRemoveFromCartDataType = {
  product: CartTabProductType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventViewCartDataType = {
  cartPreview: CartPreviewType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventBeginCheckoutDataType = {
  ordersCheckout: OrdersCheckoutType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventAddPaymentInfoDataType = {
  checkout: OrdersCheckoutType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventPurchaseDataType = {
  checkout: OrdersCheckoutType;
  orderId: number;
  itemListId: ItemListIdType;
};

export type EventConsentOptionDataType = {
  [ParametersEnum.necessary]: boolean;
  [ParametersEnum.statistics]: boolean;
};

export type EventConsentDefDataType = {
  [ParametersEnum.necessary]: boolean;
  [ParametersEnum.statistics]: boolean;
};

export type EventDashboardBannerDataType = {
  bannerPosition: number;
  bannerName: keyof typeof DASHBOARD_BANNER_NAME;
};

export type EventPopupDataType = {
  popupName: keyof typeof POPUP_NAME;
};

export type EventHeaderTopDataType = {
  hotTrickerName: keyof typeof HEADER_HOT_TRICKER_NAME;
};

export type EventFooterMenuDataType = {
  actionType: keyof typeof FOOTER_MENU_ITEM;
};

export type EventNotificationPageDataType = {
  totalNotifications: number;
  totalUnreadNotifications: number;
};

export type EventNotificationDataType = {
  actionType: keyof typeof NOTIFICATION_ACTION_TYPE;
};

export type EventPayloadType = {
  [GTMTypesEnum.pageLoad]: EventPayloadPageLoadType;
  [GTMTypesEnum.authorization]: EventPayloadAuthorizationType;
  [GTMTypesEnum.registration]: EventPayloadRegistrationType;
  [GTMTypesEnum.search]: EventPayloadSearchType;
  [GTMTypesEnum.selector_car]: EventPayloadSelectorCarType;
  [GTMTypesEnum.catalog]: EventPayloadCatalogType;
  [GTMTypesEnum.show_price]: EventPayloadShowPriceType;
  [GTMTypesEnum.add_to_cart]: EventPayloadAddToCartType;
  [GTMTypesEnum.begin_checkout]: EventPayloadBeginCheckoutType;
  [GTMTypesEnum.purchase]: EventPayloadPurchaseType;
  [GTMTypesEnum.add_tab]: EventCatalogTabPayloadType;
  [GTMTypesEnum.close_tab]: EventCatalogCloseTabPayloadType;
  [GTMTypesEnum.tab_click]: EventCatalogTabPayloadType;
  [GTMTypesEnum.consent_mode_option]: EventConsentOptionPayloadType;
  [GTMTypesEnum.consent_mode_def]: EventConsentDefPayloadType;
  [GTMTypesEnum.view_item_list]: EventViewItemListPayloadType;
  [GTMTypesEnum.view_item]: EventViewItemPayloadType;
  [GTMTypesEnum.select_item]: EventSelectItemPayloadType;
  [GTMTypesEnum.remove_from_cart]: EventPayloadRemoveFromCartType;
  [GTMTypesEnum.view_cart]: EventPayloadViewCartType;
  [GTMTypesEnum.add_payment_info]: EventPayloadAddPaymentInfoType;
  [GTMTypesEnum.dashboard_banner]: EventPayloadDashboardBannerType;
  [GTMTypesEnum.popup]: EventPayloadPopupType;
  [GTMTypesEnum.header_top]: EventPayloadHeaderTopType;
  [GTMTypesEnum.footer_menu]: EventPayloadFooterMenuType;
  [GTMTypesEnum.notification_page]: EventPayloadNotificationPageType;
  [GTMTypesEnum.notification]: EventPayloadNotificationType;
  [GTMTypesEnum.ecommerce_clear]: EventClearEcommercePayloadType;
};

export type EventsDataType = {
  [GTMTypesEnum.pageLoad]: EventPageLoadDataType;
  [GTMTypesEnum.authorization]: EventAuthorizationDataType;
  [GTMTypesEnum.registration]: EventRegistrationDataType;
  [GTMTypesEnum.search]: EventSearchDataType;
  [GTMTypesEnum.selector_car]: EventSelectorCarDataType;
  [GTMTypesEnum.catalog]: EventCatalogDataType;
  [GTMTypesEnum.show_price]: EventShowPriceDataType;
  [GTMTypesEnum.add_to_cart]: EventAddToCartDataType;
  [GTMTypesEnum.begin_checkout]: EventBeginCheckoutDataType;
  [GTMTypesEnum.purchase]: EventPurchaseDataType;
  [GTMTypesEnum.add_tab]: EventCatalogTabDataType;
  [GTMTypesEnum.close_tab]: EventCatalogCloseTabDataType;
  [GTMTypesEnum.tab_click]: EventCatalogTabDataType;
  [GTMTypesEnum.consent_mode_option]: EventConsentOptionDataType;
  [GTMTypesEnum.consent_mode_def]: EventConsentDefDataType;
  [GTMTypesEnum.view_item_list]: EventViewItemListDataType;
  [GTMTypesEnum.view_item]: EventViewItemDataType;
  [GTMTypesEnum.select_item]: EventSelectItemDataType;
  [GTMTypesEnum.remove_from_cart]: EventRemoveFromCartDataType;
  [GTMTypesEnum.view_cart]: EventViewCartDataType;
  [GTMTypesEnum.add_payment_info]: EventAddPaymentInfoDataType;
  [GTMTypesEnum.dashboard_banner]: EventDashboardBannerDataType;
  [GTMTypesEnum.popup]: EventPopupDataType;
  [GTMTypesEnum.header_top]: EventHeaderTopDataType;
  [GTMTypesEnum.footer_menu]: EventFooterMenuDataType;
  [GTMTypesEnum.notification_page]: EventNotificationPageDataType;
  [GTMTypesEnum.notification]: EventNotificationDataType;
  [GTMTypesEnum.ecommerce_clear]: null;
};

export type EventsDataUnionType =
  | EventPageLoadDataType
  | EventAuthorizationDataType
  | EventRegistrationDataType
  | EventSearchDataType
  | EventSelectorCarDataType
  | EventCatalogDataType
  | EventShowPriceDataType
  | EventAddToCartDataType
  | EventBeginCheckoutDataType
  | EventPurchaseDataType
  | EventCatalogTabDataType
  | EventCatalogCloseTabDataType
  | EventConsentOptionDataType
  | EventConsentDefDataType
  | EventViewItemListDataType
  | EventViewItemDataType
  | EventSelectItemDataType
  | EventRemoveFromCartDataType
  | EventViewCartDataType
  | EventAddPaymentInfoDataType
  | EventDashboardBannerDataType
  | EventPopupDataType
  | EventHeaderTopDataType
  | EventFooterMenuDataType
  | EventNotificationPageDataType
  | EventNotificationDataType
  | null;

export interface GTMServiceInterface {
  event(name: GTMTypesEnum, data: EventsDataType[GTMTypesEnum]): void;
}

export interface GTMSegmentBuilderInterface<T extends GTMTypesEnum = GTMTypesEnum> {
  makeSegment(data: EventsDataUnionType): EventPayloadType[T];
}

export type GTMConfigType = {
  gtmId: string;
  eventsMap: Map<GTMTypesEnum, GTMSegmentBuilderInterface>;
  gtmArgs?: {
    dataLayer?: object;
    dataLayerName?: string;
  };
  checkEventsConsentGranted?: (name: GTMTypesEnum) => boolean;
  active?: boolean;
};
