// Components
import { Container } from "components/common/Container";
import { LayoutError } from "components/common/LayoutError";
import { StandardErrorReporter, StandardErrorReporterProps } from "components/ui/Errors";

export const ErrorReporter = (props: StandardErrorReporterProps) => {
  return (
    <LayoutError>
      <Container>
        <StandardErrorReporter {...props} />
      </Container>
    </LayoutError>
  );
};
