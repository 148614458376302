// Core
import { forwardRef } from "react";

// Definitions
import type { TimePickerProps } from "./TimePicker.types";

// Components
import { DatePicker } from "../DatePicker";

export const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} ref={ref} />;
});

TimePicker.displayName = "TimePicker";
