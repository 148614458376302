// Core
import { z } from "zod";
import { TFunction } from "i18next";

export const PERSONAL_MANAGER_CALLBACK_FIELD = {
  phoneNumber: "phoneNumber",
  phonePrefix: "phonePrefix",
  dateTime: "dateTime",
  desiredTime: "desiredTime",
} as const;

const regExps = {
  [PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber]: /^\d{3}[\d|\\+]\d{1,9}$/,
  [PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime]: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z$/,
};

const generalWithTranslations = {
  required: "inputs:errorMessages.required",
  invalidPhone: "inputs:errorMessages.invalidPhone",
  invalidTime: "inputs:errorMessages.invalidTime",
  errorMin: "inputs:errorMessages.min",
};

export const inputConfig = {
  [PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix]: {
    label: "",
    placeholder: "inputs:defaultPlaceholders.select",
    required: generalWithTranslations.required,
  },
  [PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber]: {
    label: "inputs:phone.label",
    placeholder: "inputs:phone.placeholder",
    required: generalWithTranslations.required,
    min: 5,
    minText: generalWithTranslations.errorMin,
    invalid: generalWithTranslations.invalidPhone,
    pattern: regExps[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber],
  },
  [PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime]: {
    invalid: generalWithTranslations.invalidTime,
    pattern: regExps[PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime],
    label: "personal-manager:callBack.desiredTime.label",
    placeholder: "personal-manager:callBack.desiredTime.placeholder",
  },
};

export const personalManagerCallBackForm = {
  shape: {
    [PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime]: "",
    [PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix]: null,
    [PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber]: "",
  },
  schema: (t: TFunction) =>
    z.object({
      [PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix]: z
        .object(
          {
            id: z.number(),
            label: z.string(),
            value: z.string(),
            icon: z.string().optional(),
          },
          {
            required_error: t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix].required),
            invalid_type_error: t(
              inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix].required,
            ),
          },
        )
        .required({
          id: true,
          label: true,
          value: true,
        }),
      [PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber]: z
        .string({
          required_error: t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].required),
          invalid_type_error: t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].required),
        })
        .trim()
        .min(
          inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].min,
          t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].minText, {
            label: t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].label).toLowerCase(),
            count: inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].min,
          }),
        )
        .regex(
          inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].pattern,
          t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].invalid),
        ),
      [PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime]: z
        .string()
        .nonempty(t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].required))
        .regex(
          inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime].pattern,
          t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime].invalid),
        ),
    }),
};
