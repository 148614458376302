// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  EventPayloadSearchType,
  EventSearchDataType,
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class SearchEvent extends AbstractGTMEventSegment<GTMTypesEnum.search> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.query]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadSearchType {
    const searchData = data as EventSearchDataType;
    const segment = this.getSegment();

    return {
      ...segment,
      [PayloadKeysEnum.parameters]: {
        [ParametersEnum.query]: searchData.query,
      },
    };
  }
}

export const searchEvent: SearchEvent = new SearchEvent();
