// Core
import { browserReactQueryInformationLogger } from "./browser-react-query-info-logger";
import { serverDevelopmentLogger } from "./server-development-logger";
import { serverProductionLogger } from "./server-production-logger";
import { serverProductionRestLogger } from "./server-production-rest-logger";

export const logger = {
  browserReactQueryInformationLogger,
  serverDevelopmentLogger,
  serverProductionLogger,
  serverProductionRestLogger,
};
