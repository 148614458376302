// Components
import { notification, notificationMessages } from "components/ui/Notification";

// Definitions
import {
  NotificationTypeEnum,
  NotificationMessage,
} from "components/ui/Notification/Notification.types";

export const notificationService = Object.freeze({
  showSuccess: ({ title, desc }: NotificationMessage) =>
    notification.open(
      notificationMessages.customView(
        {
          title,
          desc,
        },
        NotificationTypeEnum.success,
      ),
    ),
  showError: ({ title, desc }: NotificationMessage) =>
    notification.open(
      notificationMessages.customView(
        {
          title,
          desc,
        },
        NotificationTypeEnum.error,
      ),
    ),
  showInfo: ({ title, desc }: NotificationMessage) =>
    notification.open(
      notificationMessages.customView(
        {
          title,
          desc,
        },
        NotificationTypeEnum.info,
      ),
    ),
});
