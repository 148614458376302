// Core
import { useRouter } from "next/router";

// Utils
import { cookiesService } from "utils/cookies";
import { CONSENT_COOKIE_KEY } from "../constants";

type UseConsentCookieReset = {
  resetCookie: () => void;
};

export const useConsentCookiesReset = (): UseConsentCookieReset => {
  const router = useRouter();

  const resetCookie = () => {
    cookiesService.remove(CONSENT_COOKIE_KEY);
    router.reload();
  };

  return { resetCookie };
};
