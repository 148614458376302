// Core
import { ReactNode, PropsWithChildren } from "react";
import cx from "classnames";

// Definitions
import type { FormProps } from "antd/lib/form/Form";

// Components
import { Form } from "antd";

// Utils
import st from "./Styles.module.less";

const FORM_BASE_CONFIG: FormProps = {
  labelCol: {
    xs: { span: 24 },
  },
  layout: "vertical",
  labelAlign: "left",
  name: "pro-form",
  size: "large",
};

type FormGeneralPropsType<T = void> = PropsWithChildren<
  FormProps<T> & {
    children: ReactNode;
  }
>;

export const FormGeneral = (props: FormGeneralPropsType) => {
  const { children, size, ...otherProps } = props;

  const formStyles = cx(st.form);

  const formConfig = {
    ...FORM_BASE_CONFIG,
    ...otherProps,
    size: size || FORM_BASE_CONFIG.size,
  };

  return (
    <Form className={formStyles} {...formConfig}>
      {children}
    </Form>
  );
};
