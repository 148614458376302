// Core
import React, { PropsWithChildren, ReactElement } from "react";

// Definitions
import type { ReturnDraftProductType } from "client/core/return-new/models";

// Utils
import { returnNewCache } from "client/core/return-new/contexts/return-create";

type ReturnNewStoreProviderPropsType = {
  children: ReactElement;
};

export const ReturnNewStoreProvider = ({
  children,
}: PropsWithChildren<ReturnNewStoreProviderPropsType>) => {
  const [data, setData] = React.useState<ReturnDraftProductType[]>([]);

  return (
    <returnNewCache.Provider
      value={{
        data,
        setData: (newData) => {
          setData(newData);
        },
      }}
    >
      {children}
    </returnNewCache.Provider>
  );
};
