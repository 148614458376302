// Core
import { useCallback, useState } from "react";

// Utils
import { verifyBrowser } from "utils/verify-browser";

type UseSessionStorageType<T> = {
  storedValue: T;
  setValue: (value: T) => void;
  getValue: (value: string) => T | void;
  clearValue: () => void;
};

export const useSessionStorage = <T = any>(
  key: string,
  initialValue: T,
): UseSessionStorageType<T> => {
  const isBrowser = verifyBrowser();

  const [storedValue, setStoredValue] = useState<T>((): T => {
    try {
      const item = isBrowser ? window.sessionStorage.getItem(key) : "";
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? (value(storedValue) as T) : value;
      isBrowser && window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      setStoredValue(valueToStore);
    } catch (error) {
      console.log(error);
    }
  };

  const getValue = (value: string) => {
    try {
      const item = isBrowser ? window.sessionStorage.getItem(value) : "";

      return item ? (JSON.parse(item) as T) : void 0;
    } catch (error) {
      console.log(error);
    }
  };

  const clearValue = useCallback(() => {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  }, [key]);

  return { storedValue, setValue, getValue, clearValue };
};
