// Core
import { useContext } from "react";
import { useTranslation } from "next-i18next";

// Components
import { BannerSurvey } from "components/common/BannerSurvey";
import { BannerSales } from "components/common//BannerSales";
import { HeaderBannerSpecial } from "components/common/HeaderBanner/HeaderBannerSpecial";
import { HeaderBannerGoCore } from "client/core/header-banner/components/header-banner-go-core";
import { HeaderBannerOlympicGames } from "client/core/header-banner/components/header-banner-olympic-games";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

// Context
import { PermissionContext } from "contexts/usePermissionContext";

type HeaderBannerProps = {
  country: string;
};

const headerBanners: Record<string, JSX.Element> = {
  FR: <BannerSurvey />,
  DE: <BannerSurvey />,
  EN: <BannerSales />,
  AT: <BannerSurvey />,
  NL: <BannerSurvey />,
};

export const HeaderBanner = (props: HeaderBannerProps) => {
  const { t } = useTranslation("common");
  const ability = useContext(PermissionContext);
  const agentOff = ability.can(PERMISSION_ACT.READ, "service_agent_holidays_banner_top");
  const goCore = ability.can(PERMISSION_ACT.READ, "banner_top_go_core");
  const olympicGames = ability.can(PERMISSION_ACT.READ, "banner_top_olympic_games");

  if (olympicGames && agentOff) {
    return <HeaderBannerOlympicGames />;
  }

  if (agentOff) {
    return <HeaderBannerSpecial message={t("common:serviceAgentHoliday")} />;
  }

  if (goCore) {
    return <HeaderBannerGoCore />;
  }

  return headerBanners[props.country];
};
