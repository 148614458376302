// Core
import { HttpLink, ApolloLink, split, ApolloClient, InMemoryCache, from } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient, CloseCode } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import apolloLogger from "apollo-link-logger";
import { onError } from "@apollo/client/link/error";
import Router from "next/router";
import HttpStatusCode from "http-status-codes";
import { ApolloServerErrorCode } from "@apollo/server/errors";

// Utils
import appPackageJson from "../../package.json";
import { CLIENT_API_URL_HTTP_GRAPHQL, CLIENT_API_URL_WS_GRAPHQL } from "api/config";
import { verifyEnvironment } from "utils/verify-environment";
import { verifyBrowser } from "utils/verify-browser";
import { queryClient } from "init/queryClient";
import { notificationService } from "utils/notifications";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { authService } from "bus/auth/service";

const { isDevelopment } = verifyEnvironment();
const isBrowser = verifyBrowser();

const errorLink = onError((errorRaw) => {
  const definitionRes = getMainDefinition(errorRaw.operation.query);
  const isDetectOperation = definitionRes.kind === "OperationDefinition";
  const isSubscription = isDetectOperation && definitionRes.operation === "subscription";
  const isMutation = isDetectOperation && definitionRes.operation === "mutation";
  // check only auth
  if (errorRaw.graphQLErrors?.length) {
    errorRaw.graphQLErrors.every((err) => {
      const { message, locations, path } = err;
      if (isDevelopment) {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${String(locations)}, Path: ${String(
            path,
          )}`,
        );
      }
      if (err.extensions.code === "UNAUTHENTICATED") {
        notificationService.showError(getHttpErrorMessageWithTranslations());
        void (async () => {
          await Router.replace("/signin");
        })();
        queryClient.clear();
        return;
      }
      return !!err;
    });
    return;
  }

  const isNetworkErr =
    errorRaw.networkError &&
    "statusCode" in errorRaw.networkError &&
    errorRaw.networkError?.statusCode >= HttpStatusCode.INTERNAL_SERVER_ERROR;

  if (isNetworkErr && errorRaw.networkError) {
    const graphqlErrNetworkResult =
      "result" in errorRaw.networkError && errorRaw.networkError.result
        ? (errorRaw.networkError.result as {
            errors: { message: string; extensions: { code: string; stacktrace: string[] } }[];
          })
        : { errors: [] };
    const graphqlErrNetworkMessage = graphqlErrNetworkResult.errors[0].message;
    const graphqlErrNetworkCode = graphqlErrNetworkResult.errors[0].extensions?.code;

    if (isDevelopment) {
      console.log(`[Network error]: ${String(errorRaw.networkError)}`);
      console.log(
        `[Network graphQL error]: Code: ${graphqlErrNetworkCode}, Message: ${graphqlErrNetworkMessage}`,
      );
    }
  }

  if (isSubscription) {
    return;
  }

  if (isMutation && errorRaw.graphQLErrors) {
    errorRaw.graphQLErrors.every((err) => {
      const { message, locations, path } = err;
      if (isDevelopment) {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${String(locations)}, Path: ${String(
            path,
          )}`,
        );
      }

      if (
        err.extensions.code === ApolloServerErrorCode.BAD_REQUEST ||
        err.extensions.code === ApolloServerErrorCode.BAD_USER_INPUT ||
        err.extensions.code === ApolloServerErrorCode.GRAPHQL_VALIDATION_FAILED ||
        err.extensions.code === ApolloServerErrorCode.GRAPHQL_PARSE_FAILED
      ) {
        // add custome error rules err.extensions.code === MY_ERROR
        return;
      }
      return !!err;
    });
    return;
  }
  // showErrorGlobal,  errorBoundary
  notificationService.showError(getHttpErrorMessageWithTranslations());
  void (async () => {
    await Router.replace("/500");
  })();
});

export const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri: CLIENT_API_URL_HTTP_GRAPHQL,
    credentials: "same-origin",
  });

  const allHttpLinks = [errorLink, apolloLogger, httpLink] as ApolloLink[];
  const allWsLinks = [errorLink, apolloLogger] as ApolloLink[];

  const wsLink = isBrowser
    ? new GraphQLWsLink(
        createClient({
          url: CLIENT_API_URL_WS_GRAPHQL,
          connectionParams: async () => {
            let token = "";
            try {
              const session = await authService.session();
              token = String(session?.data?.data?.token);
            } catch (err) {
              if (isDevelopment) {
                console.warn("Apollo Client WSS params error", err);
              }
              throw err;
            }
            return {
              Authorization: token ? `Bearer ${token}` : "",
            };
          },
          on: {
            connected: (socket) => {
              if (isDevelopment) {
                console.log("Apollo Client WSS connected", socket);
              }
              return socket;
            },
            closed: (socket) => {
              const event = socket as CloseEvent;
              if (isDevelopment) {
                console.log("Apollo Client WSS closed", socket);
              }
              // event.code.Unauthorized = 4401
              if (event.code === CloseCode.Unauthorized) {
                void (async () => {
                  await Router.replace("/signin");
                })();
                queryClient.clear();
              }
            },
            error: (socket) => {
              if (isDevelopment) {
                console.log("Apollo Client WSS has error", socket);
              }
              // all other 500 cases
              notificationService.showError(getHttpErrorMessageWithTranslations());
            },
          },
        }),
      )
    : null;

  if (wsLink != null) {
    allWsLinks.push(wsLink);
  }

  const additiveHttpLink = from(allHttpLinks);
  const additiveWsLink = from(allWsLinks);

  const splitLink = split(
    (splitProps) => {
      const definition = getMainDefinition(splitProps.query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    additiveWsLink,
    additiveHttpLink,
  );

  return new ApolloClient({
    name: appPackageJson.name,
    version: appPackageJson.version,
    ssrMode: typeof window !== "undefined",
    link: splitLink,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
    connectToDevTools: isDevelopment,
  });
};
