// Core
import { useTranslation } from "next-i18next";

// Components
import { Grid } from "antd";
import { UserCountrySelectorForm } from "components/common/Forms/UserCountrySelector";

// Bus
import { useUserCountriesStaticDataFetch } from "bus/user/hooks/useUserCountriesStaticDataFetch";
import { useUserCountry } from "bus/user/hooks/useUserCountry";

// Utils
import st from "./Styles.module.less";

type UserCountrySelectorType = {
  testId: string;
};

export const UserCountrySelector = ({ testId }: UserCountrySelectorType) => {
  const { md = true } = Grid.useBreakpoint();
  const { t } = useTranslation();
  const { userCountry, onUpdateUserCountry } = useUserCountry();
  const { loadingStaticData, countries } = useUserCountriesStaticDataFetch({
    country: userCountry,
  });

  const label = md ? t("common:countrySelect") : "";

  return (
    <div className={st["country-selector"]} data-test-id={testId}>
      <UserCountrySelectorForm
        testId={`${testId}-form`}
        loading={loadingStaticData}
        label={label}
        options={countries}
        country={userCountry}
        onSubmit={onUpdateUserCountry}
        ghost
        withoutBorder
        type="header"
        controlSize="small"
      />
    </div>
  );
};
