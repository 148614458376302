// Core
import { FC } from "react";
import { useTranslation } from "next-i18next";
import cx from "classnames";

// Components
import { Layout, Grid, Row, Col } from "antd";
import { Container } from "components/common/Container";
import { Logo } from "components/common/Logo";
import { ButtonsBecome } from "components/common/ButtonsBecome";
import { UserCountrySelector } from "components/common/UserCountrySelector";

// Definitions
import type { ButtonsBecomeType } from "components/common/ButtonsBecome/ButtonsBecome.types";

// Utils
import { rowGutters } from "utils/constants";
import st from "./Styles.module.less";

type LayoutPublicHeaderProps = {
  navigationType?: ButtonsBecomeType;
  hasCountrySelector?: boolean;
  hasActions?: boolean;
  typeHeader?: "ghost";
};

export const LayoutHeaderPublic: FC<LayoutPublicHeaderProps> = ({
  navigationType,
  hasCountrySelector,
  hasActions = true,
  typeHeader,
}) => {
  const { t } = useTranslation();
  const { md = true, lg = true } = Grid.useBreakpoint();
  const headerStyle = cx(st.header, {
    ...(typeHeader && { [st[typeHeader]]: typeHeader }),
  });
  const headerInnerStyles = cx(st["header-inner"], {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });

  return (
    <Layout.Header className={headerStyle} data-test-id="header-public">
      <div className={headerInnerStyles}>
        <Container>
          <Row gutter={rowGutters} align="middle">
            <Col xs={{ span: 8 }} md={{ span: 5 }} lg={{ span: 6 }}>
              <Logo alt={t("common:firstPartPageTitle")} />
            </Col>
            {hasActions && (
              <Col
                xs={{ span: 16 }}
                md={{ span: 16 }}
                xl={{ span: 10 }}
                className={st["header-actions"]}
              >
                <Row gutter={rowGutters} align="middle" justify={"end"}>
                  {hasCountrySelector && (
                    <Col span={"auto"}>
                      <UserCountrySelector testId="user-country-selector" />
                    </Col>
                  )}
                  <Col span={"auto"}>
                    <ButtonsBecome type={navigationType} testId="btns-become" />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Layout.Header>
  );
};
