// Core
import { useEffect, PropsWithChildren, ReactElement } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Components
import { WrappedFormCheckbox } from "components/common/WrappedFormCheckbox";
import { FormGeneral } from "components/ui/FormFields/FormGeneral";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { InputForm } from "components/ui/FormFields/InputForm";

// Definitions
import { FormCartPreviewEnum } from "./config";
import type { CartPreviewType } from "client/core/cart/models";

// Utils
import { cartPreviewForm } from "./config";

type CartPreviewFormProps = PropsWithChildren<{
  initialValues?: CartPreviewType;
  children: ReactElement;
  loadingFetch?: boolean;
  loadingUpdate?: boolean;
  onSubmit?: (value: CartPreviewType) => void;
}>;

export const CartPreviewForm = (props: CartPreviewFormProps) => {
  const { children, loadingFetch, loadingUpdate, onSubmit, initialValues = null } = props;

  const formProps = useForm<CartPreviewType>({
    defaultValues: { ...cartPreviewForm.shape, ...initialValues },
    resolver: zodResolver(cartPreviewForm.schema()),
    mode: "all",
    reValidateMode: "onChange",
  });

  const { control, handleSubmit, setValue, watch, formState } = formProps;
  const { isSubmitting } = formState;

  useEffect(() => {
    if (initialValues) {
      setValue("selected", initialValues.selected);
    }
  }, [setValue, initialValues]);

  const handleSubmitForm = handleSubmit((values) => {
    onSubmit?.(values);
  });

  const onSubmitForm = () => {
    void (async () => {
      await handleSubmitForm();
    })();
  };

  useEffect(() => {
    const subscription = watch(() => void handleSubmitForm());
    return () => subscription.unsubscribe();
  }, [onSubmitForm, watch]);

  return (
    <FormGeneral size="middle" name="form-cart-preview" onFinish={onSubmitForm}>
      <FormBlock>
        <InputForm
          id={FormCartPreviewEnum.selected}
          name={FormCartPreviewEnum.selected}
          loading={loadingFetch || loadingUpdate}
          type="no-gutter"
          skeletonSize="default"
        >
          <WrappedFormCheckbox
            name={FormCartPreviewEnum.selected}
            control={control}
            hasDisabled={isSubmitting}
            mode="indeterminate"
            align="top"
          >
            {children}
          </WrappedFormCheckbox>
        </InputForm>
      </FormBlock>
    </FormGeneral>
  );
};
