// Components
import { Image } from "components/ui/Image";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { Tooltip } from "components/ui/Tooltip";

// Definitions
import type { ImageType } from "components/ui/Image/Image.types";
import type { ImagePropsType } from "components/ui/Image";

// Utils
import st from "./styles.module.less";

const imagePlaceholderDefault =
  // eslint-disable-next-line max-len
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCIgdmlld0JveD0iMCAwIDMwMCAzMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSIzMDAiIGZpbGw9IiNFM0UzRTMiLz48L3N2Zz4=";
const noImage = {
  src: "/assets/images/no-image.svg",
  alt: "No Image",
  blurDataURL: imagePlaceholderDefault,
};

type WithEnalrgeImagePropTypes = ImagePropsType & {
  placement?: "top" | "left";
  testId?: string;
};

const WithEnalrgeImage = (props: WithEnalrgeImagePropTypes) => {
  const { placement = "top", testId = "", alt, ...imageProps } = props;

  return (
    <Tooltip
      trigger="hover"
      type="primary"
      color="secondary"
      title={
        <div data-test-id="product-tooltip-image">
          <Image {...imageProps} width={200} height={200} alt={alt} />
        </div>
      }
      placement={placement}
    >
      <div data-test-id={testId}>
        <Image className={st.image} {...imageProps} width={40} height={40} alt={alt} />
      </div>
    </Tooltip>
  );
};

type CartCellProductImageProps = {
  image: ImageType | null | void;
  withEnlarge?: boolean;
  placement?: "top" | "left";
  testId?: string;
};

export const CartCellProductImage = (props: CartCellProductImageProps) => {
  const { image, withEnlarge, placement, testId = "" } = props;

  if (!image) return WrapperNodeDefault();

  const imageProps: ImagePropsType = {
    src: image.src || noImage.src,
    alt: image.alt || noImage.alt,
    blurDataURL: image.blurDataURL || noImage.blurDataURL,
    placeholder: "blur",
    objectFit: "contain",
  };

  if (withEnlarge) {
    return (
      <WithEnalrgeImage {...imageProps} placement={placement} testId={testId} alt={image.alt} />
    );
  }

  return (
    <div data-test-id={testId}>
      <Image {...imageProps} width={40} height={40} alt={image.alt} />
    </div>
  );
};
