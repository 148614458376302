// Core
import { useEffect } from "react";
import { useTranslation } from "next-i18next";

// Definitions
import type { PropsWithChildren } from "react";

// Components
import { PersonalManagerCallbackForm } from "client/core/personal-manager/components/personal-manager-callback-form";

// Hooks
import { useModalContext } from "hooks/useModalContext";
import { useProfileContext } from "hooks/useProfileContext";

// Hooks
import { usePersonalManagerAdviserCallbackCreate } from "client/core/personal-manager/hooks/use-personal-manager-adviser-callback-create";
import { usePersonalMmanagerAdviserStaticDataFetch } from "client/core/personal-manager/hooks/use-personal-manager-adviser-static-data-fetch";

// Utils
import st from "./styles.module.less";

export type PersonalManagerCallbackModalProps = PropsWithChildren<unknown>;
export const PersonalManagerCallbackModal = () => {
  const { t } = useTranslation();
  const { setIsModal } = useModalContext();
  const { profile } = useProfileContext();
  const { loadingPersonalManagerCallBack, onPersonalManagerCallBack } =
    usePersonalManagerAdviserCallbackCreate();
  const { loadingStaticData, staticData } = usePersonalMmanagerAdviserStaticDataFetch();

  useEffect(() => {
    setIsModal(true);
  }, []);

  return (
    <div className={st["modal-personal-manager-callback"]}>
      <PersonalManagerCallbackForm
        title={t("personal-manager:callBack.modalTitle")}
        loading={loadingPersonalManagerCallBack}
        loadingStaticData={loadingStaticData}
        country={profile?.countryCode}
        phoneCodes={staticData?.phoneCodes}
        onSubmit={onPersonalManagerCallBack}
      />
    </div>
  );
};
