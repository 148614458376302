// Core
import { useContext } from "react";

// Components
import { Divider } from "components/ui/Divider";
import { Can } from "components/common/Can";
import { PriceVatSwitchForm } from "client/core/price-vat-switch/components/price-vat-switch-form";

// Context
import { SettingsPriceVatContext } from "client/core/settings-price-vat/context";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";
import type {
  PriceVatSwitchOptionType,
  PriceVatSwitchValueType,
} from "client/core/price-vat-switch/components/price-vat-switch-form/config";

// Utils
import st from "./styles.module.less";

export type CartPreviewPriceVatSwitchProps = {
  loading?: boolean;
  label?: string;
  initialValues?: {
    showPriceVat: string;
  } | null;
  options?: PriceVatSwitchOptionType[];
  onSubmit?: (value: { showPriceVat: string }) => void;
};

export const CartPreviewPriceVatSwitch = ({
  loading,
  label,
  options,
}: CartPreviewPriceVatSwitchProps) => {
  const { priceVatCartPreview: showPriceVat, setPriceVatCartPreview } =
    useContext(SettingsPriceVatContext);

  const onChangePriceVat = (value: { showPriceVat: PriceVatSwitchValueType }) => {
    setPriceVatCartPreview(value?.showPriceVat);
  };

  return (
    <Can I={PERMISSION_ACT.READ} this={"cart_actions_vat_price"}>
      <Divider type="vertical" className={st["cart-preview-switch-divider"]} />
      <PriceVatSwitchForm
        loading={loading}
        initialValues={{ showPriceVat }}
        label={label}
        options={options}
        onSubmit={onChangePriceVat}
      />
    </Can>
  );
};
