// Core
import { useIsMutating, useMutation } from "react-query";

// Definitions
import { BaseMutationPayloadType, BaseUnknownRecordType } from "models/Base";
import { FetchDataType, HttpErrorType } from "models/Http";
import { QueryKey } from "react-query/types/core/types";
import { UseMutationOptions } from "react-query/types/react/types";

// Utils
import { onError } from "utils/react-query/fetcher-error";
import { queryFetcher } from "utils/react-query/query-fetcher";

type MutationPayloadType<TFetcherParams = BaseUnknownRecordType> = {
  id?: number;
  params?: TFetcherParams;
  meta?: BaseUnknownRecordType;
};

type UseMutationAsyncOptionsType<TQueryFnData, TVariables, TContext, TQueryKey, TFetcherParams> = {
  key: TQueryKey;
  name: string;
  fetcher: FetchDataType<TQueryFnData>;
  fetcherPayload?: MutationPayloadType<TFetcherParams>;
  fetcherType?: "query" | "mutation";
  handlerError403?: () => Promise<void>;
  handlerError404?: () => Promise<void>;
  handlerError422?: () => Promise<void>;
  options?: Omit<
    UseMutationOptions<TQueryFnData, HttpErrorType, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  >;
};

type MutationDataType = {
  id?: number | null;
} | void;

/* core */
export const useMutationAsync = <
  TQueryFnData = unknown,
  TVariables = void,
  TContext = unknown,
  TQueryKey extends QueryKey = QueryKey,
  TFetcherParams = BaseUnknownRecordType,
>(
  props: UseMutationAsyncOptionsType<TQueryFnData, TVariables, TContext, TQueryKey, TFetcherParams>,
) => {
  const {
    key,
    name,
    fetcher,
    fetcherPayload: payload,
    fetcherType = "mutation",
    handlerError404,
    handlerError422,
    options,
  } = props;

  const isMutating = !!useIsMutating(key);
  const mutationProps = useMutation<
    TQueryFnData,
    HttpErrorType,
    TVariables & MutationDataType,
    TContext
  >(
    key,
    (data) => {
      const fetcherPayload = {
        ...(payload?.id && { id: payload.id }),
        ...(payload?.params && { params: payload.params }),
        ...(payload?.meta && { meta: payload.meta }),
        ...(data?.id && { id: data.id }),
        ...(data && { data }),
      };

      return queryFetcher<TQueryFnData, BaseMutationPayloadType<TFetcherParams, TVariables>>({
        name,
        fetcher,
        fetcherType,
        fetcherPayload,
      });
    },
    {
      onError: (err) => onError({ err, handlerError404, handlerError422 }),
      ...options,
    },
  );

  return { ...mutationProps, isLoading: isMutating };
};
