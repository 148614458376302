// Core
import { useCallback } from "react";

// Definitions
import { HttpStatusCode } from "models/Http";

// Domains
import { useCartPreviewProductAlternativeUpdateMutation } from "client/core/cart/hooks/mutations/use-cart-preview-product-alternative-update-mutation";

// Contexts
import { useCartPreviewProductAlternativesStore } from "client/core/cart/hooks/use-cart-preview-product-alternative-store";

// Utils
import {
  getHttpSuccessMessageCartProductUpdateWithTranslations,
  getHttpErrorMessageCartProductUpdateWithTranslations,
  getHttpErrorMessageNotFoundCartProductUpdateWithTranslations,
  getHttpErrorMessageWithTranslations,
} from "client/core/cart/utils/notification-messages";
import { notificationService } from "utils/notifications";

type UseCallbackUpdateCartPreviewProductAlternative = (data: { articleId: number | null }) => void;
type UseCartPreviewProductAlternativeUpdate = {
  loadingCartPreviewProductAlternativeUpdate: boolean;
  onUpdateCartPreviewProductAlternative: UseCallbackUpdateCartPreviewProductAlternative;
};
type Args = {
  id: number;
  keyId: number;
};

export const useCartPreviewProductAlternativeUpdate = (
  args: Args,
): UseCartPreviewProductAlternativeUpdate => {
  const { onClose } = useCartPreviewProductAlternativesStore();

  const {
    loadingCartPreviewProductAlternativeUpdateMutation,
    onUpdateCartPreviewProductAlternativeMutation,
  } = useCartPreviewProductAlternativeUpdateMutation(args.id, args.keyId);

  const onUpdateCartPreviewProductAlternative =
    useCallback<UseCallbackUpdateCartPreviewProductAlternative>((payload) => {
      void (async () => {
        try {
          if (!payload.articleId) {
            throw new Error("Callback payload do not have articleId");
          }

          const [error] = await onUpdateCartPreviewProductAlternativeMutation(payload);

          const hasErrorInUpdateProduct = error && "hasError" in error;
          if (hasErrorInUpdateProduct) {
            const isError404 = error.status === HttpStatusCode.notFound;
            const isError422 = error.status === HttpStatusCode.unprocessableEntity;
            const isError500 = error.status === HttpStatusCode.server;

            if (isError422 || isError500) {
              return notificationService.showError(
                getHttpErrorMessageCartProductUpdateWithTranslations(),
              );
            }

            if (isError404) {
              return notificationService.showError(
                getHttpErrorMessageNotFoundCartProductUpdateWithTranslations(),
              );
            }

            throw new Error("Something went wrong in cart preview product alternative update flow");
          }

          notificationService.showSuccess(getHttpSuccessMessageCartProductUpdateWithTranslations());
          onClose();
        } catch (error) {
          console.warn(error);
          notificationService.showError(getHttpErrorMessageWithTranslations());
        }
      })();
    }, []);

  return {
    loadingCartPreviewProductAlternativeUpdate: loadingCartPreviewProductAlternativeUpdateMutation,
    onUpdateCartPreviewProductAlternative,
  };
};
