// Utils
import st from "./Styles.module.less";

export const IndicatorCircle = () => {
  return (
    <div className={st.indicator}>
      <div className={st.inner}>IndicatorCircle</div>
    </div>
  );
};
