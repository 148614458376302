// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventBeginCheckoutDataType,
  EventsDataUnionType,
  EventPayloadPurchaseItemsType,
  EventPayloadBeginCheckoutType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  ROUTE_NAME_KEYS,
  GTMTypesEnum,
  EcommerceEnum,
  EcommerceItemsEnum,
  EcommerceProductVariantsEnum,
  AFFILIATION_TYPE,
} from "client/utils/gtm/gtm.types";

class BeginCheckoutEvent extends AbstractGTMEventSegment<GTMTypesEnum.begin_checkout> {
  protected readonly segmentShape: EventPayloadBeginCheckoutType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.shipping_date]: 0,
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.cart,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadBeginCheckoutType {
    const { ordersCheckout, itemListId, route } = data as EventBeginCheckoutDataType;
    const { currency, priceVat } = super.getPriceConfig(String(ordersCheckout.totalPrice?.country));

    const segment = this.getSegment();

    if (route) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    }

    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(
      ordersCheckout.deliveryDate,
    );
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = String(currency);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] = Number(
      ordersCheckout.totalPrice?.value,
    );

    ordersCheckout.products.forEach((product) => {
      if (product.isActive) {
        const price = Number(priceVat ? product.priceVat?.value : product.priceNonVat?.value);
        const itemVariant = product.isVinCompatibility
          ? EcommerceProductVariantsEnum.vin_compitable
          : EcommerceProductVariantsEnum.vin_incompitable;

        const productData: EventPayloadPurchaseItemsType = {
          [EcommerceItemsEnum.item_id]: Number(product.articleId),
          [EcommerceItemsEnum.item_name]: String(product.name),
          [EcommerceItemsEnum.affiliation]: AFFILIATION_TYPE["autodoc.pro"],
          [EcommerceItemsEnum.item_brand]: String(product.brand?.name),
          ...(product.genericId && {
            [EcommerceItemsEnum.item_category3]: product.genericId,
          }),
          [EcommerceEnum.item_list_id]: itemListId,
          [EcommerceItemsEnum.item_variant]: itemVariant,
          [EcommerceItemsEnum.price]: price,
          [EcommerceItemsEnum.quantity]: Number(product.quantity?.value),
        };

        segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items].push(productData);
      }
    });

    return segment;
  }
}

export const beginCheckoutEvent: BeginCheckoutEvent = new BeginCheckoutEvent();
