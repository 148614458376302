// Core
import { ElementType } from "react";
import cx from "classnames";

// Definitions
import type { LinkProps } from "components/ui/Link";

// Components
import { Menu as AntMenu, MenuProps } from "antd";
import { Icon } from "../Icon";
import { Link } from "../Link";

// Utils
import st from "./Styles.module.less";

const MenuItemLink = (linkProps: {
  children: LinkProps["children"];
  link?: LinkProps & { component?: ElementType };
}) => {
  const { link, children } = linkProps;
  if (!link) {
    return <>{children}</>;
  }
  const { component: ItemLink = Link, href, ...otherLinkProps } = link;
  return (
    <ItemLink href={String(href)} {...otherLinkProps}>
      {children}
    </ItemLink>
  );
};

export type MenuItemType = {
  id?: number;
  key?: string;
  link?: LinkProps & {
    component?: ElementType;
  };
  hasIcon?: boolean;
  icon?: string;
  hasText?: boolean;
  text?: string;
  isSubMenu?: boolean;
};
export const MenuItem = (props: MenuItemType) => {
  const { hasIcon, hasText, isSubMenu, icon, text, link } = props;
  const contentJSX = (
    <>
      {hasIcon && icon && <Icon name={icon} />}
      {hasText && text && <>{text}</>}
    </>
  );
  if (isSubMenu) {
    return <>{text}</>;
  }
  return (
    <MenuItemLink link={link}>
      <>{contentJSX}</>
    </MenuItemLink>
  );
};

export type MenuPropsType = MenuProps;

export const Menu = (props: MenuPropsType) => {
  const { items = [], mode = "vertical", ...rest } = props;
  const menuStyles = cx(st.menu);

  return (
    <AntMenu
      mode={mode}
      className={menuStyles}
      expandIcon={<Icon name="DownOutlined" />}
      selectable={false}
      disabledOverflow
      items={items}
      {...rest}
    />
  );
};
