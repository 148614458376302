// Core
import { useContext } from "react";

// Utils
import { cartPreviewProductAlternativeRowCache } from "client/core/cart/contexts/cart-preview-product-alternative-row";

export const useCartPreviewProductAlternativeRow = () => {
  const context = useContext(cartPreviewProductAlternativeRowCache);
  if (!context) {
    throw new Error(
      "useCartPreviewProductAlternativeRow should only be called inside CartPreviewProductAlternativeRowProvider",
    );
  }

  return context;
};
