// Definitions
import { GTMTypesEnum, NOTIFICATION_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import { gtmService } from "client/utils/gtm";

type EventNotificationPageDataType = {
  totalNotifications: number;
  totalUnreadNotifications: number;
};

export const triggerNotificationPageGtmEvent = (eventData: EventNotificationPageDataType) => {
  const { totalNotifications, totalUnreadNotifications } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.notification_page, {
    totalNotifications,
    totalUnreadNotifications,
  });
};

type EventNotificationDataType = {
  actionType: keyof typeof NOTIFICATION_ACTION_TYPE;
};

export const triggerNotificationGtmEvent = (eventData: EventNotificationDataType) => {
  const { actionType } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.notification, {
    actionType,
  });
};
