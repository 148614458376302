// Core
import { useMemo } from "react";
import merge from "deepmerge";
import { ApolloClient } from "@apollo/client";

// Utils
import { createApolloClient } from "api/apollo-client";
import { verifyBrowser } from "utils/verify-browser";

const isBrowser = verifyBrowser();

type ApolloInitStateType = Record<string, unknown>;
type ApolloStoreType = ApolloClient<ApolloInitStateType>;

export let apolloClient: ApolloStoreType;

const initializeApollo = (initialState: ApolloInitStateType | null = null) => {
  const prevApolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = prevApolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    prevApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) {
    return prevApolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = prevApolloClient;

  return prevApolloClient;
};

export function useApollo(initialState: ApolloInitStateType | null = null) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
