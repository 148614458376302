// Definitions
import type { SavedBasketListType } from "client/core/saved-basket-listing/models";

// Utils
import { initMetaPagination } from "utils/constants";

export const savedBasketsKeys = {
  savedBaskets: "saved-baskets",
  savedBasketDetails: "saved-basket/id",
  client: "saved-basket-client",
  repairEstimate: "saved-basket-repair-estimate",
};

export type SavedBasketsStateType = {
  savedBaskets: SavedBasketListType;
};

export const initialSavedBasketsState: SavedBasketsStateType = {
  savedBaskets: {
    items: [],
    meta: initMetaPagination,
  },
};
