// Core
import { useCallback } from "react";

// Definitions
import { HttpStatusCode } from "models/Http";
import type { FormSubmitFn } from "models/Forms";
import type { CartTabType } from "client/core/cart/models";

// Domains
import { useCartPreviewVehicleUpdateMutation } from "client/core/cart/hooks/mutations/use-cart-preview-vehicle-update-mutation";
import { useCartPreviewVehiclesStore } from "client/core/cart/hooks/use-cart-preview-vehicles-store";

// Utils
import { fillServerErrorsToForm } from "utils/forms";
import { notificationService } from "utils/notifications";
import { getHttpErrorMessageCartProductUpdateWithTranslations } from "client/core/cart/utils/notification-messages";

type OnUpdateCartPreviewVehiclesType = FormSubmitFn<Partial<CartTabType>>;

type UseCartPreviewVehiclesUpdate = {
  loadingCartPreviewVehiclesUpdate: boolean;
  onUpdateCartPreviewVehicles: OnUpdateCartPreviewVehiclesType;
};

export const useCartPreviewVehiclesUpdate = (vehicleId?: number): UseCartPreviewVehiclesUpdate => {
  const { loadingCartPreviewVehicle, onUpdateCartPreviewVehicle } =
    useCartPreviewVehicleUpdateMutation(vehicleId);
  const { edit, setEdit } = useCartPreviewVehiclesStore();

  const onUpdateCartPreviewVehicles = useCallback<OnUpdateCartPreviewVehiclesType>(
    (data) => {
      void (async () => {
        try {
          const dataValues = { ...data?.values };

          const vehicleUpdated = await onUpdateCartPreviewVehicle({ ...dataValues });
          const errorInVehicleUpdate = "errors" in vehicleUpdated && vehicleUpdated?.errors;

          if (errorInVehicleUpdate) {
            if (vehicleUpdated.status === HttpStatusCode.notFound) {
              notificationService.showError(getHttpErrorMessageCartProductUpdateWithTranslations());
            }
            if (vehicleUpdated.status === HttpStatusCode.unprocessableEntity) {
              fillServerErrorsToForm(errorInVehicleUpdate, data?.acts?.setError);
            }
            throw new Error("Flow update repair-estimate client has error");
          }
          setEdit(false);
        } catch (error) {
          console.warn(error);
        }
      })();
    },
    [edit],
  );

  return {
    loadingCartPreviewVehiclesUpdate: loadingCartPreviewVehicle,
    onUpdateCartPreviewVehicles,
  };
};
