// Definitions
import { GTMTypesEnum, HEADER_HOT_TRICKER_NAME } from "client/utils/gtm/gtm.types";

// Utils
import { gtmService } from "client/utils/gtm";

type EventHeaderTopDataType = {
  hotTrickerName: keyof typeof HEADER_HOT_TRICKER_NAME;
};

export const triggerHeaderTopGtmEvent = (eventData: EventHeaderTopDataType) => {
  const { hotTrickerName } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.header_top, {
    hotTrickerName,
  });
};
