// Core
import { createContext } from "react";

type CartPreviewProductsRowCacheType = {
  tooltip?: string;
  name?: string;
  isDisabled?: boolean;
  onChange?: () => void;
};

export const cartPreviewProductsRowCache = createContext<CartPreviewProductsRowCacheType>({
  tooltip: "",
  name: "",
  isDisabled: false,
  onChange: () => {},
});
