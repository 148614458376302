// Core
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Text } from "components/ui/Typography/Text";
import { Container } from "components/common/Container";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";

export const BannerSales = () => {
  const { t } = useTranslation();

  return (
    <div className={st.banner} data-test-id="banner-sales">
      <Container>
        <Row gutter={rowGutters}>
          <Col span={24}>
            <div className={st.center}>
              <Text font="system" fontWeight="600" transform="up" color="white-100">
                {t("common:topSales.text")}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
