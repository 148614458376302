// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";
import type { BaseQueryKey } from "models/Base";
import type { SavedBasketType } from "client/core/saved-basket-listing/models";

// Domains
import { savedBasketsService } from "client/core/saved-basket-listing/service";
import { savedBasketsKeys } from "client/core/saved-basket-listing/store";

// Utils
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type OnCreateSavedBasketCbType = (data: {
  id: number;
}) => Promise<[HttpErrorType | null, SavedBasketType | null]>;

type UseSavedBasketCreateMutation = {
  loadingSavedBasketCreateMutation: boolean;
  onCreateSavedBasketMutation: OnCreateSavedBasketCbType;
};

export const useSavedBasketCreateMutation = (): UseSavedBasketCreateMutation => {
  const queryKey = [savedBasketsKeys.savedBaskets];

  const createSavedBasketProps = useMutationAsync<
    SavedBasketType,
    { id: number },
    SavedBasketType,
    BaseQueryKey
  >({
    key: queryKey,
    name: "createSavedBasketMutation",
    fetcher: savedBasketsService.createSavedBasket,
  });

  const onCreateSavedBasket = useCallback<OnCreateSavedBasketCbType>(async (data) => {
    try {
      const createSavedBasketResult = await createSavedBasketProps.mutateAsync(data);
      return [null, createSavedBasketResult];
    } catch (err) {
      return [err as HttpErrorType, null];
    }
  }, []);

  return {
    loadingSavedBasketCreateMutation: createSavedBasketProps.isLoading,
    onCreateSavedBasketMutation: onCreateSavedBasket,
  };
};
