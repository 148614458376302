// Core
import { z } from "zod";

export const PRICE_VAT_SWITCH_VALUE_FIELD = {
  notVat: "notVat",
  withVat: "withVat",
} as const;

export type PriceVatSwitchValueType =
  (typeof PRICE_VAT_SWITCH_VALUE_FIELD)[keyof typeof PRICE_VAT_SWITCH_VALUE_FIELD];

export const priceVatSwitchOptionSchema = z.object({
  id: z.number(),
  value: z.enum([PRICE_VAT_SWITCH_VALUE_FIELD.notVat, PRICE_VAT_SWITCH_VALUE_FIELD.withVat]),
  label: z.string(),
});
export type PriceVatSwitchOptionType = z.infer<typeof priceVatSwitchOptionSchema>;

export type PriceVatSwitchInitialType = {
  showPriceVat?: string;
};

export type PriceVatSwitchType = {
  showPriceVat?: PriceVatSwitchOptionType | null;
};

export const PRICE_VAT_SWITCH_FIELD = {
  showPriceVat: "showPriceVat",
} as const;

export const priceVatSwitchForm = {
  shape: {
    [PRICE_VAT_SWITCH_FIELD.showPriceVat]: null,
  },
  schema: () => {
    return z.object({
      [PRICE_VAT_SWITCH_FIELD.showPriceVat]: priceVatSwitchOptionSchema.nullable(),
    });
  },
};
