// Core
import { Trans } from "next-i18next";
import cx from "classnames";

// Components
import { Skeleton } from "antd";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";

// Utils
import { CurrencyFormat } from "utils/currencyFormat/currencyFormat";
import { currencyConfig } from "utils/currencyFormat/country-config";
import { getVatSignByCountry } from "utils/countries";
import st from "./Styles.module.less";

type WrapperPricePropsType = {
  value?: number | null;
  isEmpty?: boolean;
  isNumber?: boolean;
  skeletonSize?: "large" | "small" | "default";
  loading?: boolean;
  disabled?: boolean;
  country?: string;
  hasVatSign?: boolean;
  hasVatSignInvert?: boolean;
  testId?: string;
};

export const wrapperPriceFormat = (val: number | null, country: string) => {
  if (Number.isNaN(val)) {
    return WrapperNodeDefault();
  }

  return new CurrencyFormat({
    ...currencyConfig[country.toLocaleUpperCase()],
  }).toFormat(val);
};

export const WrapperPrice = (props: WrapperPricePropsType) => {
  const {
    value = null,
    isEmpty = false,
    loading = false,
    skeletonSize = "small",
    disabled,
    hasVatSign = false,
    hasVatSignInvert = false,
    country = "",
    testId = "wrapper-price",
  } = props;
  const price = wrapperPriceFormat(value, country);

  const vatPostfix = getVatSignByCountry(country?.toLocaleUpperCase(), hasVatSignInvert);

  const priceStyles = cx(st["wrapper-price"], {
    [st.disabled]: disabled,
  });

  if (loading) {
    return <Skeleton.Input active className={st.skeleton} size={skeletonSize} />;
  }

  if (!value && isEmpty) {
    return WrapperNodeDefault();
  }

  return (
    <div className={priceStyles} data-test-id={testId}>
      <span className={st.price}>{price}</span>
      {(hasVatSign || hasVatSignInvert) && (
        <span className={st.postfix}>
          <Trans i18nKey={vatPostfix} />
        </span>
      )}
    </div>
  );
};
