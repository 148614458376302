// Core
import { useCallback } from "react";

// Contexts
import { useCartPreviewProductAlternativesStore } from "client/core/cart/hooks/use-cart-preview-product-alternative-store";

type OnOpenProductsAlternativesCbType = (data: {
  articleId: number;
  vehicleId?: number;
  productId?: number;
}) => void;
type UseCartPreviewProductAlternativesToggleType = {
  onOpenProductAlternatives: OnOpenProductsAlternativesCbType;
};

export const useCartPreviewProductAlternativesToggle =
  (): UseCartPreviewProductAlternativesToggleType => {
    const { onOpen, onChange } = useCartPreviewProductAlternativesStore();

    const handleOpen = useCallback<OnOpenProductsAlternativesCbType>((data) => {
      onOpen();
      onChange(data);
    }, []);

    return {
      onOpenProductAlternatives: handleOpen,
    };
  };
