// Core
import { useRouter } from "next/router";

// Components
import { Text } from "components/ui/Typography/Text";
import { Tooltip } from "components/ui/Tooltip";
import { TagBadge } from "components/ui/TagBadge";
import { Skeleton } from "components/ui/Skeleton";

// Hooks
import { useProfileVehicleFetch } from "client/core/profile-vehicle/hook/use-profile-vehicle-fetch";

// Utils
import st from "./styles.module.less";
import { book } from "init/book";

export const SearchBarVehicle = () => {
  const router = useRouter();
  const vehicleId = Number(router.query.vehicleId);
  const { profileVehicle, loadingFetchProfileVehicle } = useProfileVehicleFetch({
    vehicleId,
  });

  if (loadingFetchProfileVehicle) {
    return (
      <div className={st["search-bar-vehicle-badge"]}>
        <Skeleton.Input active size="small" className={st["search-bar-vehicle-badge-skeleton"]} />
      </div>
    );
  }

  if (!profileVehicle) {
    return null;
  }

  const profileVehicleText = profileVehicle.description
    ? `${profileVehicle.title}, ${profileVehicle.description}`
    : profileVehicle.title;

  const handleResetVehicle = () => {
    void (async () => {
      await router.push({
        pathname: `${book.newCatalog}/new`,
      });
    })();
  };

  return (
    <Tooltip
      mouseEnterDelay={0.8}
      placement="bottom"
      color="secondary"
      gutter="middle"
      title={
        <Text size="12" testId={"search-bar-vehicle-tooltip-text"}>
          {profileVehicleText}
        </Text>
      }
    >
      <div className={st["search-bar-vehicle-badge"]}>
        <TagBadge
          label={profileVehicleText}
          onClose={handleResetVehicle}
          testId={"search-bar-vehicle-badge"}
        />
      </div>
    </Tooltip>
  );
};
