// Definitions
import type { StaticDataInnerShape } from "./models";
import { STATIC_DATA_KEYS } from "./models";

export const staticDataKeys = {
  staticData: "static-data",
};

export type StaticDataStateType = StaticDataInnerShape;

export const initialStaticDataState: StaticDataStateType = {
  [STATIC_DATA_KEYS.businessType]: [],
  [STATIC_DATA_KEYS.quantityAutolifts]: [],
  [STATIC_DATA_KEYS.quantityEmployees]: [],
  [STATIC_DATA_KEYS.countries]: [],
  [STATIC_DATA_KEYS.phoneCodes]: [],
  [STATIC_DATA_KEYS.returnStatuses]: [],
  [STATIC_DATA_KEYS.returnCauses]: [],
  [STATIC_DATA_KEYS.orderStatus]: [],
  [STATIC_DATA_KEYS.repairEstimateStatus]: [],
  [STATIC_DATA_KEYS.returnSpentHours]: [],
  [STATIC_DATA_KEYS.returnSpentMinutes]: [],
  [STATIC_DATA_KEYS.returnOtherTypes]: [],
  [STATIC_DATA_KEYS.returnOptions]: [],
  [STATIC_DATA_KEYS.cityOptions]: [],
  [STATIC_DATA_KEYS.bonusOperation]: [],
  [STATIC_DATA_KEYS.viewDisplay]: [],
};

export const selectStaticDataQueryMemoized = (data: StaticDataInnerShape): StaticDataInnerShape =>
  data;
