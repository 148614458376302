// Core
import cx from "classnames";

// Definitions
import type { ReactNode, PropsWithChildren } from "react";

// Utils
import st from "./styles.module.less";

type CartCellDisabledProps = PropsWithChildren<{
  children?: ReactNode;
  center?: boolean;
  testId?: string;
}>;

export const CartCellDisabled = (props: CartCellDisabledProps) => {
  const { children, center, testId } = props;

  const cellStyles = cx(st["cell-disabled"], {
    [st.center]: center,
  });

  return (
    <div className={cellStyles} data-test-id={testId}>
      <div className={st["cell-disabled-inner"]}>{children}</div>
    </div>
  );
};
