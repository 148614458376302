// Core
const buildConfig = require("./scripts/build.config");

const { isProduction, isDevelopment } = buildConfig;
const I18N_LOCALES_PATH = "./public/locales";
const I18N_DEFAULT_LOCALE = "en-FR";
const I18N_LOCALES = ["en-FR", "fr-FR", "de-DE", "de-AT", "nl-NL"];

module.exports = {
  i18n: {
    defaultLocale: I18N_DEFAULT_LOCALE,
    locales: I18N_LOCALES,
  },
  localePath: I18N_LOCALES_PATH,
  reloadOnPrerender: !isProduction,
  debug: typeof window !== "undefined" && isDevelopment,
};
