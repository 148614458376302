const CART_SERVICE_METHOD_COUNTRY_CODE = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

type CartServiceMethodCountryCode = keyof typeof CART_SERVICE_METHOD_COUNTRY_CODE;

export const cartServiceMethods = (
  country: CartServiceMethodCountryCode = CART_SERVICE_METHOD_COUNTRY_CODE.EN,
) => {
  const servicesByCountry = {
    [CART_SERVICE_METHOD_COUNTRY_CODE.DE]: [
      { alt: "sepa", src: "/assets/images/paymentMethods/sepa.svg" },
      { alt: "dhl", src: "/assets/images/paymentMethods/dhl.svg" },
      { alt: "xpollens", src: "/assets/images/paymentMethods/xpollens.svg" },
    ],
    [CART_SERVICE_METHOD_COUNTRY_CODE.AT]: [
      { alt: "sepa", src: "/assets/images/paymentMethods/sepa.svg" },
      { alt: "dhl", src: "/assets/images/paymentMethods/dhl.svg" },
      { alt: "xpollens", src: "/assets/images/paymentMethods/xpollens.svg" },
    ],
    [CART_SERVICE_METHOD_COUNTRY_CODE.FR]: [
      { alt: "sepa", src: "/assets/images/paymentMethods/sepa.svg" },
      { alt: "ups", src: "/assets/images/paymentMethods/ups.svg" },
      { alt: "mondu", src: "/assets/images/paymentMethods/FR/mondu.svg", width: 140 },
    ],
    [CART_SERVICE_METHOD_COUNTRY_CODE.EN]: [
      { alt: "sepa", src: "/assets/images/paymentMethods/sepa.svg" },
      { alt: "ups", src: "/assets/images/paymentMethods/ups.svg" },
      { alt: "xpollens", src: "/assets/images/paymentMethods/xpollens.svg" },
    ],
    [CART_SERVICE_METHOD_COUNTRY_CODE.NL]: [
      { alt: "sepa", src: "/assets/images/paymentMethods/sepa.svg" },
      { alt: "ups", src: "/assets/images/paymentMethods/ups.svg" },
      { alt: "xpollens", src: "/assets/images/paymentMethods/xpollens.svg" },
    ],
  } as const;
  return servicesByCountry[country];
};
