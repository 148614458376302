// Definitions
import { CartPreviewType, CartTabType } from "client/core/cart/models";

// Domains
import { cartKeys } from "client/core/cart/store";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { cartService } from "client/core/cart/service";

const getCartProductsTotalCount = (items: CartTabType[] = []) => {
  return items?.reduce((acc, item) => {
    const itmProductsCount = item?.products?.reduce((pAcc, p) => {
      return pAcc + Number(p?.quantity?.value);
    }, 0);
    return acc + itmProductsCount;
  }, 0);
};

type UseCartCountFetchType = {
  loadingCartCountFetch: boolean;
  cartCount: number | null;
};

export const useCartCountFetch = (): UseCartCountFetchType => {
  const fetchCartProps = useQueryAsync<CartPreviewType, CartPreviewType, [string] | string[]>({
    key: [cartKeys.preview],
    name: "fetchCartPreview",
    fetcher: cartService.fetchCartPreview,
    options: {
      refetchOnMount: "always",
    },
  });

  const cartVehicles = fetchCartProps?.data?.tabs;
  const cartCountExist = getCartProductsTotalCount(cartVehicles) || null;

  return {
    loadingCartCountFetch: fetchCartProps.isLoading || fetchCartProps.isRefetching,
    cartCount: cartCountExist,
  };
};
