export enum NotificationTypeEnum {
  success = "success",
  error = "error",
  info = "info",
}

export type NotificationMessageTitle = {
  type?: string;
  text: string;
};

export type NotificationMessageDesc = {
  text: string;
};

export type NotificationMessage = {
  title: string;
  desc: string;
};
