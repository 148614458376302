// Definitions
import type { PopoverProps as AntdPopoverProps } from "antd";

// Components
import { Popover as PopoverAntd } from "antd";

// Utils
import st from "./Styles.module.less";

export const Popover = (props: AntdPopoverProps) => {
  return <PopoverAntd {...props} className={st.popover} />;
};
