// Core
import { z } from "zod";

// Definitions
import { BaseDataListType } from "models/Base";

export const SEARCH_TYPES_KEYS = {
  product: 1,
  oem: 2,
  generic: 3,
  category: 4,
  brand: 5,
} as const;
export type SearchTypesKeysType = (typeof SEARCH_TYPES_KEYS)[keyof typeof SEARCH_TYPES_KEYS];

const SearchAutocompleteOptionScheme = z.object({
  label: z.string(),
  count: z.number().nullable(),
  keyword: z.string(),
  entity: z.string(),
  type: z.nativeEnum(SEARCH_TYPES_KEYS),
});
export type SearchAutocompleteOptionType = z.infer<typeof SearchAutocompleteOptionScheme>;

const SearchAutocompleteParamsScheme = z.object({
  keyword: z.string().optional(),
});

export type SearchAutocompleteParamsType = z.infer<typeof SearchAutocompleteParamsScheme>;

const SearchAutocompleteQueryParamsScheme = z.object({
  params: SearchAutocompleteParamsScheme,
});
export type SearchAutocompleteQueryParamsType = z.infer<typeof SearchAutocompleteQueryParamsScheme>;

const SearchBarScheme = z.object({
  type: z.nativeEnum(SEARCH_TYPES_KEYS).nullable(),
  entity: z.string(),
  search: z.string(),
  keyword: z.string(),
});
export type SearchBarType = z.infer<typeof SearchBarScheme>;

const SearchVehicleScheme = z.object({
  search: z.string().optional(),
});
export type SearchVehicleType = z.infer<typeof SearchVehicleScheme>;

export type SearchAutocompleteType = BaseDataListType<SearchAutocompleteOptionType>;
