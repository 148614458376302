// Core
import { useEffect, useState, PropsWithChildren } from "react";

// Utils
import { cartPreviewProductAlternativeRowCache } from "client/core/cart/contexts/cart-preview-product-alternative-row";

type CartPreviewProductAlternativeRowProviderType = PropsWithChildren<{
  loading?: boolean;
  onSubmit?: () => void;
}>;

export const CartPreviewProductAlternativeRowProvider = (
  props: CartPreviewProductAlternativeRowProviderType,
) => {
  const { children, loading = false, onSubmit } = props;
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (!loading && isLoading) {
      setIsloading(false);
    } else if (loading && !isLoading) {
      setIsloading(true);
    }
  }, [loading]);

  const handleSubmit = () => {
    if (!isLoading) {
      setIsloading(true);
    }
    onSubmit?.();
  };

  return (
    <cartPreviewProductAlternativeRowCache.Provider
      value={{
        loading: isLoading,
        onSubmit: handleSubmit,
      }}
    >
      {children}
    </cartPreviewProductAlternativeRowCache.Provider>
  );
};
