/* Core */
import winston from "winston";

// Utils
import { verifyEnvironment } from "utils/verify-environment";

export const serverProductionLogger = winston.createLogger({
  transports: [new winston.transports.Console()],
  format: winston.format.json(),
  silent: verifyEnvironment().isProduction || verifyEnvironment().isStaging,
});
