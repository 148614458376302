// Core
import { AxiosRequestConfig } from "axios";
import QS from "qs";

// Definitions
import { HttpResponse } from "models/Http";
import { HttpRequestParams } from "models/Http";
import type {
  PersonalManagerType,
  PersonalManagerCallBackType,
  PersonalManagerCallBackSendType,
  PersonalManagerRatingType,
  StaticDataInnerShape,
  StaticDataType,
} from "client/core/personal-manager/models";
import type { PersonalManagerAdviserMetadataType } from "client/core/personal-manager/models";

// Utils
import { http } from "api/network-provider";

export const personalManagerService = Object.freeze({
  fetchProfilePersonalManager: () => {
    return http.get<HttpResponse<PersonalManagerType>>("/personal-manager");
  },
  createProfilePersonalManagerRating: (payload: HttpRequestParams<PersonalManagerRatingType>) => {
    const { data } = payload;
    return http.post<HttpResponse<void>>("/personal-manager/rating", data);
  },
  personalManagerCallBack: (values: HttpRequestParams<PersonalManagerCallBackSendType>) => {
    const data = values.data;
    return http.post<HttpResponse<PersonalManagerCallBackType>>(
      "/personal-manager/call-back",
      data,
    );
  },
  fetchMetadata: () => {
    return http.get<HttpResponse<PersonalManagerAdviserMetadataType>>("/genesys/user");
  },
  fetchPrivateStaticData: (payload: HttpRequestParams<{ types: StaticDataType[] }>) => {
    const { params } = payload;
    const config = {
      params,
      paramsSerializer: (p: AxiosRequestConfig): string => QS.stringify(p, { encode: false }),
    };

    return http.get<HttpResponse<StaticDataInnerShape>>("/dropdown-options/private", config);
  },
});
