// Core
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

// Components
import { Layout } from "antd";
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Container } from "components/common/Container";
import { Navbar } from "components/common/Navbar";
import { Logo } from "components/common/Logo";
import { NavbarProfile } from "components/common/NavbarProfile";
import { HeaderBanner } from "components/common/HeaderBanner";
import { Can } from "components/common/Can";
import { SearchBar } from "client/core/search-bar";

// Hooks
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import { rowGutters } from "utils/constants";
import { mainNavModel, mainNavItemsCatalog } from "utils/renderModels/mainNavModel";
import st from "./Styles.module.less";

type LayoutHeaderPrivateProps = {
  hasActions?: boolean;
};

export const LayoutHeaderPrivate = ({ hasActions = true }: LayoutHeaderPrivateProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { profile } = useProfileContext();
  const { vehicleId } = router.query;

  const menuItemsCatalog = mainNavModel({
    items: mainNavItemsCatalog({
      pages: profile?.permission?.pages,
      ...(vehicleId ? { profileVehicleId: String(vehicleId) } : {}),
    }),
    t,
  });

  return (
    <Layout.Header className={st.header} data-test-id="header-private">
      <Can I={PERMISSION_ACT.READ} this={"banner_top"}>
        {profile && <HeaderBanner country={profile.countryCode} />}
      </Can>
      <div className={st["header-top"]}>
        <Container>
          <Row gutter={rowGutters} align="middle">
            <Col span={4}>
              <Logo alt={t("common:firstPartPageTitle")} testId="logo-header" />
            </Col>
            <Col span={16}>
              <SearchBar />
            </Col>
            {hasActions && (
              <Col span={4} className={st.actions}>
                <NavbarProfile />
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <div className={st["header-bottom"]}>
        <Container>
          <Row justify="center">
            <Col span={12}>
              <Navbar menuItems={menuItemsCatalog} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout.Header>
  );
};
