// Components
import { WrapperDateDefault } from "components/common/WrapperDateDefault";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { WrapperPrice } from "components/common/WrapperPrice";
import { CartCellText } from "client/core/cart/components/cart-cell-text";
import { CartCellName } from "client/core/cart/components/cart-cell-name";
import { CartCellProductImage } from "client/core/cart/components/cart-cell-product-image";
import { CartCellPromoLabel } from "client/core/cart/components/cart-cell-promo-label";
import { CartCellPriceWithOldPrice } from "client/core/cart/components/cart-cell-price-with-old-price";

// Definitions
import type { TFunction } from "next-i18next";
import type {
  CartProductAlternative,
  ProductAlternativeImageType,
  ProductAlternativePromotionsType,
} from "client/core/cart/models";
import type { ColumnsTypeCustom } from "components/ui/TableAnt/TableAnt.types";
import { PRICE_VAT_VIEW_KEYS } from "client/core/settings-price-vat/models";
import type { PriceVatViewType } from "client/core/settings-price-vat/models";

// Utils
import { getHeader } from "client/core/cart/utils/common";
import { checkIsVatCountry, getVatSignByCountry } from "client/core/cart/utils/countries";

const EMPTY_STR = " ";
const DATE_FORMAT_CREATED_AT = "DD/MM/YY";
const PRODUCT_ALTERNATIVE_COLUMNS = {
  articleNo: "articleNo",
  images: "images",
  brand: "brand",
  deliveryAt: "deliveryAt",
  priceRecommended: "priceRecommended",
  margin: "margin",
  priceNonVat: "priceNonVat",
  name: "name",
  vatPrice: "vatPrice",
  promotions: "promotions",
  action: "action",
} as const;

type GetCartProductAlternativesColumnsProps = {
  t: TFunction;
  country?: string;
  canSwitchVat?: boolean;
  viewPriceVat?: PriceVatViewType;
};

export const getCartPreviewProductAlternativesColumns = (
  props: GetCartProductAlternativesColumnsProps,
): ColumnsTypeCustom<CartProductAlternative> => {
  const { t, country, canSwitchVat, viewPriceVat } = props;
  const CellPromoLabel = (value: ProductAlternativePromotionsType | null) => {
    return CartCellPromoLabel({ item: value?.prime });
  };

  const CellCreatedDate = (value: number) => WrapperDateDefault(value, DATE_FORMAT_CREATED_AT);

  const CellArticle = (value: string) => WrapperNodeDefault(value);

  const CellBrandAndName = (
    value: CartProductAlternative["brand"],
    record: CartProductAlternative,
  ) => CartCellName({ brand: value, name: record.name });

  const CellImage = (value: ProductAlternativeImageType | null) =>
    CartCellProductImage({ image: value?.thumb, withEnlarge: true, placement: "left" });

  const CellPriceRecommended = (value: CartProductAlternative["priceRecommended"]) =>
    WrapperPrice({ country: value?.country, value: value?.value, isEmpty: true });

  const CellDiscountWrapper = (value: number) =>
    CartCellText({
      value,
      postfix: "%",
      textProps: {
        size: "14",
      },
    });

  const CellPriceVat = (_: CartProductAlternative["vatPrice"], record: CartProductAlternative) => {
    return CartCellPriceWithOldPrice({
      country: record?.vatPrice?.country,
      value: record?.vatPrice?.value,
      oldValue: record?.oldVatPrice?.value,
      isEmpty: true,
      testId: "cell-price-vat",
    });
  };

  const CellPriceNonVat = (
    _: CartProductAlternative["priceNonVat"],
    record: CartProductAlternative,
  ) => {
    return CartCellPriceWithOldPrice({
      country: record?.priceNonVat?.country,
      value: record?.priceNonVat?.value,
      oldValue: record?.oldPriceNonVat?.value,
      isEmpty: true,
      testId: "cell-price-non-vat",
    });
  };

  const maskT = "products:alternativeTable";
  const isVatCountry = checkIsVatCountry(country?.toLocaleUpperCase());
  const vatPostfix = t(getVatSignByCountry(country?.toLocaleUpperCase()));

  const mainPriceColVat = {
    title: getHeader(maskT, "vatPrice", t),
    key: PRODUCT_ALTERNATIVE_COLUMNS.vatPrice,
    dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.vatPrice,
    render: CellPriceVat,
    width: 94,
  };

  const mainPriceColNotVat = {
    title: getHeader(maskT, "priceNonVat", t),
    key: PRODUCT_ALTERNATIVE_COLUMNS.priceNonVat,
    dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.priceNonVat,
    render: CellPriceNonVat,
    width: 94,
  };

  const priceColumnsModelMap = {
    [PRICE_VAT_VIEW_KEYS.notVat]: mainPriceColNotVat,
    [PRICE_VAT_VIEW_KEYS.withVat]: mainPriceColVat,
  };

  const mainPriceColByCountry = isVatCountry ? mainPriceColVat : mainPriceColNotVat;
  const mainPriceCol =
    canSwitchVat && viewPriceVat ? priceColumnsModelMap[viewPriceVat] : mainPriceColByCountry;

  return [
    {
      title: EMPTY_STR,
      key: PRODUCT_ALTERNATIVE_COLUMNS.promotions,
      dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.promotions,
      render: CellPromoLabel,
      width: 40,
    },
    {
      title: EMPTY_STR,
      key: PRODUCT_ALTERNATIVE_COLUMNS.images,
      dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.images,
      render: CellImage,
      width: 72,
    },
    {
      title: getHeader(maskT, "article", t),
      key: PRODUCT_ALTERNATIVE_COLUMNS.articleNo,
      dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.articleNo,
      sorter: true,
      render: CellArticle,
      width: 120,
    },
    {
      title: getHeader(maskT, "brand", t),
      key: PRODUCT_ALTERNATIVE_COLUMNS.brand,
      dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.brand,
      sorter: true,
      render: CellBrandAndName,
      width: 310,
    },
    {
      title: getHeader(maskT, "deliveryAt", t),
      key: PRODUCT_ALTERNATIVE_COLUMNS.deliveryAt,
      dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.deliveryAt,
      render: CellCreatedDate,
      width: 104,
    },
    {
      title: getHeader(maskT, "priceRecommended", t, { vatPostfix }),
      key: PRODUCT_ALTERNATIVE_COLUMNS.priceRecommended,
      dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.priceRecommended,
      render: CellPriceRecommended,
      width: 104,
    },
    {
      title: getHeader(maskT, "surcharge", t),
      key: PRODUCT_ALTERNATIVE_COLUMNS.margin,
      dataIndex: PRODUCT_ALTERNATIVE_COLUMNS.margin,
      render: CellDiscountWrapper,
      width: 104,
    },
    mainPriceCol,
    {
      title: getHeader(maskT, "action", t),
      key: PRODUCT_ALTERNATIVE_COLUMNS.action,
      inputType: PRODUCT_ALTERNATIVE_COLUMNS.action,
      width: 56,
    },
  ];
};
