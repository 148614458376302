// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Grid } from "components/ui/Grid";
import { Image } from "components/ui/Image";
import { Text } from "components/ui/Typography/Text";
import { DecorDivider } from "components/common/DecorDivider";

// Utils
import { rowGutters } from "utils/constants";
import st from "./Styles.module.less";

export enum TecDocLogoSrcList {
  dark = "/assets/images/pro-tec-doc-logo-dark.svg",
}
type TecDocLogoProps = {
  alt?: string;
  testId?: string;
  copyright?: string;
};

export const TecDocLogo = (props: TecDocLogoProps) => {
  const { alt = "Tec Alliance", copyright, testId } = props;
  const { md = true, lg = true } = Grid.useBreakpoint();

  const isMobile = !md && !lg;
  const isTablet = !lg && !isMobile;
  const isDesktop = !isTablet && !isMobile;

  const tecDocLogoSrc = TecDocLogoSrcList.dark;

  return (
    <div className={st["tec-doc-logo"]}>
      <DecorDivider gutter="middle" color="gray-9" />
      <div className={st["tec-doc-logo-container"]}>
        <Row gutter={rowGutters}>
          {isMobile && (
            <Col span={8}>
              <div className={st["tec-doc-logo-image"]} data-test-id={testId}>
                <Image src={tecDocLogoSrc} width={132} height={36} alt={alt} unoptimized />
              </div>
            </Col>
          )}
          {isTablet && (
            <Col span={5}>
              <div className={st["tec-doc-logo-image"]} data-test-id={testId}>
                <Image src={tecDocLogoSrc} width={132} height={36} alt={alt} unoptimized />
              </div>
            </Col>
          )}
          {isDesktop && (
            <Col span={3}>
              <div className={st["tec-doc-logo-image"]} data-test-id={testId}>
                <Image src={tecDocLogoSrc} width={155} height={42} alt={alt} unoptimized />
              </div>
            </Col>
          )}

          {isMobile && (
            <Col span={16}>
              <div className={st["tec-doc-logo-copyright"]}>
                <Text size="12" color="gray-700" tag="p">
                  {copyright}
                </Text>
              </div>
            </Col>
          )}
          {isTablet && (
            <Col span={19}>
              <div className={st["tec-doc-logo-copyright"]}>
                <Text size="12" color="gray-700" tag="p">
                  {copyright}
                </Text>
              </div>
            </Col>
          )}
          {isDesktop && (
            <Col span={21}>
              <div className={st["tec-doc-logo-copyright"]}>
                <Text size="12" color="gray-700" tag="p">
                  {copyright}
                </Text>
              </div>
            </Col>
          )}
        </Row>
      </div>
      <DecorDivider gutter="middle" color="gray-9" />
    </div>
  );
};
