// Core
import cx from "classnames";

// Components
import { Input } from "antd";

// Definitions
import { GroupProps as AntGroupProps } from "antd/lib/input";

// Utils
import st from "./Styles.module.less";

type GroupProps = AntGroupProps & {
  type?: "phone";
};
export const InputGroup = ({ type, className = "", ...rest }: GroupProps) => {
  const InputGroupStyles = cx(
    type && {
      [st[type]]: !!type,
    },
    className,
  );

  return <Input.Group className={InputGroupStyles} {...rest} />;
};

InputGroup.displayName = "InputGroup";
