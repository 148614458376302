// Core
import { useContext } from "react";

// Utils
import { cartPreviewProductAlternativesCache } from "client/core/cart/contexts/cart-preview-product-alternatives";

export const useCartPreviewProductAlternativesStore = () => {
  const context = useContext(cartPreviewProductAlternativesCache);
  if (!context) {
    throw new Error(
      "useCartPreviewProductAlternativesStore should only be called inside CartPreviewProductAlternativesProvider",
    );
  }

  return context;
};
