// Core
import type { PropsWithChildren } from "react";

// Components
import { Layout, Row, Col } from "antd";
import { Container } from "components/common/Container";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";

type FooterProps = PropsWithChildren<{ testId: string }>;

export const Footer = (props: FooterProps) => {
  const { children = null, testId } = props;
  return (
    <Layout.Footer className={st.footer} data-test-id={testId}>
      <Container>
        <Row gutter={rowGutters}>
          <Col span={24}>{children}</Col>
        </Row>
      </Container>
    </Layout.Footer>
  );
};
