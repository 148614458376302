// Core
import { FC, ReactElement } from "react";
import { useRouter } from "next/router";

// Components
import { LayoutApp } from "components/common/LayoutApp";
import { LayoutFooterPrivate } from "components/common/LayoutFooterPrivate";
import { LayoutFooterPublic } from "components/common/LayoutFooterPublic";
import { LayoutHeaderPublic } from "components/common/LayoutHeaderPublic";
import { LayoutHeaderPrivate } from "components/common/LayoutHeaderPrivate";

// Hooks
import { useAuth } from "bus/auth/hooks/useAuth";

// Utils
import { book } from "init/book";

type LayoutErrorType = { children: ReactElement };

export const LayoutError: FC<LayoutErrorType> = ({ children }) => {
  const router = useRouter();
  const { isAuthenticated } = useAuth();

  const layoutType = isAuthenticated ? "private" : "public";
  const buttonsBecomeType = router.asPath === book.signin ? "signup" : "signin";

  return (
    <LayoutApp type={layoutType}>
      {isAuthenticated ? (
        <LayoutHeaderPrivate hasActions={false} />
      ) : (
        <LayoutHeaderPublic hasActions={false} navigationType={buttonsBecomeType} />
      )}
      {children}
      {isAuthenticated ? <LayoutFooterPrivate /> : <LayoutFooterPublic />}
    </LayoutApp>
  );
};
