// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type { EventClearEcommercePayloadType } from "client/utils/gtm/gtm.types";
import { PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class EcommerceClearEvent extends AbstractGTMEventSegment<GTMTypesEnum.ecommerce_clear> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.ecommerce]: null,
  };

  makeSegment(): EventClearEcommercePayloadType {
    return this.segmentShape;
  }
}

export const ecommerceClearEvent: EcommerceClearEvent = new EcommerceClearEvent();
