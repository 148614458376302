const COUNTRY_CODE_VAT_CHECK = {
  EN: "EN",
  DE: "DE",
} as const;

export const checkIsVatCountry = (country?: string) => {
  if (!country) return true;
  return Object.keys(COUNTRY_CODE_VAT_CHECK).includes(country);
};

export const getVatSignByCountry = (country?: string, invert = false) => {
  const isVatCountry = checkIsVatCountry(country);
  if (!invert) {
    return isVatCountry ? "common:withVat" : "common:notVat";
  }
  return !isVatCountry ? "common:withVat" : "common:notVat";
};
