// Core
import axios, { AxiosRequestConfig } from "axios";

// Utils
import { CLIENT_API_URL_HTTP_REST, CLIENT_SSR_API_URL_HTTP_REST } from "api/config";

const defaultOptions: AxiosRequestConfig = {
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

// for browser requests
export const http = axios.create({
  ...defaultOptions,
  baseURL: CLIENT_API_URL_HTTP_REST,
  withCredentials: true,
});

// for node requests
export const httpServer = axios.create({
  ...defaultOptions,
  withCredentials: true,
  baseURL: CLIENT_SSR_API_URL_HTTP_REST,
});
