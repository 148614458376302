// Core
import React, { FC, ReactElement, ReactNode } from "react";

// Components
import { Grid } from "antd";
import { Title } from "components/ui/Typography/Title";

// Utils
import st from "./Styles.module.less";

type FormTitleProps = {
  children: ReactNode;
  testId?: string;
};

export const FormTitle: FC<FormTitleProps> = ({
  children,
  testId = "form-title",
}): ReactElement => {
  const { md = true, lg = true } = Grid.useBreakpoint();

  const isMobile = !md && !lg;
  const isTablet = !lg && !isMobile;
  const isDesktop = !isTablet && !isMobile;

  return (
    <div className={st.title} data-test-id={testId}>
      {isDesktop && (
        <Title tag="h3" size="24" fontWeight="600" color="gray-900" alignmentHorizontal="center">
          {children}
        </Title>
      )}
      {isTablet && (
        <Title tag="h3" size="24" fontWeight="600" color="gray-900" alignmentHorizontal="center">
          {children}
        </Title>
      )}
      {isMobile && (
        <Title tag="h3" size="18" fontWeight="600" color="gray-900" alignmentHorizontal="center">
          {children}
        </Title>
      )}
    </div>
  );
};
