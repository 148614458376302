// Core
import cx from "classnames";

// Utils
import st from "./Styles.module.less";
import { getDateFromTimestamp } from "utils/helpers";
import { dateFormat } from "utils/constants";

export const WrapperDateDefault = (
  value?: string | number | null,
  customDateFormat?: string,
  locale?: string,
  disabled?: boolean,
) => {
  const wrapperStyles = cx(st["wrapper-date"], {
    [st.disabled]: Boolean(disabled),
  });

  const renderValue = value
    ? getDateFromTimestamp({
        timestamp: Number(value),
        format: customDateFormat || dateFormat,
        locale: String(locale),
      })
    : "-";

  return (
    <span className={wrapperStyles} data-test-id="wrapper-date-default">
      {renderValue}
    </span>
  );
};
