// Components
import { authorizationEvent } from "./segments/authorization";
import { registrationEvent } from "./segments/registration";
import { searchEvent } from "./segments/search";
import { selectorCarEvent } from "./segments/selector-car";
import { catalogEvent } from "./segments/catalog";
import { showPriceEvent } from "./segments/show-price";
import { addToCartEvent } from "./segments/add-to-cart";
import { beginCheckoutEvent } from "./segments/begin-checkout";
import { purchaseEvent } from "./segments/purchase";
import { catalogAddTabEvent } from "./segments/catalog-add-tab";
import { catalogCloseTabEvent } from "./segments/catalog-close-tab";
import { catalogTabClickEvent } from "./segments/catalog-tab-click";
import { consentOptionEvent } from "./segments/consent-option";
import { consentDefEvent } from "./segments/consent-def";
import { pageLoadEvent } from "./segments/page-load";
import { viewItemListEvent } from "./segments/view_item_list";
import { viewItemEvent } from "./segments/view_item";
import { ecommerceClearEvent } from "./segments/ecommerce_clear";
import { selectItemEvent } from "./segments/select-item";
import { addPaymentInfoEvent } from "./segments/add-payment-info";
import { removeFromCartEvent } from "./segments/remove-from-cart";
import { viewCartEvent } from "./segments/view-cart";
import { dashboardBannerEvent } from "./segments/dashboard-banner";
import { popupEvent } from "./segments/popup";
import { headerTopEvent } from "./segments/header-top";
import { footerMenuEvent } from "./segments/footer-menu";
import { notificationPageEvent } from "./segments/notification-page";
import { notificationEvent } from "./segments/notification";

// Definitions
import { GTMTypesEnum, GTMConfigType } from "./gtm.types";

const eventsMap: GTMConfigType["eventsMap"] = new Map();
eventsMap.set(GTMTypesEnum.pageLoad, pageLoadEvent);
eventsMap.set(GTMTypesEnum.authorization, authorizationEvent);
eventsMap.set(GTMTypesEnum.registration, registrationEvent);
eventsMap.set(GTMTypesEnum.search, searchEvent);
eventsMap.set(GTMTypesEnum.selector_car, selectorCarEvent);
eventsMap.set(GTMTypesEnum.catalog, catalogEvent);
eventsMap.set(GTMTypesEnum.show_price, showPriceEvent);
eventsMap.set(GTMTypesEnum.add_to_cart, addToCartEvent);
eventsMap.set(GTMTypesEnum.begin_checkout, beginCheckoutEvent);
eventsMap.set(GTMTypesEnum.purchase, purchaseEvent);
eventsMap.set(GTMTypesEnum.add_tab, catalogAddTabEvent);
eventsMap.set(GTMTypesEnum.close_tab, catalogCloseTabEvent);
eventsMap.set(GTMTypesEnum.tab_click, catalogTabClickEvent);
eventsMap.set(GTMTypesEnum.consent_mode_option, consentOptionEvent);
eventsMap.set(GTMTypesEnum.consent_mode_def, consentDefEvent);
eventsMap.set(GTMTypesEnum.view_item_list, viewItemListEvent);
eventsMap.set(GTMTypesEnum.view_item, viewItemEvent);
eventsMap.set(GTMTypesEnum.select_item, selectItemEvent);
eventsMap.set(GTMTypesEnum.remove_from_cart, removeFromCartEvent);
eventsMap.set(GTMTypesEnum.view_cart, viewCartEvent);
eventsMap.set(GTMTypesEnum.add_payment_info, addPaymentInfoEvent);
eventsMap.set(GTMTypesEnum.dashboard_banner, dashboardBannerEvent);
eventsMap.set(GTMTypesEnum.popup, popupEvent);
eventsMap.set(GTMTypesEnum.header_top, headerTopEvent);
eventsMap.set(GTMTypesEnum.footer_menu, footerMenuEvent);
eventsMap.set(GTMTypesEnum.notification_page, notificationPageEvent);
eventsMap.set(GTMTypesEnum.notification, notificationEvent);
eventsMap.set(GTMTypesEnum.ecommerce_clear, ecommerceClearEvent);

export { eventsMap };
