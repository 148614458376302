// Core
import { useMemo } from "react";

// Components
import { UserCountrySelectorForm } from "components/common/Forms/UserCountrySelector";

// Bus
import { useUserCountriesStaticDataFetch } from "bus/user/hooks/useUserCountriesStaticDataFetch";
import { useUserCountry } from "bus/user/hooks/useUserCountry";

// Utils
import { mapUserCountriesLabel } from "utils/maps";
import st from "./Styles.module.less";

type UserCountrySelectorFooterType = {
  testId: string;
};

export const UserCountrySelectorFooter = ({ testId }: UserCountrySelectorFooterType) => {
  const { userCountry, onUpdateUserCountry } = useUserCountry();
  const { loadingStaticData, countries } = useUserCountriesStaticDataFetch({
    country: userCountry,
  });

  const options = useMemo(() => mapUserCountriesLabel(countries), [countries]);

  return (
    <div className={st["country-selector"]} data-test-id={testId}>
      <UserCountrySelectorForm
        testId={`${testId}-form`}
        loading={loadingStaticData}
        options={options}
        country={userCountry}
        onSubmit={onUpdateUserCountry}
        type={"footer"}
        controlSize="large"
      />
    </div>
  );
};
