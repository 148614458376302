// Core
import { useEffect, useState } from "react";
import { Trans } from "next-i18next";

// Components
import { Container } from "components/common/Container";
import { Link } from "components/common/Link";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Text } from "components/ui/Typography/Text";

// Definitions
import { HEADER_HOT_TRICKER_NAME } from "client/utils/gtm/gtm.types";

// Domains
import { useUserCountry } from "bus/user/hooks/useUserCountry";

// Utils
import { rowGutters } from "utils/constants";
import { book } from "init/book";
import st from "./styles.module.less";
import { getGoCorePDFLink } from "client/core/header-banner/utils/common";
import { triggerHeaderTopGtmEvent } from "components/common/HeaderBanner/utils/header-banner-gtm-events";

const GoCoreMessagesType = {
  learnMore: "learnMore",
  shopNow: "shopNow",
} as const;

export const HeaderBannerGoCore = () => {
  const [messageType, setMessageType] = useState<keyof typeof GoCoreMessagesType>(
    GoCoreMessagesType.learnMore,
  );
  const { userCountry } = useUserCountry();

  useEffect(() => {
    const interval = setInterval(() => {
      const nextMessageType =
        messageType === GoCoreMessagesType.learnMore
          ? GoCoreMessagesType.shopNow
          : GoCoreMessagesType.learnMore;

      setMessageType(nextMessageType);
    }, 5000);

    return () => clearInterval(interval);
  }, [messageType]);

  const handleTrackClick = () => {
    triggerHeaderTopGtmEvent({
      hotTrickerName: HEADER_HOT_TRICKER_NAME.go_core,
    });
  };

  return (
    <div className={st.banner} onClick={handleTrackClick}>
      <Container>
        <Row gutter={rowGutters}>
          <Col span={24}>
            <div className={st.center}>
              {messageType === GoCoreMessagesType.learnMore && (
                <Text font="system" fontWeight="600" color="white-100">
                  <Trans
                    i18nKey={"common:topBanner.goCore.learnMore"}
                    components={{
                      "go-core-link": (
                        <Link
                          size="16"
                          fontWeight="600"
                          type="banner"
                          decoration="underline"
                          blank
                          href={getGoCorePDFLink(userCountry)}
                        />
                      ),
                    }}
                  />
                </Text>
              )}
              {messageType === GoCoreMessagesType.shopNow && (
                <Text font="system" fontWeight="600" color="white-100">
                  <Trans
                    i18nKey={"common:topBanner.goCore.shopNow"}
                    components={{
                      "go-core-link": (
                        <Link
                          size="16"
                          fontWeight="600"
                          type="banner"
                          decoration="underline"
                          href={`${book.newCatalog}/new${book.productsSearch}?keyword=goCORE&search=goCORE`}
                        />
                      ),
                    }}
                  />
                </Text>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
